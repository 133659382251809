import * as types from "./actionTypes";
import axios from "../../utils/axios-base";
import { returnErrors, returnSuccess } from "./messageActions";
export const fetchBlogPostsStart = () => {
  return {
    type: types.GET_BLOG_START,
  };
};

export const fetchBlogPostsSuccess = (data) => {
  return {
    type: types.GET_BLOG_SUCCESS,
    data,
  };
};

export const fetchBlogPostsFail = () => {
  return {
    type: types.GET_BLOG_FAIL,
  };
};

export const fetchBlogPosts = (page, size) => (dispatch) => {
  dispatch(fetchBlogPostsStart());
  axios
    .get(
      `${process.env.REACT_APP_BLOG_URL}/posts?populate=%2A&pagination[page]=${page}&pagination[pageSize]=${size}`
    )
    .then((res) => {
      dispatch(fetchBlogPostsSuccess(res.data));
      dispatch(returnSuccess(res.data.message, "ok", "BLOG"));
    })
    .catch((err) => {
      dispatch(fetchBlogPostsFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

export const fetchPostsbyCategory = (category) => (dispatch) => {
  dispatch(fetchBlogPostsStart());
  axios
    .get(
      `${process.env.REACT_APP_BLOG_URL}/posts?filters[category][name][$eq]=${category}&pagination[page]=1&pagination[pageSize]=6`
    )
    .then((res) => {
      dispatch(fetchBlogPostsSuccess(res.data));
      dispatch(returnSuccess(res.data.message, "ok", "BLOG"));
    })
    .catch((err) => {
      dispatch(fetchBlogPostsFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

export const fetchSortedBlogPosts = (page, sortBy, value) => (dispatch) => {
  dispatch(fetchBlogPostsStart());
  axios
    .get(
      `${process.env.REACT_APP_BLOG_URL}/posts?populate=%2A&pagination[page]=${page}&pagination[pageSize]=6&sort=${sortBy}:${value}`
    )
    .then((res) => {
      dispatch(fetchBlogPostsSuccess(res.data));
      dispatch(returnSuccess(res.data.message, "ok", "BLOG"));
    })
    .catch((err) => {
      dispatch(fetchBlogPostsFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};
