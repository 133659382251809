export const filters = [
  {
    by: "All",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0378 6.10356C9.4079 7.57435 9 9.65851 9 11.9999C9 14.3413 9.4079 16.4255 10.0378 17.8962C10.3533 18.633 10.7097 19.1812 11.0673 19.5344C11.4217 19.8844 11.736 20.0062 12 20.0062C12.264 20.0062 12.5783 19.8844 12.9327 19.5344C13.2903 19.1812 13.6467 18.633 13.9622 17.8962C14.5921 16.4255 15 14.3413 15 11.9999C15 9.65851 14.5921 7.57435 13.9622 6.10356C13.6467 5.36682 13.2903 4.81856 12.9327 4.46538C12.5783 4.11538 12.264 3.99365 12 3.99365C11.736 3.99365 11.4217 4.11538 11.0673 4.46538C10.7097 4.81856 10.3533 5.36682 10.0378 6.10356ZM10.0133 3.39814C10.5562 2.86196 11.2284 2.49365 12 2.49365C12.7716 2.49365 13.4438 2.86196 13.9867 3.39814C14.5264 3.93115 14.978 4.66521 15.3411 5.51304C16.0684 7.21139 16.5 9.50535 16.5 11.9999C16.5 14.4945 16.0684 16.7884 15.3411 18.4868C14.978 19.3346 14.5264 20.0687 13.9867 20.6017C13.4438 21.1378 12.7716 21.5062 12 21.5062C11.2284 21.5062 10.5562 21.1378 10.0133 20.6017C9.47362 20.0687 9.02201 19.3346 8.65891 18.4868C7.93157 16.7884 7.5 14.4945 7.5 11.9999C7.5 9.50535 7.93157 7.21139 8.65891 5.51304C9.02201 4.66521 9.47362 3.93115 10.0133 3.39814Z"
          fill="black"
        />
      </svg>
    ),
  },
  {
    by: "Date",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "#fff" }}
          d="M8 7V3M16 7V3M7 11H17M5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    by: "Success",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.44714 4.74321C2.72703 4.43787 3.20146 4.41724 3.5068 4.69714L12 12.4826L20.4932 4.69714C20.7985 4.41724 21.273 4.43787 21.5529 4.74321C21.8328 5.04855 21.8121 5.52298 21.5068 5.80287L12.5068 14.0529C12.2201 14.3157 11.7799 14.3157 11.4932 14.0529L2.49321 5.80287C2.18787 5.52298 2.16724 5.04855 2.44714 4.74321Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 5.25C2.25 4.83579 2.58579 4.5 3 4.5H21C21.4142 4.5 21.75 4.83579 21.75 5.25V18C21.75 18.3978 21.592 18.7794 21.3107 19.0607C21.0294 19.342 20.6478 19.5 20.25 19.5H3.75C3.35218 19.5 2.97065 19.342 2.68934 19.0607C2.40804 18.7794 2.25 18.3978 2.25 18V5.25ZM3.75 6V18H20.25V6H3.75Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9121 11.4931C11.1921 11.7984 11.1716 12.2728 10.8663 12.5528L3.7413 19.0871C3.43603 19.3671 2.9616 19.3466 2.68163 19.0413C2.40167 18.736 2.42218 18.2616 2.72745 17.9816L9.85246 11.4473C10.1577 11.1673 10.6322 11.1878 10.9121 11.4931Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.0879 11.4931C13.3679 11.1878 13.8423 11.1673 14.1476 11.4473L21.2726 17.9816C21.5778 18.2616 21.5983 18.736 21.3184 19.0413C21.0384 19.3466 20.564 19.3671 20.2587 19.0871L13.1337 12.5528C12.8284 12.2728 12.8079 11.7984 13.0879 11.4931Z"
          fill="black"
        />
      </svg>
    ),
  },
  {
    by: "Failed",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.584 2.37596C11.8359 2.20801 12.1641 2.20801 12.416 2.37596L21.416 8.37596C21.6247 8.51506 21.75 8.74924 21.75 9V18.75C21.75 19.1478 21.592 19.5294 21.3107 19.8107C21.0294 20.092 20.6478 20.25 20.25 20.25H3.75C3.35218 20.25 2.97065 20.092 2.68934 19.8107C2.40804 19.5294 2.25 19.1478 2.25 18.75V9C2.25 8.74924 2.37533 8.51506 2.58397 8.37596L11.584 2.37596ZM3.75 9.40139V18.75H20.25V9.40139L12 3.90139L3.75 9.40139Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.972 13.8173C11.211 14.1556 11.1305 14.6236 10.7922 14.8626L3.66723 19.897C3.32895 20.136 2.86094 20.0555 2.62191 19.7172C2.38288 19.379 2.46335 18.9109 2.80164 18.6719L9.92664 13.6375C10.2649 13.3985 10.7329 13.479 10.972 13.8173Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.0282 13.8173C13.2672 13.479 13.7352 13.3985 14.0735 13.6375L21.1985 18.6719C21.5368 18.9109 21.6172 19.379 21.3782 19.7172C21.1392 20.0555 20.6712 20.136 20.3329 19.897L13.2079 14.8626C12.8696 14.6236 12.7891 14.1556 13.0282 13.8173Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.3895 8.5645C2.63005 8.22729 3.09841 8.14894 3.43562 8.3895L10.5995 13.5001H13.4006L20.5645 8.3895C20.9017 8.14894 21.3701 8.22729 21.6106 8.5645C21.8512 8.9017 21.7728 9.37007 21.4356 9.61062L14.0762 14.8606C13.9491 14.9513 13.7968 15.0001 13.6407 15.0001H10.3594C10.2033 15.0001 10.051 14.9513 9.92387 14.8606L2.5645 9.61062C2.22729 9.37007 2.14894 8.9017 2.3895 8.5645Z"
          fill="black"
        />
      </svg>
    ),
  },
  {
    by: "Duration Listened",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.68934 2.68934C5.97064 2.40804 6.35217 2.25 6.75 2.25H17.25C17.6478 2.25 18.0294 2.40804 18.3107 2.68934C18.592 2.97065 18.75 3.35218 18.75 3.75V7.0875L18.75 7.09212C18.7486 7.3233 18.6941 7.55106 18.5907 7.75783C18.4873 7.96461 18.3378 8.14487 18.1537 8.28472L12.4525 12.5981C12.1858 12.7999 11.8176 12.8007 11.55 12.6L5.85 8.325L5.84631 8.32222C5.66222 8.18237 5.51272 8.00211 5.40933 7.79533C5.30595 7.58856 5.25144 7.3608 5.25001 7.12962L5.25 7.125V3.75C5.25 3.35217 5.40804 2.97064 5.68934 2.68934ZM17.25 3.75H6.75L6.75 7.12084C6.75007 7.12212 6.7504 7.12337 6.75098 7.12451C6.75155 7.12566 6.75235 7.12667 6.75332 7.12749C6.75344 7.12759 6.75357 7.12769 6.75369 7.12779L11.998 11.061L17.2463 7.09028C17.2463 7.09026 17.2464 7.09023 17.2464 7.0902C17.2475 7.08934 17.2484 7.08825 17.249 7.08701C17.2496 7.08587 17.2499 7.08462 17.25 7.08334L17.25 3.75Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.55 11.4C11.8176 11.1993 12.1858 11.2001 12.4525 11.4019L18.1537 15.7153C18.3378 15.8551 18.4873 16.0354 18.5907 16.2422C18.6941 16.4489 18.7486 16.6767 18.75 16.9079L18.75 16.9125V20.25C18.75 20.6478 18.592 21.0294 18.3107 21.3107C18.0294 21.592 17.6478 21.75 17.25 21.75H6.75C6.35218 21.75 5.97065 21.592 5.68934 21.3107C5.40804 21.0294 5.25 20.6478 5.25 20.25V16.875L5.25001 16.8704C5.25144 16.6392 5.30595 16.4114 5.40933 16.2047C5.51272 15.9979 5.66222 15.8176 5.84631 15.6778L5.85 15.675L11.55 11.4ZM6.75 16.8792C6.75007 16.8779 6.7504 16.8766 6.75098 16.8755C6.75155 16.8743 6.75235 16.8733 6.75333 16.8725L11.998 12.939L17.2463 16.9097C17.2464 16.9097 17.2464 16.9098 17.2464 16.9098C17.2475 16.9107 17.2484 16.9117 17.249 16.913C17.2496 16.9141 17.2499 16.9154 17.25 16.9167V20.25H6.75V16.8792Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.25317 8.25C6.25317 7.83579 6.58896 7.5 7.00317 7.5H16.9594C17.3736 7.5 17.7094 7.83579 17.7094 8.25C17.7094 8.66421 17.3736 9 16.9594 9H7.00317C6.58896 9 6.25317 8.66421 6.25317 8.25Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V12C11.25 11.5858 11.5858 11.25 12 11.25Z"
          fill="black"
        />
      </svg>
    ),
  },
];

export const otpFilters = [
  {
    by: "All",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0378 6.10356C9.4079 7.57435 9 9.65851 9 11.9999C9 14.3413 9.4079 16.4255 10.0378 17.8962C10.3533 18.633 10.7097 19.1812 11.0673 19.5344C11.4217 19.8844 11.736 20.0062 12 20.0062C12.264 20.0062 12.5783 19.8844 12.9327 19.5344C13.2903 19.1812 13.6467 18.633 13.9622 17.8962C14.5921 16.4255 15 14.3413 15 11.9999C15 9.65851 14.5921 7.57435 13.9622 6.10356C13.6467 5.36682 13.2903 4.81856 12.9327 4.46538C12.5783 4.11538 12.264 3.99365 12 3.99365C11.736 3.99365 11.4217 4.11538 11.0673 4.46538C10.7097 4.81856 10.3533 5.36682 10.0378 6.10356ZM10.0133 3.39814C10.5562 2.86196 11.2284 2.49365 12 2.49365C12.7716 2.49365 13.4438 2.86196 13.9867 3.39814C14.5264 3.93115 14.978 4.66521 15.3411 5.51304C16.0684 7.21139 16.5 9.50535 16.5 11.9999C16.5 14.4945 16.0684 16.7884 15.3411 18.4868C14.978 19.3346 14.5264 20.0687 13.9867 20.6017C13.4438 21.1378 12.7716 21.5062 12 21.5062C11.2284 21.5062 10.5562 21.1378 10.0133 20.6017C9.47362 20.0687 9.02201 19.3346 8.65891 18.4868C7.93157 16.7884 7.5 14.4945 7.5 11.9999C7.5 9.50535 7.93157 7.21139 8.65891 5.51304C9.02201 4.66521 9.47362 3.93115 10.0133 3.39814Z"
          fill="black"
        />
      </svg>
    ),
  },
  {
    by: "Date",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "#fff" }}
          d="M8 7V3M16 7V3M7 11H17M5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    by: "Status",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.44714 4.74321C2.72703 4.43787 3.20146 4.41724 3.5068 4.69714L12 12.4826L20.4932 4.69714C20.7985 4.41724 21.273 4.43787 21.5529 4.74321C21.8328 5.04855 21.8121 5.52298 21.5068 5.80287L12.5068 14.0529C12.2201 14.3157 11.7799 14.3157 11.4932 14.0529L2.49321 5.80287C2.18787 5.52298 2.16724 5.04855 2.44714 4.74321Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 5.25C2.25 4.83579 2.58579 4.5 3 4.5H21C21.4142 4.5 21.75 4.83579 21.75 5.25V18C21.75 18.3978 21.592 18.7794 21.3107 19.0607C21.0294 19.342 20.6478 19.5 20.25 19.5H3.75C3.35218 19.5 2.97065 19.342 2.68934 19.0607C2.40804 18.7794 2.25 18.3978 2.25 18V5.25ZM3.75 6V18H20.25V6H3.75Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9121 11.4931C11.1921 11.7984 11.1716 12.2728 10.8663 12.5528L3.7413 19.0871C3.43603 19.3671 2.9616 19.3466 2.68163 19.0413C2.40167 18.736 2.42218 18.2616 2.72745 17.9816L9.85246 11.4473C10.1577 11.1673 10.6322 11.1878 10.9121 11.4931Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.0879 11.4931C13.3679 11.1878 13.8423 11.1673 14.1476 11.4473L21.2726 17.9816C21.5778 18.2616 21.5983 18.736 21.3184 19.0413C21.0384 19.3466 20.564 19.3671 20.2587 19.0871L13.1337 12.5528C12.8284 12.2728 12.8079 11.7984 13.0879 11.4931Z"
          fill="black"
        />
      </svg>
    ),
  },
  {
    by: "Delivery Status",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.584 2.37596C11.8359 2.20801 12.1641 2.20801 12.416 2.37596L21.416 8.37596C21.6247 8.51506 21.75 8.74924 21.75 9V18.75C21.75 19.1478 21.592 19.5294 21.3107 19.8107C21.0294 20.092 20.6478 20.25 20.25 20.25H3.75C3.35218 20.25 2.97065 20.092 2.68934 19.8107C2.40804 19.5294 2.25 19.1478 2.25 18.75V9C2.25 8.74924 2.37533 8.51506 2.58397 8.37596L11.584 2.37596ZM3.75 9.40139V18.75H20.25V9.40139L12 3.90139L3.75 9.40139Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.972 13.8173C11.211 14.1556 11.1305 14.6236 10.7922 14.8626L3.66723 19.897C3.32895 20.136 2.86094 20.0555 2.62191 19.7172C2.38288 19.379 2.46335 18.9109 2.80164 18.6719L9.92664 13.6375C10.2649 13.3985 10.7329 13.479 10.972 13.8173Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.0282 13.8173C13.2672 13.479 13.7352 13.3985 14.0735 13.6375L21.1985 18.6719C21.5368 18.9109 21.6172 19.379 21.3782 19.7172C21.1392 20.0555 20.6712 20.136 20.3329 19.897L13.2079 14.8626C12.8696 14.6236 12.7891 14.1556 13.0282 13.8173Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.3895 8.5645C2.63005 8.22729 3.09841 8.14894 3.43562 8.3895L10.5995 13.5001H13.4006L20.5645 8.3895C20.9017 8.14894 21.3701 8.22729 21.6106 8.5645C21.8512 8.9017 21.7728 9.37007 21.4356 9.61062L14.0762 14.8606C13.9491 14.9513 13.7968 15.0001 13.6407 15.0001H10.3594C10.2033 15.0001 10.051 14.9513 9.92387 14.8606L2.5645 9.61062C2.22729 9.37007 2.14894 8.9017 2.3895 8.5645Z"
          fill="black"
        />
      </svg>
    ),
  },
];

export const smsFilters = [
  {
    by: "All",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0378 6.10356C9.4079 7.57435 9 9.65851 9 11.9999C9 14.3413 9.4079 16.4255 10.0378 17.8962C10.3533 18.633 10.7097 19.1812 11.0673 19.5344C11.4217 19.8844 11.736 20.0062 12 20.0062C12.264 20.0062 12.5783 19.8844 12.9327 19.5344C13.2903 19.1812 13.6467 18.633 13.9622 17.8962C14.5921 16.4255 15 14.3413 15 11.9999C15 9.65851 14.5921 7.57435 13.9622 6.10356C13.6467 5.36682 13.2903 4.81856 12.9327 4.46538C12.5783 4.11538 12.264 3.99365 12 3.99365C11.736 3.99365 11.4217 4.11538 11.0673 4.46538C10.7097 4.81856 10.3533 5.36682 10.0378 6.10356ZM10.0133 3.39814C10.5562 2.86196 11.2284 2.49365 12 2.49365C12.7716 2.49365 13.4438 2.86196 13.9867 3.39814C14.5264 3.93115 14.978 4.66521 15.3411 5.51304C16.0684 7.21139 16.5 9.50535 16.5 11.9999C16.5 14.4945 16.0684 16.7884 15.3411 18.4868C14.978 19.3346 14.5264 20.0687 13.9867 20.6017C13.4438 21.1378 12.7716 21.5062 12 21.5062C11.2284 21.5062 10.5562 21.1378 10.0133 20.6017C9.47362 20.0687 9.02201 19.3346 8.65891 18.4868C7.93157 16.7884 7.5 14.4945 7.5 11.9999C7.5 9.50535 7.93157 7.21139 8.65891 5.51304C9.02201 4.66521 9.47362 3.93115 10.0133 3.39814Z"
          fill="black"
        />
      </svg>
    ),
  },
  {
    by: "Date",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "#fff" }}
          d="M8 7V3M16 7V3M7 11H17M5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    by: "Status",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.44714 4.74321C2.72703 4.43787 3.20146 4.41724 3.5068 4.69714L12 12.4826L20.4932 4.69714C20.7985 4.41724 21.273 4.43787 21.5529 4.74321C21.8328 5.04855 21.8121 5.52298 21.5068 5.80287L12.5068 14.0529C12.2201 14.3157 11.7799 14.3157 11.4932 14.0529L2.49321 5.80287C2.18787 5.52298 2.16724 5.04855 2.44714 4.74321Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 5.25C2.25 4.83579 2.58579 4.5 3 4.5H21C21.4142 4.5 21.75 4.83579 21.75 5.25V18C21.75 18.3978 21.592 18.7794 21.3107 19.0607C21.0294 19.342 20.6478 19.5 20.25 19.5H3.75C3.35218 19.5 2.97065 19.342 2.68934 19.0607C2.40804 18.7794 2.25 18.3978 2.25 18V5.25ZM3.75 6V18H20.25V6H3.75Z"
          fill="black"
        />
        <path
          style={{ fill: "black" }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9121 11.4931C11.1921 11.7984 11.1716 12.2728 10.8663 12.5528L3.7413 19.0871C3.43603 19.3671 2.9616 19.3466 2.68163 19.0413C2.40167 18.736 2.42218 18.2616 2.72745 17.9816L9.85246 11.4473C10.1577 11.1673 10.6322 11.1878 10.9121 11.4931Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.0879 11.4931C13.3679 11.1878 13.8423 11.1673 14.1476 11.4473L21.2726 17.9816C21.5778 18.2616 21.5983 18.736 21.3184 19.0413C21.0384 19.3466 20.564 19.3671 20.2587 19.0871L13.1337 12.5528C12.8284 12.2728 12.8079 11.7984 13.0879 11.4931Z"
          fill="black"
        />
      </svg>
    ),
  },
];
