import * as types from "./actionTypes";
import axios from "../../utils/axios-base";
import { returnErrors, returnSuccess } from "./messageActions";

export const sendBusinessMessageStart = () => {
  return {
    type: types.SEND_BUSINESS_MESSAGE_START,
  };
};

export const sendBusinessMessageSuccess = (data, status, id) => {
  return {
    type: types.SEND_BUSINESS_MESSAGE_SUCCESS,
    data,
  };
};

const sendBusinessMessageFail = () => ({
  type: types.SEND_BUSINESS_MESSAGE_FAIL,
});

export const sendBusinessMessageVoice = (formData) => (dispatch) => {
  dispatch(sendBusinessMessageStart());

  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data",
    },
  };

  axios
    .post("/telemarketing/dev/voice", formData, config)
    .then((res) => {
      dispatch(sendBusinessMessageSuccess());
      dispatch(
        returnSuccess(
          res.data.message,
          res.status,
          "SEND_BUSINESS_MESSAGE_SUCCESS"
        )
      );
    })
    .catch((err) => {
      dispatch(sendBusinessMessageFail());
      dispatch(
        returnErrors(
          err.response?.data,
          err.response?.status,
          "SEND_BUSINESS_MESSAGE_FAIL"
        )
      );
    });
};

export const sendBusinessMessageSms = (formData) => (dispatch) => {
  dispatch(sendBusinessMessageStart());

  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data",
    },
  };

  axios
    .post("/telemarketing/dev/sms", formData, config)
    .then((res) => {
      dispatch(sendBusinessMessageSuccess());
      dispatch(
        returnSuccess(
          res.data.message,
          res.status,
          "SEND_BUSINESS_MESSAGE_SUCCESS"
        )
      );
      // console.log(res.data)
    })
    .catch((err) => {
      // console.log(err)

      dispatch(sendBusinessMessageFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));

      // dispatch(returnErrors(err.response?.data, err.response?.status, 'SEND_BUSINESS_MESSAGE_FAIL'))
    });
};

export const sendBusinessMessageWhatsApp = (formData) => (dispatch) => {
  dispatch(sendBusinessMessageStart());

  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data",
    },
  };

  axios
    .post("/telemarketing/dev/whatsapp", formData, config)
    .then((res) => {
      dispatch(sendBusinessMessageSuccess());
      dispatch(
        returnSuccess(
          res.data.message,
          res.status,
          "SEND_BUSINESS_MESSAGE_SUCCESS"
        )
      );
      // console.log(res.data)
    })
    .catch((err) => {
      // console.log(err)

      dispatch(sendBusinessMessageFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));

      // dispatch(returnErrors(err.response?.data, err.response?.status, 'SEND_BUSINESS_MESSAGE_FAIL'))
    });
};

export const sendRichBusinessMessageSms = (formData) => (dispatch) => {
  dispatch(sendBusinessMessageStart());

  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data",
    },
  };

  axios
    .post("/telemarketing/dev/sms/rich", formData, config)
    .then((res) => {
      dispatch(sendBusinessMessageSuccess());
      dispatch(
        returnSuccess(
          res.data.message,
          res.status,
          "SEND_BUSINESS_MESSAGE_SUCCESS"
        )
      );
      // console.log(res.data)
    })
    .catch((err) => {
      // console.log(err)

      dispatch(sendBusinessMessageFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));

      // dispatch(returnErrors(err.response?.data, err.response?.status, 'SEND_BUSINESS_MESSAGE_FAIL'))
    });
};
