import axios from "axios";
import React from "react";
import { useEffect } from "react";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import SubscribeForm from "../../../../components/SubscribeForm";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./index.module.scss";

import facebookIcon from "../../../../assets/images/facebookBlack.svg";
import linkedinIcon from "../../../../assets/images/linkedinBlack.svg";
import twitterIcon from "../../../../assets/images/twitterBlack.svg";

const ViewBlogPost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [post, setPost] = React.useState({});
  const [posts, setPosts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const featuredPosts = async () => {
      setIsLoading(true);
      try {
        axios
          .get(`${process.env.REACT_APP_BLOG_URL}/posts/${id}?populate=*`)
          .then((res) => {
            setIsLoading(false);
            console.log(res.data.data);
            setPost(res.data.data);
          });
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    featuredPosts();
    scrollIntoView.current.scrollIntoView({ behavior: "smooth" });
  }, [id]);
  const readingTime = (textToCount) => {
    const text = textToCount;
    const wpm = 225;
    const words = text?.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    return time;
  };

  const convertDate = (date) => {
    return `${new Date().toDateString(date)}`;
  };
  const scrollIntoView = React.useRef(null);
  const fetchPosts = async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BLOG_URL}/posts?populate=%2A&pagination[page]=1&pagination[pageSize]=2`
        )
        .then((res) => {
          setPosts(res.data.data);
          console.log(res.data);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPosts();
  }, []);

  const handleViewBlog = (id, name) => {
    navigate(`/blog/${id}/${name}`);
  };

  return (
    <>
      <div ref={scrollIntoView}></div>
      <Header theme="darkTheme" bgStyle="blog__page--bg"></Header>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className={style["blog__container"]}>
          <div className={style["header__container"]}>
            <img
              src={post?.attributes?.image_url}
              alt={post?.attributes?.title}
            />
          </div>

          {post && (
            <div className={style["blog__post"]}>
              <div className={style["blog--header"]}>
                <div className={style["top"]}>
                  <p onClick={() => navigate(-1)}>← Go back</p>

                  <div className={style["post-info"]}>
                    <p>{post?.attributes?.category?.data?.attributes?.name}</p>
                    <p>
                      {readingTime(post?.attributes?.description)} mins read
                    </p>
                  </div>
                </div>
                <h3>{post?.attributes?.title}</h3>
                <div className={style["author--section"]}>
                  <div className={style["author--info"]}>
                    <img
                      src={
                        post?.attributes?.author?.data?.attributes?.avatar_url
                      }
                      alt={post?.attributes?.author?.data?.attributes?.Name}
                    />
                    <div>
                      <p className={style["name"]}>
                        {post?.attributes?.author?.data?.attributes?.Name}
                      </p>
                      <p className={style["date"]}>
                        {convertDate(post?.attributes?.createdAt)}
                      </p>
                    </div>
                  </div>

                  <div className={style["author--social"]}>
                    <p>Share this</p>
                    <div className={style["social--icons"]}>
                      <a
                        target="_blank"
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${post?.attributes?.title}&source=${window.location.host}`}
                        rel="noreferrer"
                      >
                        <img src={linkedinIcon} alt="linkedin" />
                      </a>
                      <a
                        target="_blank"
                        href={`https://www.facebook.com/share.php?u=${window.location.href}&title=${post?.attributes?.title}`}
                        rel="noreferrer"
                      >
                        <img src={facebookIcon} alt="facebook" />
                      </a>
                      <a
                        target="_blank"
                        href={`https://twitter.com/home/?status=${post?.attributes?.title}${window.location.href}`}
                        rel="noreferrer"
                      >
                        <img src={twitterIcon} alt="twitter" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style["post--body"]}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post?.attributes?.description.replaceAll(
                      "<a",
                      '<a target="_blank"'
                    ),
                  }}
                ></div>
              </div>
            </div>
          )}

          <div className={style["suggest--post"]}>
            <h3>You might also like</h3>

            <div className={style["post--list"]}>
              {posts.map((post, index) => (
                <div
                  className={style["post--list__item"]}
                  key={index}
                  onClick={() =>
                    handleViewBlog(post.id, post?.attributes?.title)
                  }
                >
                  <div className={style["post--image"]}>
                    <img src={post?.attributes?.image_url} alt="" />
                  </div>
                  <div className={style["post--content"]}>
                    <p className={style["category"]}>
                      {post?.attributes?.category?.data?.attributes?.name}
                    </p>
                    <h3 className={style["title"]}>
                      {post?.attributes?.title}
                    </h3>
                    <div className={style["post--footer"]}>
                      <p className={style["author"]}>
                        {post?.attributes?.author?.data?.attributes?.Name}
                      </p>
                      <p className={style["reading-time"]}>
                        {readingTime(post?.attributes?.description)} mins read
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <SubscribeForm />
      <Footer />
    </>
  );
};

export default ViewBlogPost;
