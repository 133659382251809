/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useMailChimp from "../../hooks/useMailChimp";
import style from "./index.module.scss";
import contactImg from "../../assets/friends.webp";
import { Status } from "../../hooks/useMailChimp";

export default function SubscribeForm() {
  const [form, setForm] = React.useState({ firstName: "", email: "" });
  const { subscribe, status, error, value } = useMailChimp(
    "https://maxresistance.us1.list-manage.com/subscribe/post?u=c4189e9b258f9b10c33432633&amp;id=5fe37b10c4"
  );
  console.log(status);
  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  };

  const [show, setShow] = React.useState(true);

  const handleStatus = () => {
    switch (status) {
      case "ERROR":
        return error?.includes("already subscribed") ? (
          <p className={style["subscribe--form__success"]}>
            You are already Subscribed
          </p>
        ) : (
          <p className={style["subscribe--form__error"]}>{error}</p>
        );
      case "SUCCESS":
        return <p className={style["subscribe--form__success"]}>{value}</p>;
      default:
        return null;
    }
  };

  React.useEffect(() => {
    handleStatus();
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // // We are performing some simple validation here.
    // // I highly recommend checking a fully-fledged solution
    // // for forms like https://react-hook-form.com/
    if (form.email === "") return;

    subscribe({
      FNAME: form.firstName,
      EMAIL: form.email,
    });

    console.log(show);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  };

  return (
    <div className={style["subscribe--form__container"]}>
      <form className={style["subscribe--form"]}>
        <header>
          <h1>Be a part of our superstar community today!</h1>
          <p>
            Subscribe to our newsletter today, and be the first to get timely
            updates on everything concerning MyServiceAgent.
          </p>
        </header>
        {/* <label htmlFor="firstName">First Name</label>
        <input
          id="firstName"
          value={form.firstName}
          onChange={handleInputChange}
          name="firstName"
        /> */}

        {/* <label htmlFor="email">Email</label> */}
        <input
          id="email"
          name="email"
          placeholder="Enter your email address"
          value={form.email}
          onChange={handleInputChange}
        />
        <button onClick={handleSubmit} disabled={status === Status.loading}>
          {status === Status.loading ? "Please wait" : "Subscribe Now"}
        </button>
        {handleStatus()}
      </form>

      <div className={style["container--right"]}>
        <img src={contactImg} alt="contact" height="100%" width="100%" />
      </div>
    </div>
  );
}
