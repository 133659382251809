/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { BlogPagination } from "../../../components/Pagination";
import SubscribeForm from "../../../components/SubscribeForm";
import style from "./index.module.scss";
import SearchData from "../../../assets/images/search-data.svg";
import { useDispatch, useSelector } from "react-redux";
import expandIcon from "../../../assets/sort.svg";
import {
  fetchBlogPosts,
  fetchPostsbyCategory,
  fetchSortedBlogPosts,
} from "../../../redux/actions/blogActions";
import { useRef } from "react";
import { useClickOutside } from "../../../hooks/useClickOutside";

const DropDown = ({ handleSelect }) => {
  const filters = ["A - Z", "Last added", "All time"];
  return (
    <div className={style["dropdown"]}>
      <div className={style["actions"]}>
        {filters.map((item, index) => (
          <p
            className={style["action"]}
            key={index}
            onClick={() => handleSelect(item)}
          >
            <span>{item}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

const Blog = () => {
  const [categories, setCategories] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState("All");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [sort, setSort] = React.useState("All Time");
  const [recentPost, setRecentPost] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const outsideClose = useRef();

  const posts = useSelector((state) => state.blog?.data?.data);
  const pagination = useSelector((state) => state.blog?.data?.meta?.pagination);

  const handleViewBlog = (id, name) => {
    navigate(`/blog/${id}/${name}`);
  };

  const top = React.useRef(null);

  React.useEffect(() => {
    top.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        axios
          .get(`${process.env.REACT_APP_BLOG_URL}/categories`)
          .then((res) => {
            setCategories(res.data.data);
            top.current.scrollIntoView({ behavior: "smooth" });
          });
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    dispatch(fetchBlogPosts(currentPage, 6));
  }, []);

  useEffect(() => {
    const getRecentPost = async () => {
      setIsLoading(true);
      try {
        axios
          .get(
            `${process.env.REACT_APP_BLOG_URL}/posts?populate=%2A&sort=createdAt:DESC`
          )
          .then((res) => {
            setIsLoading(false);
            setRecentPost(res.data.data[0]);
          });
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getRecentPost();
  }, [posts]);

  useClickOutside(outsideClose, setOpen);

  const sortFunction = (a, b) => {
    if (a?.attributes?.name < b?.attributes?.name) {
      return -1;
    }
    if (a?.attributes?.name > b?.attributes?.name) {
      return 1;
    }
    return 0;
  };

  const handleTabChange = (e) => {
    setCurrentTab(e);
    if (e === "All") {
      dispatch(fetchBlogPosts(currentPage, 6));
      return;
    }
    dispatch(fetchPostsbyCategory(e));
  };

  const readingTime = (textToCount) => {
    const text = textToCount;
    const wpm = 225;
    const words = text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    return time;
  };

  const handleNext = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(fetchBlogPosts(pageNumber));
  };

  const handleSort = (sort) => {
    setSort(sort);
    setOpen(false);
    if (sort === "A - Z") {
      dispatch(fetchSortedBlogPosts(currentPage, "title", "ASC"));
      return;
    } else if (sort === "Last added") {
      dispatch(fetchSortedBlogPosts(currentPage, "createdAt", "DESC"));
      return;
    }
    dispatch(fetchBlogPosts(currentPage));
    console.log(sort);
  };

  const truncate = (string = "", maxLength = 50) =>
    string.length > maxLength ? `${string.substring(0, maxLength)}` : string;
  return (
    <>
      <div ref={top}></div>
      <Header theme="darkTheme" bgStyle="blog__page--bg">
        <div className={style["header__details"]}>
          <div className={style["tabs"]}>
            {categories.sort(sortFunction).map((category, index) => (
              <div
                className={`${style["tab"]} ${
                  currentTab === category?.attributes?.name
                    ? style["active"]
                    : ""
                }`}
                key={index}
                onClick={() => handleTabChange(category?.attributes?.name)}
              >
                <p> {category?.attributes?.name}</p>
              </div>
            ))}
          </div>
        </div>
      </Header>

      {!posts?.length ? (
        <div className={style["not-found"]}>
          <div className={style["not-found__content"]}>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <>
                <img src={SearchData} alt="" />
                <h3>New Blog Posts will appear here when they are available</h3>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={style["container"]} ref={top}>
          <div className={style["recent--post"]}>
            <h3>Read our Latest Story</h3>
            {
              <div
                className={style["recent--post__container"]}
                onClick={() =>
                  handleViewBlog(recentPost.id, recentPost?.attributes?.title)
                }
              >
                <img src={recentPost?.attributes?.image_url} alt="" />

                <div className={style["post__content"]}>
                  <p className={style["category"]}>
                    {recentPost?.attributes?.category?.data?.attributes?.name}
                  </p>
                  <h3>{recentPost?.attributes?.title}</h3>
                  <p
                    className={style["preview"]}
                    dangerouslySetInnerHTML={{
                      __html: truncate(
                        recentPost?.attributes?.description,
                        300
                      ),
                    }}
                  ></p>
                  <div className={style["post-author"]}>
                    <img
                      src={
                        recentPost?.attributes?.author?.data?.attributes
                          ?.avatar_url
                      }
                      alt=""
                    />
                    <p>
                      by{" "}
                      {recentPost?.attributes?.author?.data?.attributes?.Name}
                    </p>
                  </div>
                </div>
              </div>
            }
          </div>

          <div className={style["post__header"]} ref={outsideClose}>
            <h3>You might also like</h3>
            <div className={style["sort"]}>
              <p>Sort by</p>
              <div className={style["filter"]} onClick={() => setOpen(!open)}>
                <p>{sort}</p>
                <img src={expandIcon} alt="" />
              </div>
              {open && <DropDown handleSelect={(item) => handleSort(item)} />}
            </div>
          </div>

          <div className={style["post--list"]}>
            {posts
              ?.filter((post, index) => post.id !== recentPost.id)
              .map((post, index) => (
                <div
                  className={style["post--list__item"]}
                  key={index}
                  onClick={() =>
                    handleViewBlog(post.id, post?.attributes?.title)
                  }
                >
                  <div className={style["post--image"]}>
                    <img src={post?.attributes?.image_url} alt="" />
                  </div>
                  <div className={style["post--content"]}>
                    <p className={style["category"]}>
                      {post?.attributes?.category?.data?.attributes?.name}
                    </p>
                    <h3 className={style["title"]}>
                      {post?.attributes?.title}
                    </h3>
                    <div className={style["post--footer"]}>
                      <p className={style["author"]}>
                        {post?.attributes?.author?.data?.attributes?.Name}
                      </p>
                      <p className={style["reading-time"]}>
                        {readingTime(post?.attributes?.description)} mins read
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {!posts ? (
            ""
          ) : (
            <div className={style["blog--pagination"]}>
              <div>
                <BlogPagination
                  numberOfPages={Math.ceil(pagination?.total / 6)}
                  currentPage={currentPage}
                  nextPage={handleNext}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <SubscribeForm />
      <Footer />
    </>
  );
};

export default Blog;
