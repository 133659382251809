import React from "react";
import { NavLink } from "react-router-dom";
import Layout from "../DashboardLayout";
import style from "./index.module.scss";

export default function AccountLayout({ children, title, description, image }) {
  // let { path, url } = useRouteMatch();

  const Tabs = [
    {
      name: "Profile",
      link: `/user/settings`,
    },
    {
      name: "API Key",
      link: `/user/settings/Apikey`,
    },
    {
      name: "Change Password",
      link: `/user/settings/change-password`,
    },
  ];

  return (
    <Layout pageTitle="Account">
      <section className={style["accountLayout__container"]}>
        <div className={style["accountLayout--header"]}>
          <header>
            <h3>{title}</h3>
            <p>{description}</p>
          </header>
        </div>
        <ul className={style["accountLayout--links"]}>
          {Tabs.map((item, key) => (
            <NavLink
              key={key * 100}
              activeClassName={style["active"]}
              exact={true}
              to={item.link}
            >
              <li>{item.name}</li>
            </NavLink>
          ))}
        </ul>
        <main className={style["accountLayout--children"]}>{children}</main>
      </section>
    </Layout>
  );
}
