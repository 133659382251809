import React from "react";
import style from "./index.module.scss";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ContactForm from "../../../components/ContactForm";
import conversionIcon from "../../../assets/images/pricing/price__conversion.svg";
import greenTickIcon from "../../../assets/images/pricing/green__tick.svg";
import pricingHero from "../../../assets/icons/pricingHero.svg";

export default function Pricing() {
  const pricingContentTop = React.useRef(null);
  const pricingTop = React.useRef(null);

  const pricingCards = [
    {
      tag: "otp",
      title: "MSA OTP Authentication",
      cardBg: "#EB2931",
      link: "/otpauthentication",
    },
    {
      tag: "business",
      title: "MSA Business Messaging",
      cardBg: "#6B080D",
      link: "/business-messaging",
    },
    {
      tag: "ivr",
      title: "MSA Smart IVR",
      cardBg: "#000000",
      link: "/call-center",
      text: "Build an intelligent call center system that can manage 1000+ customer calls with unique responses to each caller using localised languages.",
    },
  ];

  // function scrollDown(){
  //     pricingContentTop.current.scrollIntoView({behavior: "smooth"});
  // }

  React.useEffect(() => {
    pricingTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <div ref={pricingTop}></div>
      <Header theme="darkTheme">
        <div className={style["header__details"]}>
          <div>
            <h3>Our Pricing</h3>
            <p>Flexible pricing Tailored for your growth</p>
          </div>
          <img src={pricingHero} alt="scroll down" />
        </div>
      </Header>

      <div className={style["pricing__content"]} ref={pricingContentTop}>
        <div className={style["container"]}>
          <div className={style["top__details"]}>
            <div className={style["top__details__text"]}>
              <h1>Flexible pricing</h1>
              <p>No contracts. No surprise fees.</p>
            </div>
            <img src={conversionIcon} alt="price conversion" />
          </div>

          <div className={style["pricing__card__list"]}>
            {pricingCards.map((item, index) => (
              <PricingCard item={item} key={index} />
            ))}
          </div>
        </div>
      </div>

      <ContactForm />
      <Footer />
    </>
  );
}

function PricingCard(props) {
  return (
    <div className={style["pricing__card__flex"]}>
      <div className={style["pricing__card"]}>
        <div className={style["pricing__card__details"]}>
          <p>{props.item.title}</p>
          <hr />
          {props.item.tag === "ivr" ? (
            <span>{props.item.text}</span>
          ) : (
            <table>
              <tbody>
                <tr>
                  <td className={style["first__data"]}>
                    <img src={greenTickIcon} alt="green tick" />
                    {props.item.tag === "otp" ? "SMS OTP" : "SMS Messaging"}
                  </td>
                  <td>
                    <strong>3.5 Units / SMS</strong>
                  </td>
                </tr>
                <tr>
                  <td className={style["first__data"]}>
                    <img src={greenTickIcon} alt="green tick" />
                    {props.item.tag === "otp" ? "Voice OTP" : "Voice Messaging"}
                  </td>
                  <td>
                    <strong>
                      {props.item.tag === "otp"
                        ? "5 Units / Call"
                        : "22 Units / minute"}
                    </strong>
                  </td>
                  {/* <td><strong>7.5 Units / Call</strong></td> */}
                </tr>
                {props.item.tag === "otp" && (
                  <tr>
                    <td className={style["first__data"]}>
                      <img src={greenTickIcon} alt="green tick" />
                      Verification API
                    </td>
                    <td>
                      <strong>0.5 Unit / request</strong>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div
          className={style["pricing__card__footer"]}
          style={{ backgroundColor: props.item.cardBg }}
        >
          <Link to={props.item.link}>
            <span style={{ color: "#ffffff" }}>
              {props.item.tag === "ivr" ? "Talk to an expert" : "Get Started →"}
            </span>
          </Link>
        </div>
      </div>
      <div
        className={style["pricing__card__sidebar"]}
        style={{ backgroundColor: props.item.cardBg }}
      ></div>
    </div>
  );
}
