import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout/DashboardLayout";
import style from "./index.module.scss";

export default function SendMessages() {
  return (
    <Layout pageTitle="Business Messaging">
      <main className={style["business__messaging--container"]}>
        <header>
          <p>
            Engage your customers better with intelligent voice and sms messaging. 
            Send instant or scheduled voice or SMS messages to your users by filling a simple form or utilizing our API.
          </p>
        </header>
        <OnboardingOption />
      </main>
    </Layout>
  );
}

const OnboardingOption = () => {
  const content = [
    {
      title: "Send Business Messages as Non Developer",
      content:
        "As a marketer, sales rep, user researcher, etc, you can use our voice and business messaging without writing a single line of code. Instantly send or schedule messages in less than a minute by utilizing our simple form.",
      route: "/user/send-messages/developer",
    },
    
    {
      title: "Send Business Messages as a Developer",
      content:
        "Integrate messaging APIs into your applications using your preferred programming languages in no time with highly customizable configurations. Developers with basic programming skills can schedule or instantaneously initiate calls or SMS to be sent to their customers.",
      route: "/business-messaging",
      external: true,
    },
  ];

  return (
    <ul className={style["card__container"]}>
      {content.map((item) => {
        return (
          <li>
            <header>
              <h4>{item.title}</h4>
            </header>
            <p>{item.content}</p>
            {item.external ? (
              <a href="https://documentation.myserviceagent.net">Continue</a>
            ) : (
              <Link to={item.route}>Continue</Link>
            )}
          </li>
        );
      })}
    </ul>
  );
};






