/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button";

import Layout from "../../../../components/Layout/DashboardLayout";
import style from "./index.module.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import Dialog from "../../../../components/Dialog";
import { Pagination } from "../../../../components/Pagination";

const ViewPhonebook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [contacts, setContacts] = React.useState(null);
  const id = location.pathname.split("/")[3];
  const name = location.pathname.split("/")[4];
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const userInfo = useSelector((state) => state.auth.authState);
  const [currentPage, setCurrentPage] = useState(1);

  const toggling = (index) => {
    setSelectedId(index);
    setDeleteModalOpen(!deleteModalOpen);
  };

  const formData = new FormData();

  formData.append("client_id", userInfo?.id);
  formData.append("token", userInfo?.token);

  useEffect(() => {
    const getPhonebookDetails = async () => {
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/phonebook/dev/client/${id}/items`,
          formData
        );

        if (response.status === 200) {
          response.data.data
            ? setContacts(response.data)
            : (window.location.replace = "/user/phonebook");
        } else {
          setContacts(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getPhonebookDetails();
  }, [id]);

  const fetchhonebookDetails = async () => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/phonebook/dev/client/${id}/items`,
        formData
      );

      if (response.status === 200) {
        response.data.data
          ? setContacts(response.data)
          : (window.location.replace = "/user/phonebook");
      } else {
        setContacts(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    formData.append("page", pageNumber);
    fetchhonebookDetails(formData);
  };

  const handleDeletePhonebook = async () => {
    setIsLoading(true);
    setDeleteModalOpen(!deleteModalOpen);
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/phonebook/dev/${selectedId}/item/delete`,
        {
          client_id: userInfo.id,
          token: userInfo.token,
          phonebook_id: id,
        }
      );

      if (res.status) {
        fetchhonebookDetails();
      } else {
        console.log(res.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const th = [
    {
      name: "S/N",
    },
    {
      name: "Name",
    },
    {
      name: "Contact Number",
    },
    {
      name: "",
    },
  ];

  return (
    <Layout pageTitle="View contacts">
      <Dialog isOpen={isLoading}>
        <ScaleLoader />
      </Dialog>
      <Dialog
        isOpen={deleteModalOpen}
        toggleModal={() => setDeleteModalOpen(!deleteModalOpen)}
      >
        <div className={style.deleteModal}>
          <p>Are You Sure you want to Delete This Contact?</p>
          <div className={style.buttons}>
            <div>
              <Button
                type="submit"
                className="medium--filled"
                onClick={handleDeletePhonebook}
              >
                Yes
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                className="medium--ghost"
                onClick={() => {
                  setDeleteModalOpen(!deleteModalOpen);
                }}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <div style={{ padding: "2rem" }}>
        <a href="/user/phonebook">
          {" "}
          <span>
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 15L1 8M1 8L8 1M1 8L19 8"
                stroke="#EB2931"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>{" "}
          <span> Back</span>
        </a>
        <div className={style["table--top"]}>
          <p style={{ position: "relative", top: "30px" }}>
            All contacts in {name.replaceAll("%20", " ")} Phonebook
          </p>
          <Button
            type="button"
            className="small--filled"
            onClick={() => navigate(`/user/phonebook/add/${id}/${name}`)}
            style={{ width: "210px" }}
          >
            <span style={{ paddingRight: ".5rem" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style={{ fill: "#fff" }}
                  d="M16 7V10V7ZM16 10V13V10ZM16 10H19H16ZM16 10H13H16ZM11 5C11 6.06087 10.5786 7.07828 9.82843 7.82843C9.07828 8.57857 8.06087 9 7 9C5.93913 9 4.92172 8.57857 4.17157 7.82843C3.42143 7.07828 3 6.06087 3 5C3 3.93913 3.42143 2.92172 4.17157 2.17157C4.92172 1.42143 5.93913 1 7 1C8.06087 1 9.07828 1.42143 9.82843 2.17157C10.5786 2.92172 11 3.93913 11 5V5ZM1 18C1 16.4087 1.63214 14.8826 2.75736 13.7574C3.88258 12.6321 5.4087 12 7 12C8.5913 12 10.1174 12.6321 11.2426 13.7574C12.3679 14.8826 13 16.4087 13 18V19H1V18Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            Add Contact
          </Button>
        </div>

        <div className={style["table--container"]}>
          <table id="test">
            <thead>
              <tr>
                {th.map((item, index) => {
                  return <th key={index}>{item.name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {contacts &&
                contacts.data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.msisdn}</td>
                    <td>
                      <span onClick={() => toggling(item.id)}>
                        {" "}
                        <svg
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            style={{ fill: "#fff" }}
                            d="M7 9V15M11 9V15M1 5H17M16 5L15.133 17.142C15.0971 17.6466 14.8713 18.1188 14.5011 18.4636C14.1309 18.8083 13.6439 19 13.138 19H4.862C4.35614 19 3.86907 18.8083 3.49889 18.4636C3.1287 18.1188 2.90292 17.6466 2.867 17.142L2 5H16ZM12 5V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1H7C6.73478 1 6.48043 1.10536 6.29289 1.29289C6.10536 1.48043 6 1.73478 6 2V5H12Z"
                            stroke="#EB2931"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />{" "}
                        </svg>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className={style["pagination"]}>
          {contacts && (
            <Pagination
              numberOfPages={Math.ceil(contacts.total / 10)}
              currentPage={currentPage}
              nextPage={nextPage}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ViewPhonebook;
