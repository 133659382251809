import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import style from "./index.module.scss";

export default function WalletLayout({ children }) {
  const location = useLocation();

  const NavLinks = [
    {
      title: "Credit",
      route: "/wallet",
    },
    {
      title: "History",
      route: "/wallet/history",
    },
    {
      title: "Upload Payment Evidence",
      route: "/wallet/paymentevidence",
    },
  ];
  return (
    <section>
      {location.pathname !== '/user/usage-report' && 
      <nav className={style["wallets--nav"]}>
        <ul>
          {NavLinks.map((item) => {
            return (
              <NavLink
                exact={true}
                activeClassName={style["active"]}
                to={item.route}
              >
                <li>{item.title}</li>
              </NavLink>
            );
          })}
        </ul>
      </nav>}
      <main className={style["wallets--main"]}>{children}</main>
    </section>
  );
}
