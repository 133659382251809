import * as types from "./actionTypes";
import axios from "axios";
import { returnErrors, returnSuccess } from "./messageActions";

export const createPhonebookStart = () => {
  return {
    type: types.CREATE_PHONEBOOK_START,
  };
};

export const createPhonebookSuccess = (data, status, id) => {
  return {
    type: types.CREATE_PHONEBOOK_SUCCESS,
    data,
  };
};

const createPhonebookFail = () => ({
  type: types.CREATE_PHONEBOOK_FAIL,
});

export const createSingleOrBatchPhonebook = (formData) => (dispatch) => {
  dispatch(createPhonebookStart());

  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data",
    },
  };

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/phonebook/dev`, formData, config)
    .then((res) => {
      dispatch(createPhonebookSuccess());
      dispatch(
        returnSuccess(res.data.message, res.status, "CREATE_PHONEBOOK_SUCCESS")
      );
    })
    .catch((err) => {
      dispatch(createPhonebookFail());

      dispatch(
        returnErrors(
          err.response?.data,
          err.response?.status,
          "CREATE_PHONEBOOK_FAIL"
        )
      );
    });
};

export const updateSingleOrBatchPhonebook = (formData, id) => (dispatch) => {
  dispatch(createPhonebookStart());

  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data",
    },
  };

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/phonebook/dev/${id}`,
      formData,
      config
    )
    .then((res) => {
      dispatch(createPhonebookSuccess());
      dispatch(
        returnSuccess(
          res.data.message,
          res.status,
          "SEND_BUSINESS_MESSAGE_SUCCESS"
        )
      );
    })
    .catch((err) => {
      dispatch(createPhonebookFail());
      dispatch(
        returnErrors(
          dispatch(
            returnErrors(
              err.response?.data,
              err.response?.status,
              "SEND_BUSINESS_MESSAGE_FAIL"
            )
          )
        )
      );
    });
};
