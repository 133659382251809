import React from "react";
import AccountLayout from "../../../../components/Layout/AccountLayout/index.jsx";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button";
import { countries } from "../../../../utils/CountriesJson";
import dialingcodes from "../../../../utils/JSON/dialing-codes.json";
import style from "./index.module.scss";
import BusinessSector from "../../../../utils/JSON/business-sector.json";
import * as authActions from "../../../../redux/actions/authActions";
import * as messageActions from "../../../../redux/actions/messageActions";
import {
  FormRow,
  FormRowGroup,
  FormGroupCustom,
} from "../../../../components/Form-row/index.jsx";
import ErrorBoundary from "../../../../components/ErrorBoundary.jsx";
import Modal from "../../../../components/Modal/index.jsx";

import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { updateUserInfo } from "../../../../redux/actions/accountActions.js";

export default function Profile() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.account);
  const profileData = useSelector((state) => state.auth.userInfo);
  const notification = useSelector((state) => state.message);
  const token = sessionStorage.getItem("msa_jwt");
  const id = JSON.parse(sessionStorage.getItem("msa_user_data")).id;
  // const [open, setOpen] = React.useState(false);

  const clientData = new FormData();
  clientData.append("client_id", id);
  clientData.append("token", token);

  const handleToggle = () => {
    dispatch(authActions.getClientInfo(clientData));
    dispatch(messageActions.clearErrorMessage());
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  React.useEffect(() => {
    dispatch(authActions.getClientInfo(clientData));

    return () => {
      dispatch(messageActions.clearErrorMessage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <AccountLayout
        title=" Your Account Profile"
        image=""
        description="Ensure your details are always up to date. This helps us provide a really
        great service and target your campaigns effectively."
      >
        <Modal isOpen={notification.id === "SUCCESS" || isAuth.isLoading}>
          {isAuth.isLoading ? (
            <MoonLoader css={override} size={80} color={"red"} loading={true} />
          ) : (
            <>
              <header>
                <h3>Profile Updated Successfully</h3>
              </header>

              <Button className="medium--filled" onClick={() => handleToggle()}>
                Okay
              </Button>
            </>
          )}
        </Modal>
        <div className={style["personaldetails__container--form"]}>
          <PersonalDetailsForm
            profileData={profileData}
            dispatch={dispatch}
            token={token}
          />
        </div>

        <hr className={style["form__seperator"]}></hr>

        <div className={style["businessinfo__container--form"]}>
          <BusinessInfoForm
            profileData={profileData}
            dispatch={dispatch}
            token={token}
          />
        </div>
      </AccountLayout>
    </ErrorBoundary>
  );
}

const PersonalDetailsForm = ({ profileData, dispatch, token }) => {
  // const {profileData} = props;

  // console.log(profileData)

  const personalDetailsSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Firstname is required.")
      .min(3, "Firstname must contain at least 3 characters."),
    lastName: Yup.string()
      .required("Lastname is required.")
      .min(3, "Lastname must contain at least 3 characters."),
    email: Yup.string()
      .required("Email is required.")
      .email("Email is invalid."),

    phone_no: Yup.string().required("Phone number is required."),
    // .matches(
    //   new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/),
    //   "Phone number is invalid"
    // ),

    software_dev: Yup.string().required("Software developer is required."),

    country: Yup.string().required("Country is required."),
  });

  const initialValues = {
    firstName: profileData?.contact_person?.split(" ")[0],
    lastName: profileData?.contact_person?.split(" ")[1],
    email: profileData?.email,
    phone_no: profileData?.phone_number?.split(" ")[1],
    dialing_code: profileData?.phone_number?.split(" ")[0] || 234,
    software_dev: profileData?.software_developer,
    country: profileData?.country,
  };

  return (
    <>
      <header>
        <h4>Personal Info</h4>
      </header>
      <Formik
        initialValues={initialValues}
        validationSchema={personalDetailsSchema}
        onSubmit={({
          firstName,
          lastName,
          email,
          phone_no,
          software_dev,
          country,
          dialing_code,
        }) => {
          const formData = new FormData();
          const fullName = `${firstName} ${lastName}`;
          const userPhoneNo = `${dialing_code} ${phone_no}`;
          formData.append("contact_person", fullName);
          // formData.append("email", email);
          formData.append("phone_number", userPhoneNo);
          formData.append("software_developer", software_dev);
          formData.append("country", country);
          formData.append("token", token);
          dispatch(updateUserInfo(formData, profileData.id, "SUCCESS"));
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <FormRow>
                <FormRowGroup>
                  <label htmlFor="firstname">First Name</label>
                  <Field
                    type="text"
                    name="firstName"
                    id="firstname"
                    placeholder="Enter First Name"
                    className={
                      errors.firstname && touched.firstname
                        ? "input--error"
                        : null
                    }
                  />
                  <ErrorMessage
                    name="firstName"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>

                <FormRowGroup>
                  <label htmlFor="lastname">Last Name</label>
                  <Field
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Enter Last Name"
                    className={
                      errors.lastname && touched.lastname
                        ? "input--error"
                        : null
                    }
                  />
                  <ErrorMessage
                    name="lastName"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>
              </FormRow>

              <FormRow>
                <FormRowGroup>
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    disabled={"true"}
                    placeholder="Enter email address"
                    className={
                      errors.email && touched.email ? "input--error" : null
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>

                <FormRowGroup>
                  <label htmlFor="phone_no">Phone Number</label>

                  <FormGroupCustom>
                    <Field
                      name="dialing_code"
                      id=""
                      defaultValue={234}
                      as="select"
                    >
                      {dialingcodes.map((code) => {
                        return (
                          <option value={code.callingCode}>
                            +{code.callingCode}
                          </option>
                        );
                      })}
                    </Field>
                    <Field type="tel" name="phone_no" />
                  </FormGroupCustom>
                  <ErrorMessage
                    name="phone_no"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>
              </FormRow>

              <FormRow>
                <FormRowGroup>
                  <label htmlFor="software_dev">
                    Are you a Software Developer ?
                  </label>
                  <Field
                    name="software_dev"
                    as="select"
                    // defaultValue={profileData?.software_developer}
                    id="software_dev"
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Field>
                  <ErrorMessage
                    name="software_dev"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>

                <FormRowGroup>
                  <label htmlFor="country">Country</label>

                  <Field
                    name="country"
                    as="select"
                    id="country"
                    defaultValue={profileData?.country.charAt(0).toUpperCase()}
                  >
                    {countries.map((country, id) => (
                      <option value={country.name}>
                        {country.emoji} {country.name}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="country"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>
              </FormRow>

              <Button type="submit" className="medium--filled">
                Save ✓
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const BusinessInfoForm = ({ profileData, dispatch, token }) => {
  const businessInfoSchema = Yup.object().shape({
    businessName: Yup.string()
      .required("Business Name is required.")
      .min(3, "Business Name must contain at least 3 characters."),
    supports_international: Yup.string().required(
      "Supports international is required."
    ),
    business_sector: Yup.string().required("Please select an option"),

    website: Yup.string().nullable(),
  });

  const initialValues = {
    businessName: profileData?.client_name,
    supports_international: profileData?.supports_international,
    website: profileData?.website,
    business_sector: profileData?.organization_type,
  };

  return (
    <div style={{ width: "100%" }}>
      <header>
        <h4>Business Info</h4>
      </header>
      <Formik
        initialValues={initialValues}
        validationSchema={businessInfoSchema}
        onSubmit={({
          businessName,
          supports_international,
          website,
          business_sector,
        }) => {
          // alert(JSON.stringify(fields));
          // AuthenticatorAttestationResponse.
          const formData = new FormData();
          formData.append("client_name", businessName);
          formData.append("supports_international", supports_international);
          // formData.append("lastName", lastname);
          formData.append("organization_type", business_sector);
          formData.append("website", website);
          formData.append("token", token);

          dispatch(updateUserInfo(formData, profileData.id, "SUCCESS"));
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <FormRow>
                <FormRowGroup>
                  <label htmlFor="firstname">Business Name</label>
                  <Field
                    type="text"
                    name="businessName"
                    id="businessname"
                    placeholder="Enter Business Name"
                  />
                  <ErrorMessage
                    name="businessName"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>

                <FormRowGroup>
                  <label htmlFor="supports_international">
                    Does this Business support international customers?
                  </label>
                  <Field
                    as="select"
                    name="supports_international"
                    id="supports_international"
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Field>

                  <ErrorMessage
                    name="supports_international"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>
              </FormRow>

              <div className={style["form__container--row"]}>
                <FormRowGroup>
                  <label htmlFor="website">Business Website</label>
                  <Field
                    type="text"
                    name="website"
                    id="website"
                    placeholder="Enter Business Website"
                    className={
                      errors.website && touched.website ? "input--error" : null
                    }
                  />
                  <ErrorMessage
                    name="website"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>
                <FormRowGroup>
                  <label htmlFor="Business Sector">Business Sector</label>
                  <Field
                    as="select"
                    name="business_sector"
                    defaultValue={profileData?.organization_type}
                  >
                    <option value="">Please select a sector</option>
                    {BusinessSector.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </Field>
                  <ErrorMessage
                    name="business_sector"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>
              </div>

              <Button
                type="submit"
                className="medium--filled"
                // disabled={!(dirty && isValid)}
              >
                Save ✓
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
