import React from "react";
import Button from "./Button";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // logErrorToMyService(error, errorInfo) {
  //   console.log(errorInfo);
  //   const formData = new FormData();

  //   formData.append("error_dump", errorInfo)
  //   axios
  //     .post("${process.env.REACT_APP_BASE_URL}/client-error", formData)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => []);
  // }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: false };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // this.logErrorToMyService(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      return (
        <div className="errorboundary">
          <h1>Something went wrong.</h1>;
          <p>It's not you but us, please hold on while we fix this</p>
          <Button
            type="button"
            className="medium--filled"
            style={{ width: "20%" }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload Page
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
