import React from "react";
import { NavLink } from "react-router-dom";
import style from "./index.module.scss";

export default function PaymentsLayout({ children }) {
  const NavLinks = [
   {
      title: "Bank Transfer",
      route: "/admin/payments/bank",
    },
    {
      title: "Card Payment",
      route: "/admin/payments/card",
    },
  ];
  return (
      
    <section>
    <div  className={style["payments--nav"]}>
        <nav>
            <ul>
            {NavLinks.map((item) => {
                return (
                <NavLink
                    exact={true}
                    activeClassName={style["active"]}
                    to={item.route}
                >
                    <li>{item.title}</li>
                </NavLink>
                );
            })}
            </ul>
        </nav>
    </div>
      
      <main className={style["payments--main"]}>{children}</main>
    </section>
  );
}
