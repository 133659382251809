import React from "react";
import { Navigate, Outlet } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import * as tokenConfig from "../../utils/tokenConfig";
import * as authActions from "../../redux/actions/authActions";

export default function Admin({ ...rest }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.isAuth);

  React.useEffect(() => {
    if (tokenConfig.getToken() && tokenConfig.getUserdata()) {
      dispatch(authActions.loadAuthUser());
      // dispatch(userActions.getUser());
    } else {
      dispatch(authActions.logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return auth ? <Outlet {...rest} /> : <Navigate to="/admin/login" />;

  // return (
  //   <Route
  //     {...rest}
  //     render={(props) => {
  //       if (auth) {
  //         return <Component {...rest} {...props} />;
  //       } else {
  //         return (
  //           <Redirect
  //             to={{
  //               pathname: "/admin/login",
  //               state: {
  //                 from: props.location,
  //               },
  //             }}
  //           />
  //         );
  //       }
  //     }}
  //   />
  // );
}
