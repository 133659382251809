import React from "react";
import Button from "../../../components/Button";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import SubscribeForm from "../../../components/SubscribeForm";
import style from "./index.module.scss";
import heroImage from "../../../assets/ominichannel-hero-image.png";
import socialImage from "../../../assets/social-images.svg";
import IVR from "../../../assets/ivr.png";
import chatbot from "../../../assets/chatbot.png";
import social from "../../../assets/social.png";
import email from "../../../assets/email.png";
import chat from "../../../assets/chat.png";
import socials from "../../../assets/socials.png";
import emails from "../../../assets/emails.png";
import Modal from "../../../components/Modal";
import ContactForm from "../../../components/ContactForm";
import { useNavigate } from "react-router-dom";

const Ominichannel = () => {
  const scrollTop = React.useRef(null);
  React.useEffect(() => {
    scrollTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div ref={scrollTop}></div>
      <Header bgStyle="ominichannel__page--bg" theme="darkTheme">
        <div className={style["header__details"]}>
          <div className={style["hero__text"]}>
            <h1>Omnichannel CRM</h1>
            <p>
              Upgrade your customer experience and let your customers
              communicate with you the way they prefer on multi channels.
            </p>
            <Button
              className="filled"
              onClick={() => navigate("/ominichannel-contact")}
            >
              Contact us
            </Button>
          </div>
          <div className={style["hero__image"]}>
            <img src={heroImage} alt="Omni-channel" />
          </div>
          <div className={style["people"]}>
            <img src={socialImage} alt="Omni-channel" />
          </div>
        </div>
      </Header>
      <div className={style["main__content"]}>
        <h1>Our Channels</h1>

        <div className={style["channel--list"]}>
          {channels.map((channel, index) => (
            <div className={style["channel--list__item"]} key={index}>
              <img
                src={channel.background}
                className={style["bg--image"]}
                alt="Omni-channel"
              />

              <div className={style["content"]}>
                {channel.image && (
                  <img
                    className={
                      index === 0
                        ? style["chatbots--image"]
                        : index === 1
                        ? style["socials--image"]
                        : style["emails--image"]
                    }
                    src={channel.image}
                    alt="Omni-channel"
                  />
                )}
                <div className={style["text--content"]}>
                  <h3 dangerouslySetInnerHTML={{ __html: channel.title }}></h3>
                  <p>{channel.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <SubscribeForm />
      <Footer />

      <Modal isOpen={open} toggleModal={() => setOpen(false)}>
        <div className={style["modal__content"]}>
          <ContactForm />
        </div>
      </Modal>
    </>
  );
};

export default Ominichannel;

const channels = [
  {
    background: chatbot,
    image: chat,
    title: "Chatbots",
    text: "Leverage the benefit of AI chatbots by connecting with leads as soon as they land on your website.",
  },
  {
    background: social,
    image: socials,
    title: "Social Media",
    text: "Increase your engagement by connecting with your customers on the most popular social platforms",
  },
  {
    background: IVR,
    image: null,
    title: "Interactive Voice <br/> Response (IVR)",
    text: "Get rid of call queues by diverting phone calls to virtual digital agents",
  },
  {
    background: email,
    image: emails,
    title: "Email",
    text: "Delivers personalized and a seamless experience for your customers with emails that creates trust and delivers value.",
  },
];
