import React from "react";
import { Route, Routes } from "react-router";
import Dashboard from "./routes/NonAdmin/Dashboard";
import ProtectedRoutesAdmin from "./components/ProtectedRoutes/Admin";
import ProtectedRoutesDev from "./components/ProtectedRoutes/NonAdmin";
import OtpAndalerts from "./routes/NonAdmin/OTPandAlerts/otpAndalerts";
import LandingPage from "./routes/NonAdmin/LandingPage";
import PasswordReset from "./routes/NonAdmin/Reset-Password";
import ForgotPassword from "./routes/NonAdmin/Forgot-Password";
import UsageReports from "./routes/NonAdmin/Usage-Reports";
import SendMessages from "./routes/NonAdmin/Send-Messages";
import Profile from "./routes/NonAdmin/Settings/Profile";
import ChangeUserPassword from "./routes/NonAdmin/Settings/ChangeUserPassword";
import API from "./routes/NonAdmin/Settings/API";
import BusinessInfo from "./components/MultiStep/BusinessInfo";
import {
  WalletAlerts,
  WalletCredit,
  WalletHistory,
} from "./routes/NonAdmin/Wallets";
import {
  CardPayments,
  BankPayments,
  AdminHome,
  AdminPayments,
} from "./routes/Admin";
import { Login, Signup } from "./routes/NonAdmin";
import UploadEvidence from "./routes/NonAdmin/Wallets/PaymentEvidence";
import OTPAuthentication from "./routes/NonAdmin/OTPAuthentication";
import BusinessMessaging from "./routes/NonAdmin/BusinessMessaging";
import DevelopMessaging from "./routes/NonAdmin/Send-Messages/NonDev";
import About from "./routes/NonAdmin/About";
import FAQ from "./routes/NonAdmin/Faq";
import CallCenter from "./routes/NonAdmin/CallCenter";
import Pricing from "./routes/NonAdmin/Pricing";
import Demo from "./routes/NonAdmin/Demo/index";
import SenderID from "./routes/NonAdmin/SenderID";
import SenderIDApproval from "./routes/Admin/SenderID";
import Phonebook from "./routes/NonAdmin/Phonebook";
import CreatePhonebook from "./routes/NonAdmin/Phonebook/Create-Phonebook";
import ViewPhonebook from "./routes/NonAdmin/Phonebook/View-Phonebook";
import Logs from "./routes/NonAdmin/Logs";
import ViewLog from "./routes/NonAdmin/Logs/View-Log";
import DemoVoiceSurvey from "./routes/NonAdmin/Demo/Voice-Survey";
import DemoBusinessMessaging from "./routes/NonAdmin/Demo/Business-Messaging";
import DemoOTPAuthentication from "./routes/NonAdmin/Demo/OTP-Authentication";
import DemoOverview from "./routes/NonAdmin/Demo/Overview";
import VoiceSurvey from "./routes/NonAdmin/Voice-Survey";
import Privacy from "./routes/NonAdmin/Privacy";
import Terms from "./routes/NonAdmin/Terms";
import ContactForm from "./components/ContactForm";
import Blog from "./routes/NonAdmin/Blog";
import ViewBlogPost from "./routes/NonAdmin/Blog/View-blog";
import Ominichannel from "./routes/NonAdmin/ominichannel";
import WhatsappTemplate from "./routes/NonAdmin/WhatsappTemplate";
import WhatsappTemplateApproval from "./routes/Admin/WhatsappTemplate";
import ReactGA from "react-ga";
function App() {
  // ReactGA.initialize("UA-216743203-1", {
  //   standardImplementation: true,
  //   debug: true,
  // });
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      {/* <RouteChangeTracker /> */}
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/admin/login" element={<Login />} />
        <Route exact path="/sign-up" element={<Signup />} />
        <Route exact path="/verify" element={<Login />} />
        <Route exact path="/reset-password" element={<PasswordReset />} />
        <Route
          exact
          path="/authflow/forgot-password"
          element={<ForgotPassword />}
        />
        <Route exact path="/update/business-info" element={<BusinessInfo />} />
        <Route exact path="/contact-us" element={<ContactForm />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog/:id/:title" element={<ViewBlogPost />} />
        <Route exact path="/omnichannel-crm" element={<Ominichannel />} />
        <Route exact path="/omnichannel-contact" element={<ContactForm />} />
        {/* Dev or regular user Routes*/}

        <Route element={<ProtectedRoutesDev />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/user/settings" element={<Profile />} />
          <Route
            exact
            path="/user/whatsapp-template"
            element={<WhatsappTemplate />}
          />
          <Route
            exact
            path="/admin/otp-and-alerts"
            element={<OtpAndalerts />}
          />
          <Route exact path="/user/otp-and-alerts" element={<OtpAndalerts />} />
          <Route exact path="/wallet" element={<WalletCredit />} />
          <Route exact path="/wallet/credit" element={<WalletCredit />} />
          <Route exact path="/wallet/history" element={<WalletHistory />} />
          <Route exact path="/wallet/Alerts" element={<WalletAlerts />} />
          <Route
            exact
            path="/wallet/paymentevidence"
            element={<UploadEvidence />}
          />
          <Route exact path="/user/usage-report" element={<UsageReports />} />
          <Route exact path="/user/sender-id" element={<SenderID />} />
          <Route exact path="/user/send-messages" element={<SendMessages />} />
          <Route
            exact
            path="/user/send-messages/developer"
            element={<DevelopMessaging />}
          />
          <Route exact path="/user/settings" element={<Profile />} />
          <Route exact path="/user/settings/Apikey" element={<API />} />
          <Route
            exact
            path="/user/settings/change-password"
            element={<ChangeUserPassword />}
          />
          <Route exact path="/user/phonebook" element={<Phonebook />} />

          <Route
            exact
            path="/user/phonebook/create"
            element={<CreatePhonebook />}
          />
          <Route
            exact
            path="/user/phonebook/edit/:id/:name/:total"
            element={<CreatePhonebook />}
          />

          <Route
            exact
            path="/user/phonebook/add/:id/:name"
            element={<CreatePhonebook />}
          />

          <Route
            exact
            path="/user/phonebooks/:id/:name"
            element={<ViewPhonebook />}
          />
          <Route exact path="/user/analytics" element={<Logs />} />
          <Route exact path="/user/analytics/:id/:name" element={<ViewLog />} />
        </Route>

        {/* Admin Routes */}
        <Route element={<ProtectedRoutesAdmin />}>
          <Route exact path="/admin/dashboard" element={<AdminHome />} />
          <Route exact path="/admin/payments" element={<AdminPayments />} />
          {/* <Route exact path="/admin/otp-and-alerts" element={<OtpAndalerts/>} /> */}
          <Route exact path="/admin/payments/bank" element={<BankPayments />} />
          <Route exact path="/admin/payments/card" element={<CardPayments />} />
          <Route
            exact
            path="/admin/sender-id/approval"
            element={<SenderIDApproval />}
          />
          <Route
            exact
            path="/admin/whatsapp-template/approval"
            element={<WhatsappTemplateApproval />}
          />
          {/* <Route exact path="/admin/usage-report" element={<UsageReports/>} /> */}
          {/* <Route exact path="/admin/send-messages" element={<SendMessages/>} /> */}
        </Route>

        <Route exact path="/" element={<LandingPage />} />
        <Route
          exact
          path="/otpauthentication"
          element={<OTPAuthentication />}
        />
        <Route
          exact
          path="/business-messaging"
          element={<BusinessMessaging />}
        />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/call-center" element={<CallCenter />} />
        <Route exact path="/voice-survey" element={<VoiceSurvey />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/terms-of-service" element={<Terms />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/demo" element={<Demo />} />
        <Route exact path="/demo/voice-survey" element={<DemoVoiceSurvey />} />
        <Route
          exact
          path="/demo/business-messaging"
          element={<DemoBusinessMessaging />}
        />
        <Route
          exact
          path="/demo/otp-authentication"
          element={<DemoOTPAuthentication />}
        />
        <Route exact path="/demo/overview" element={<DemoOverview />} />
        <Route
          path="*"
          element={() => <h1 style={{ textAlign: "center" }}>Not found</h1>}
        />
      </Routes>
    </>
  );
}

export default App;
