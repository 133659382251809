/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../../../components/Layout/DashboardLayout";
import WalletLayout from "../../../../components/Layout/WalletLayout";
import style from "./index.module.scss";
import { Pagination } from "../../../../components/Pagination";
import { FormRow } from "../../../../components/Form-row/index.jsx";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import usePagination from "../../../../Customhooks/usePaginationCore";
import {
  getHistoryByDate,
  getPurchaseHistory,
} from "../../../../redux/actions/paymentActions";
import { getClientInfo } from "../../../../redux/actions/authActions";
import Button from "../../../../components/Button";

export default function WalletHistory() {
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const purchase_history = useSelector(
    (state) => state.payments?.purchase_history
  );
  React.useEffect(() => {
    if (!userInfo) {
      const token = sessionStorage.getItem("msa_jwt");
      const id = JSON.parse(sessionStorage.getItem("msa_user_data")).id;

      const clientInfo = new FormData();
      clientInfo.append("client_id", id);
      clientInfo.append("token", token);

      dispatch(getClientInfo(clientInfo));
    }
  }, [userInfo]);

  return (
    <Layout
      pageTitle={
        location.pathname === "/user/usage-report" ? "Usage Reports" : "Wallet"
      }
    >
      <WalletLayout>
        <div>
          <Cards userInfo={userInfo} />
          <HistoryTable
            purchaseHistory={purchase_history}
            dispatch={dispatch}
            userInfo={userInfo}
          />
        </div>
      </WalletLayout>
    </Layout>
  );
}

const Cards = ({ userInfo }) => {
  const cards = [
    {
      description: "Total amount funded",
      amt: `${userInfo ? userInfo.totalFunded : "Loading..."}`,
      // icon: (
      //   <svg
      //     width="12"
      //     height="12"
      //     viewBox="0 0 12 12"
      //     fill="none"
      //     xmlns="http://www.w3.org/2000/svg"
      //   >
      //     <path
      //       d="M11.5484 4.84418H10.4805V0.72683H8.79616V4.84418H5.80722L2.9559 0.72683H1.38162V4.84418H0.31926V5.94507H1.38162V7.22211H0.31926V8.32301H1.38162V12H3.08251V8.32301H6.36868L8.91725 12H10.4805V8.32301H11.5484V7.22211H10.4805V5.94507H11.5484V4.84418ZM4.72284 5.94507L5.60356 7.22211H3.08251V5.94507H4.72284ZM8.79616 5.94507V7.22211H7.45857L6.57235 5.94507H8.79616ZM3.08251 4.84418V3.73227H3.18709L3.95772 4.84418H3.08251ZM8.21819 8.32301H8.79616V9.00556H8.69157L8.21819 8.32301Z"
      //       fill="black"
      //       fill-opacity="0.4"
      //     />
      //   </svg>
      // ),
    },
    {
      description: "Total units purchased",
      amt: `${userInfo ? userInfo.totalUnitsPurchased : "Loading..."}`,
    },
    {
      description: "Total units spent",
      amt: `${
        userInfo ? Number(userInfo.totalUnitsSpent).toFixed(2) : "Loading..."
      }`,
    },
  ];

  return (
    <div>
      <ul className={style["cards--container"]}>
        {cards.map((item) => (
          <li className={style["cards--item"]}>
            <span>{item.icon}</span>
            <header>
              <h2>{item.amt}</h2>
              <p>{item.description}</p>
            </header>
          </li>
        ))}
      </ul>
    </div>
  );
};

const HistoryTable = ({ dispatch, userInfo }) => {
  const purchasHistory = useSelector(
    ({ payments }) => payments?.purchase_history
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const [filter, setFilter] = React.useState(false);
  const totalPages = Math.ceil(purchasHistory?.total / 15); // Returns 15 per page

  dayjs.extend(localizedFormat);

  const isLoading = useSelector(({ payments }) => payments?.isLoading);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // currentPage = 1;

  const dateFilter = new FormData();

  dateFilter.append("date_from", dayjs(startDate).format("YYYY-MM-DD"));
  dateFilter.append("date_to", dayjs(endDate).format("YYYY-MM-DD"));
  dateFilter.append("client_id", userInfo?.id);
  dateFilter.append("token", sessionStorage.getItem("msa_jwt"));
  dateFilter.append("page", currentPage);

  const th = [
    {
      name: "Date",
    },
    {
      name: "Transaction ID",
    },
    {
      name: "Units bought",
    },
    {
      name: "Amount",
    },
    {
      name: "Purchase Source",
    },
    {
      name: "Description",
    },
  ];

  const getDataByDate = () => {
    dispatch(getHistoryByDate(dateFilter));
    setFilter(true);
  };

  function nextPage(pageNumber) {
    setCurrentPage(pageNumber);

    if (filter) {
      dateFilter.append("page", pageNumber);
      dispatch(getHistoryByDate(dateFilter, pageNumber));
    } else {
      dateFilter.append("page", pageNumber);
      dispatch(getPurchaseHistory(dateFilter, pageNumber));
    }
  }

  React.useEffect(() => {
    dispatch(getPurchaseHistory(dateFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style["HistoryTable--container"]}>
      <header>
        <h3>Purchase History</h3>
        <div style={{ width: "100%" }}>
          <div className={style["filter__inputs"]}>
            <FormRow>
              <span>Filter from</span>

              <span>
                <DatePicker
                  selected={startDate} //when day is clicked
                  onChange={(date) => setStartDate(date)}
                  maxDate={new Date()}
                  // inline
                  showMonthDropdown
                  showYearDropdown // dateFormat="DD/MM/YYYY"
                />
              </span>

              <span>to</span>

              <span>
                <DatePicker
                  selected={endDate} //when day is clicked
                  onChange={(date) => setEndDate(date)}
                  maxDate={new Date()}
                  showMonthDropdown
                  showYearDropdown
                />
              </span>

              <Button
                style={{ cursor: "pointer", width: "15%" }}
                onClick={() => getDataByDate()}
              >
                Go
              </Button>
            </FormRow>
            {/* <span>Filter</span> */}
          </div>
        </div>
      </header>
      <table className={style["HistoryTable--table"]}>
        {" "}
        {/* <thead> */}
        <tr>
          {th.map((item) => {
            return <th>{item.name}</th>;
          })}

          {/* <th>Days Paid</th> */}
        </tr>
        {/* </thead> */}
        {isLoading ? (
          <tbody>
            <tr className={style["table-row"]} colspan="6">
              <td> Loading...</td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {purchasHistory?.data?.map((item, id) => (
              <tr className={style["table-row"]}>
                <td>{dayjs(item.created_at).format("L")}</td>
                <td>{item.transaction_id}</td>
                <td>
                  {item.purchase_source === "paystack" ||
                  item.purchase_source === "bank transfer"
                    ? `${item.currency} ${item.airtime_units}`
                    : item.airtime_units}
                </td>
                <td>{item.amount_paid}</td>
                <td>{item.purchase_source}</td>
                <td>{item.description}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <Pagination
        numberOfPages={totalPages}
        currentPage={currentPage}
        nextPage={nextPage}
      />
    </div>
  );
};
