import React, { useState } from "react";
import style from "./index.module.scss";
import Header from "../../../../components/Header";
import Button from "../../../../components/Button";
import Footer from "../../../../components/Footer";
import axios from "../../../../utils/axios-base";
import vma from "../../../../assets/voice-message-app.svg";
import Dialog from "../../../../components/Dialog";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";

export default function DemoVoiceSurvey() {
  const [responses, setResponses] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [process, setProcess] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  async function handleVoiceSurvey() {
    setIsSending(true);
    setProcess("");

    if (!phoneNumber) {
      setProcess("Phone number is required");
    } else {
      setProcess("Initiating voice survey call...");

      try {
        let res = await axios.get(
          `https://aella-call.myserviceagent.net/receive/?session_msisdn=${phoneNumber}`
        );
        if (res.status === 200) {
          setIsSending(false);
          setSuccess(true);
          setProcess("Voice survey call has been sent");
        } else {
          setIsSending(false);
          setProcess("Error sending voice survey call");
        }
      } catch (err) {
        setIsSending(false);
        setProcess("Error sending voice survey call");
      }
    }
  }

  const handleResponse = async () => {
    setIsSending(true);
    setProcess("");

    if (!phoneNumber) {
      setProcess("Phone number is required");
    }

    try {
      let res = await axios.get(
        `https://aella-call.myserviceagent.net/response/?session_msisdn=${phoneNumber}`
      );
      if (res.status === 200) {
        setIsSending(false);
        setResponses(res.data.data);
      } else {
        setIsSending(false);
        setResponses("Error sending voice survey call");
      }
    } catch (err) {
      setIsSending(false);
      setResponses("Error sending voice survey call");
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setResponses(null);
    setProcess("");
    setSuccess(false);
    setPhoneNumber("");
  };
  const handleNewsletter = async () => {
    if (!email) {
      setProcess("Email address is required");
    } else {
      setProcess("Subscribing to newsletter...");
      setIsLoading(true);
      const payload = {
        u: "44d4a61233b00e03bd3168a90",
        id: "18c8aff5b4",
        MERGE0: email,
      };

      await axios
        .post(
          `https://myserviceagent.us20.list-manage.com/subscribe/post?u=${payload.u}&id=${payload.id}&MERGE0=${email}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setProcess("");
            setIsLoading(false);
            setIsOpen(true);
          } else {
            setIsOpen(true);
          }
        })
        .catch((error) => {
          setProcess("");
          setIsOpen(true);
          setIsLoading(false);
          console.error("Error:", error);
        });
    }
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  const mouseEnter = () => {
    let vid = document.getElementById("video");
    vid.onmouseenter = function () {
      vid.setAttribute("controls", "controls");
    };
  };
  return (
    <>
      <Header theme="darkTheme">
        <div className={style["header__details"]}>
          <div className={style["header"]}>
            <div className={style["column"]}>
              <h3>
                Demo our <span>Voice Survey </span>
                solution
              </h3>
              <p>
                Collect unique customer feedback and opinions on products and
                services via voice-based surveys.
              </p>
              <p>
                Please input your email address in the column provided to
                proceed
              </p>
              <div className={style["form-section"]}>
                <label style={{ color: "#1D293F" }} htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Button
                  type="submit"
                  className="small--filled"
                  onClick={handleNewsletter}
                >
                  {!isLoading ? (
                    "Start Demo"
                  ) : (
                    <ScaleLoader
                      css={override}
                      size={80}
                      color={"#fff"}
                      loading={true}
                    />
                  )}
                </Button>
              </div>
            </div>
            <div className={style["column"]}>
              <video
                id="video"
                width="500%"
                onMouseEnter={mouseEnter}
                // className={style["column"]}
                autoPlay={true}
                muted={true}
                controlsList="nodownload"
              >
                <source src="https://res.cloudinary.com/myserviceagent/video/upload/v1643800265/Survey_xpgkyw.mp4" />
              </video>
            </div>
          </div>
        </div>
      </Header>

      <Dialog isOpen={isOpen}>
        <div className={style["dialg--container"]}>
          <div className={style["close-dialog"]} onClick={handleClose}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.6635 2.13128L22.0056 0.493117C21.557 0.0445192 20.8159 0.0445192 20.3478 0.493117L12.078 8.76273L3.65232 0.337083C3.20353 -0.11171 2.46236 -0.11171 1.99446 0.337083L0.336595 1.99495C-0.112198 2.44354 -0.112198 3.18471 0.336595 3.65281L8.74293 12.0591L0.492629 20.3485C0.0440309 20.7971 0.0440309 21.5382 0.492629 22.0063L2.15049 23.6642C2.59909 24.1128 3.34025 24.1128 3.80835 23.6642L12.078 15.3944L20.3478 23.6642C20.7964 24.1128 21.5375 24.1128 22.0056 23.6642L23.6635 22.0063C24.1121 21.5577 24.1121 20.8166 23.6635 20.3485L15.3742 12.0787L23.644 3.80904C24.1121 3.34055 24.1121 2.59938 23.6635 2.13128Z"
                fill="#323232"
              />
            </svg>
          </div>

          <div style={{ marginTop: "42px" }}>
            <img src={vma} alt="faq" height="80px" width="80px" />
            {!responses && (
              <>
                <div className={style["response"]}>
                  <h3>Test Voice Survey</h3>
                  <p>
                    Please input your phone number in the column provided. You
                    will receive a call on your phone in a few seconds, follow
                    the voice prompts to see how voice survey works.
                  </p>
                </div>

                <div className={style["section__form__three"]}>
                  <form>
                    <div className={style["form-head"]}>
                      <label style={{ color: "#1D293F" }} htmlFor="phoneNumber">
                        Phone Number
                      </label>

                      <span>Step {success ? "2" : "1"} /2</span>
                    </div>
                    <input
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      type="text"
                      placeholder="Enter phone number"
                      id="phoneNumber"
                    />
                    <p>{process}</p>
                  </form>
                  <div className={style["form__btn__group"]}>
                    {success ? (
                      <div
                        onClick={handleResponse}
                        className={style["component__right"]}
                      >
                        <Button className="small--filled">
                          {isSending ? (
                            <ScaleLoader
                              css={override}
                              size={80}
                              color={"#fff"}
                              loading={true}
                            />
                          ) : (
                            "View my Response"
                          )}
                        </Button>
                      </div>
                    ) : (
                      <div
                        onClick={handleVoiceSurvey}
                        className={style["component__right"]}
                      >
                        <Button className="small--filled">
                          {isSending ? (
                            <ScaleLoader
                              css={override}
                              size={80}
                              color={"#fff"}
                              loading={true}
                            />
                          ) : (
                            " Test Survey Call"
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {responses && (
              <div className={style["response"]}>
                <h3>Voice Survey Response(s)</h3>

                {responses.split(".").map((response, index) => {
                  return <p key={index}>{response}</p>;
                })}
              </div>
            )}
          </div>
        </div>
      </Dialog>
      {/* <ContactForm /> */}
      <Footer />
    </>
  );
}
