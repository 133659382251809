import React from "react";
import style from "./index.module.scss";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import SubscribeForm from "../../../components/SubscribeForm";
import open from "../../../assets/images/open.svg";
import close from "../../../assets/images/close.svg";
import CheckBox from "../../../assets/images/check.png";

const OTPAuthentication = () => {
  const pageTop = React.useRef(null);

  const [showAnswer, setAnswer] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  // function scrollDown(){
  //     faqsTop.current.scrollIntoView({behavior: "smooth"});
  // }

  function handleToggle(index) {
    const ansArr = [false, false, false, false, false];
    if (showAnswer[index]) {
      setAnswer(ansArr);
    } else {
      ansArr[index] = true;
      setAnswer(ansArr);
    }
  }

  React.useEffect(() => {
    pageTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const lists = [
    "No Internet connection limitations",
    " Delivers your OTP within few seconds",
    "Verify Users in real time",
    "Efficient for users with sight disabilities",
    "Leave no trails for hackers to copy",
    "Deliver OTP on both DND and Non-DND numbers",
    "Send 99.9% reliable and secure OTP to your customers globally",
    "Easy to integrate",
  ];

  return (
    <>
      <div ref={pageTop}></div>
      <Header bgStyle="otp__page--bg" justify="flex-start" theme="lightTheme">
        <div className={style["header__details"]}>
          <div>
            <h3>OTP Authentication</h3>
            <p className={style["third__header__paragraph"]}>
              Deliver timely verifications and authentication alerts to your
              customers using our voice and SMS services.
            </p>

            <Link to="/sign-up" style={{ display: "flex" }}>
              <Button className="medium--filled">Get Started</Button>
            </Link>
          </div>
        </div>
      </Header>

      <section className={style["section__otp--one"]}>
        <div className={style["container"]}>
          <div className={style["container--left"]}>
            <h1 className={style["intro__text--header"]}>
              Why use Voice OTP Authentication?
            </h1>
          </div>

          <div className={style["container--right"]}>
            <ul>
              {lists.map((item, index) => (
                <li key={index}>
                  <span>
                    <img src={CheckBox} alt={CheckBox} />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className={style["section__otp--two"]}>
        <h3>Our OTP verification protects against</h3>

        <div className={style["container"]}>
          {otpProtects.map((item, index) => (
            <div>
              <div className={style["number"]}>{item.number}</div>
              <h3 className={style["header"]}>{item.header}</h3>
            </div>
          ))}
        </div>
      </section>

      <section className={style["section__otp--three"]}>
        <div className={style["accordion"]}>
          <h3 className={style["accordion__title"]}>
            Answers to all your questions
          </h3>
          {faqs.map((faq, index) => (
            <div
              className={style["accordion__list"]}
              key={index}
              onClick={() => handleToggle(index)}
            >
              <div className={style["header"]}>
                <h3>{faq.question}</h3>
                <img src={showAnswer[index] ? close : open} alt="open" />
              </div>
              {showAnswer[index] && <p>{faq.answer}</p>}
            </div>
          ))}
        </div>
        <div className={style["circle"]}></div>
      </section>

      <SubscribeForm />
      <Footer />
    </>
  );
};

export default OTPAuthentication;
const faqs = [
  {
    question:
      "Would the customer get an OTP even if they don't have internet access?",
    answer:
      "Yes they will. Our OTP delivers with or without access to internet on the receivers end.",
  },
  {
    question: "Would the customer get an OTP even if their phone is on DND?",
    answer: "Yes they will. DND does not block the receipt of OTP ",
  },
  {
    question: "Can we customize the number used to send the OTP? ",
    answer: "Yes all numbers that send SMS OTP are customised.",
  },
  {
    question: "Can I send Voice OTP to customers in other African countries?",
    answer:
      "Not at the moment. We will expand to other African countries in the nearest future",
  },
];

const otpProtects = [
  { number: 1, header: "Account Takeover" },
  {
    number: 2,
    header: "Unauthorized Account Access",
  },
  {
    number: 3,
    header: "Fraudulent Transactions",
  },
];
