export const faqsAll = [
  {
    question: "What happens when the AI doesn't have an answer to an enquiry?",
    answer:
      "Our intelligent AI can handle this in many ways including scheduling a call back to provide a response or forwarding the call to a call rep agent.",
  },
  {
    question: "Are outbound calls possible?",
    answer: "Yes. Both inbound and outbound calls can be made.",
  },
  {
    question: "Is it pre-recorded? ",
    answer:
      "Yes. The flow for our virtual call centre is decided by all parties involved. We love to work closely with you, to give you exactly what you need ",
  },
  {
    question: "Can the API be integrated into our system?",
    answer:
      "Yes. We believe you deserve a smooth service so you can integrate using our API.",
  },
  {
    question: "What if the customer speaks a different language?",
    answer:
      "Our Voice AI is programmed in an authentic Nigerian Voice and can also speak any of the Major Nigerian Languages; Pidgin, Yoruba, Igbo and Hausa.",
  },
  {
    question: "Can we get an analysis of the customer's response?",
    answer:
      "Yes. Analysis is a big part of what we do. You can get a breakdown of the conversation, and an NPS and CSAT analysis of your customer's response",
  },
  {
    question: "What happens when the customer doesn't answer the call?",
    answer:
      "Our AI can automatically reschedule a call back or initiate an sms based on sender's request",
  },
  {
    question: "Can I schedule a call with the customer? ",
    answer:
      "All calls on our platform can be scheduled to your preferred time by the sender and the receiver",
  },
  {
    question: "Will the survey be sent out in a human voice? ",
    answer:
      "Our Voice AI is programmed in an authentic Nigerian Voice and can also speak any of the Major Nigerian Languages; Pidgin, Yoruba, Igbo and Hausa.",
  },
  {
    question: "Will I get a sentiment analysis? ",
    answer:
      "Yes. Our Survey analysis comes with sentiment analysis based on request",
  },
  {
    question:
      "Would the customer get an OTP even if they don't have internet access?",
    answer:
      "Yes they will. Our OTP delivers with or without access to internet on the receivers end.",
  },

  {
    question: "Would the customer get an OTP even if their phone is on DND?",
    answer: "Yes they will. DND does not block the receipt of OTP ",
  },

  {
    question: "Can we customize the number used to send the OTP? ",
    answer: "Yes all numbers that send SMS OTP are customised.",
  },
  {
    question: "Can I send Voice OTP to customers in other African countries?",
    answer:
      "Not at the moment. We will expand to other African countries in the nearest future",
  },
  {
    question: " Is it interactive?",
    answer:
      "Yes. Our business messaging can be one way or interactive based on your needs.",
  },
  {
    question: "How can we tell if the message got to the customer or not?",
    answer:
      "The dashboard contains all information regarding who received the message, who didn't and if there was a response.",
  },
  {
    question: "Will all messages that were sent out be delivered?",
    answer:
      "In most cases yes but this is highly dependent on a lot of factors including if the receiver's phone has been switched off",
  },
  {
    question: "Can I schedule the messages ahead of time?",
    answer:
      "Yes. All messages on our platform can be scheduled to your preferred time by the sender and the receiver",
  },

  {
    question:
      "What happens when the message doesn't get delivered? Will there be a call back option?",
    answer:
      "Our AI can automatically reschedule a call back based on sender's request",
  },
  {
    question: "Can I record the message in my voice?",
    answer:
      "Yes. You can either send and SMS or send the message in your voice which can be recorded directly on the platform.",
  },
];

export const faqsBusinessMessaging = [
  {
    question: "  Is it interactive?",
    answer:
      "Yes. Our business messaging can be one way or interactive based on your needs.",
  },
  {
    question: " How can we tell if the message got to the customer or not?",
    answer:
      "The dashboard contains all information regarding who received the message, who didn't and if there was a response.",
  },
  {
    question: "Will all messages that were sent out be delivered?",
    answer:
      "In most cases yes but this is highly dependent on a lot of factors including if the receiver's phone has been switched off",
  },
  {
    question: " Can I schedule the messages ahead of time?",
    answer:
      "Yes. All messages on our platform can be scheduled to your preferred time by the sender and the receiver",
  },
  {
    question:
      "What happens when the message doesn't get delivered? Will there be a call back option?",
    answer:
      "Our AI can automatically reschedule a call back based on sender's request",
  },
  {
    question: "Can I record the message in my voice?",
    answer:
      "Yes, you can easily record a message in your voice and preview it on our platform before sending it out to your audience.",
  },
];

export const faqsVirtualCall = [
  {
    question: "What happens when the AI doesn't have an answer to an enquiry?",
    answer:
      "Our intelligent AI can handle this in many ways including scheduling a call back to provide a response or forwarding the call to a call rep agent.",
  },
  {
    question: "Are outbound calls possible?",
    answer: "Yes. Both inbound and outbound calls can be made",
  },
  {
    question: "Is it pre-recorded? ",
    answer:
      "Yes. The flow for our virtual call centre is decided by all parties involved. We love to work closely with you, to give you exactly what you need ",
  },
  {
    question: "Can the API be integrated into our system?",
    answer:
      "Yes. We believe you deserve a smooth service so you can integrate using our API.",
  },
  {
    question: "What if the customer speaks a different language?",
    answer:
      "Our Voice AI is programmed in an authentic Nigerian Voice and can also speak any of the Major Nigerian Languages; Pidgin, Yoruba, Igbo and Hausa.",
  },
];

export const faqsOTP = [
  {
    question:
      "Would the customer get an OTP even if they don't have internet access?",
    answer:
      "Yes they will. Our OTP delivers with or without access to internet on the receivers end.",
  },
  {
    question: "Would the customer get an OTP even if their phone is on DND?",
    answer: "Yes they will. DND does not block the receipt of OTP ",
  },
  {
    question: "Can we customize the number used to send the OTP? ",
    answer: "Yes all numbers that send SMS OTP are customised.",
  },
  {
    question: "Can I send Voice OTP to customers in other African countries?",
    answer:
      "Not at the moment. We will expand to other African countries in the nearest future",
  },
];

export const faqsVoiceSurvey = [
  {
    question: "Can we get an analysis of the customer's response?",
    answer:
      "Yes. Analysis is a big part of what we do. You can get a breakdown of the conversation, and an NPS and CSAT analysis of your customer's response",
  },
  {
    question: "What happens when the customer doesn't answer the call?",
    answer:
      "Our AI can automatically reschedule a call back or initiate an sms based on sender's request",
  },
  {
    question: "Can I schedule a call with the customer? ",
    answer:
      "All calls on our platform can be scheduled to your preferred time by the sender and the receiver",
  },
  {
    question: "Will the survey be sent out in a human voice? ",
    answer:
      "Our Voice AI is programmed in an authentic Nigerian Voice and can also speak any of the Major Nigerian Languages; Pidgin, Yoruba, Igbo and Hausa.",
  },
  {
    question: "Will I get a sentiment analysis? ",
    answer:
      "Yes. Our Survey analysis comes with sentiment analysis based on request",
  },
];
