import axios from "../../utils/axios-base";
import * as types from "./actionTypes";
import * as endpoints from "../../utils/endpoints";
import * as tokenConfig from "../../utils/tokenConfig";
import { returnErrors, returnSuccess } from "./messageActions";

const loadAuthUserStart = () => ({
  type: types.LOAD_AUTH_USER_START,
});

const loadAuthUserSuccess = () => ({
  type: types.LOAD_AUTH_USER_SUCCESS,
});

export const loadAuthUser = () => (dispatch, getState) => {
  dispatch(loadAuthUserStart());
  const token = getState().auth.token;
  if (token) {
    dispatch(loadAuthUserSuccess());
  } else {
    // console.log("inside loadauth else statement")
    dispatch(loginFailed());
  }
};

const loginStart = () => ({
  type: types.LOGIN_START,
});

const loginSuccess = (token, user) => ({
  type: types.LOGIN_SUCCESS,
  token,
  user,
});

const loginFailed = () => ({
  type: types.LOGIN_FAILED,
});

const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});

export const login = (formData, link) => (dispatch) => {
  dispatch(loginStart());
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // 'X-Requested-With': 'XMLHttpRequest',
    },
  };

  const endPoint =
    link === "/login" ? endpoints.AUTH_URL : endpoints.ADMIN_AUTH_URL;

  axios
    .post(`${endPoint}/login`, formData, config)
    .then((res) => {
      let user = res.data.data;
      const { data } = res.data;
      const { auth } = res.data;
      const verified = data.email_verified;
      const token = data.token || auth.access_token;

      /* we are checking if the url is admin and then the base url is switched.
            /login stands for user route and /admin/login stands for the admin route
             */

      if (link !== "/login") {
        tokenConfig.finishAuthentication(res.data.auth.access_token, user);
        dispatch(loginSuccess(token, user, "ADMIN"));
      }

      if (link === "/login") {
        if (verified === 1) {
          tokenConfig.finishAuthentication(token, user);
          dispatch(loginSuccess(token, user));
        } else {
          dispatch(loginFailed());
          dispatch(returnErrors({ error: "Please Verify your Email Address" }));
        }
      }
    })
    .catch((err) => {
      console.log(err);

      dispatch(loginFailed());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

export const logout = () => (dispatch) => {
  dispatch(logoutSuccess());
};

const addUserStart = () => ({
  type: types.ADD_USER_START,
});

const addUserFail = () => ({
  type: types.ADD_USER_FAILED,
});

const addUserSuccess = (data) => ({
  type: types.ADD_USER_SUCCESS,
  data,
});

export const addUser = (formData) => (dispatch) => {
  dispatch(addUserStart());
  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  };
  const endpoint = endpoints.AUTH_URL;
  axios
    .post(`${endpoint}/register`, formData, config)
    .then((res) => {
      // console.log(res.data)
      localStorage.setItem("key", JSON.stringify(res.data.key));
      dispatch(returnSuccess(res.data.message));
      dispatch(addUserSuccess(res.data));
    })
    .catch((err) => {
      dispatch(addUserFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

const verifyUserStart = () => ({
  type: types.VERIFY_USER_START,
});

const verifyUserFail = () => ({
  type: types.VERIFY_USER_FAILED,
});

const verifyUserSuccess = (data) => ({
  type: types.VERIFY_USER_SUCCESS,
  data,
});

export const verifyUser = (code, email) => (dispatch) => {
  dispatch(verifyUserStart());
  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  };
  const endpoint = endpoints.AUTH_URL;
  axios
    .get(`${endpoint}/verify/${code}/${email}`, config)
    .then((res) => {
      // console.log(res.data)
      const token = res.data.token;
      let user = res.data;

      tokenConfig.finishAuthentication(token, user);
      dispatch(returnSuccess(res.data.message));
      dispatch(verifyUserSuccess(res.data));
    })
    .catch((err) => {
      dispatch(verifyUserFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

const forgotPasswordStart = () => ({
  type: types.PASSWORD_RESET_START,
});

const forgotPasswordFail = () => ({
  type: types.PASSWORD_RESET_FAIL,
});

const forgotPasswordSuccess = () => ({
  type: types.PASSWORD_RESET_SUCCESS,
});
export const forgotPassword = (data) => (dispatch) => {
  dispatch(forgotPasswordStart());

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const endpoint = endpoints.AUTH_URL;
  axios
    .post(`${endpoint}/password/reset/request`, data, config)
    .then((res) => {
      dispatch(forgotPasswordSuccess());
      dispatch(returnSuccess(res.data.message));
    })
    .catch((err) => {
      console.log(err);
      dispatch(forgotPasswordFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

const passwordResetStart = () => ({
  type: types.PASSWORD_RECOVERY_START,
});

const passwordResetFail = () => ({
  type: types.PASSWORD_RECOVERY_FAIL,
});

const passwordResetSuccess = (data) => ({
  type: types.PASSWORD_RECOVERY_SUCCESS,
  data,
});

export const passwordReset = (data) => (dispatch) => {
  dispatch(passwordResetStart());

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const endpoint = endpoints.PASSWORD_RESET;
  axios
    .post(`${endpoint}/reset`, data, config)
    .then((res) => {
      let user = res.data.data;
      const { data, token } = res.data;
      const verified = data.email_verified;

      if (verified === 1) {
        tokenConfig.finishAuthentication(token, user);
        dispatch(loginSuccess(token, user));
        dispatch(passwordResetSuccess(res.data));
        dispatch(returnSuccess(res.data.message));
      }

      // console.log(res)
    })
    .catch((err) => {
      console.log(err);
      dispatch(passwordResetFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

const passwordUpdateStart = () => ({
  type: types.PASSWORD_RECOVERY_START,
});

const passwordUpdateFail = () => ({
  type: types.PASSWORD_RECOVERY_FAIL,
});

const passwordUpdateSuccess = (data) => ({
  type: types.PASSWORD_RECOVERY_SUCCESS,
  data,
});

export const passwordUpdate = (data, notificationId) => (dispatch) => {
  dispatch(passwordUpdateStart());

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const endpoint = endpoints.PASSWORD_UPDATE;
  axios
    .post(`${endpoint}/update`, data, config)
    .then((res) => {
      dispatch(passwordUpdateSuccess(res.data));
      dispatch(returnSuccess(res.data.message, "Ok", notificationId));
      // console.log(res)
    })
    .catch((err) => {
      console.log(err);
      dispatch(passwordUpdateFail());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

const updateUserStart = () => ({
  type: types.UPDATE_USER_START,
});

const updateUserSuccess = (data) => ({
  type: types.UPDATE_USER_SUCCESFUL,
  data,
});

const updateUserFailed = () => ({
  type: types.UPDATE_USER_FAIL,
});

export const updateUser = (formData, id, notificationId) => (dispatch) => {
  dispatch(updateUserStart());
  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  };
  const endpoint = endpoints.AUTH_URL;
  axios
    .post(`${endpoint}/update/${id}`, formData, config)
    .then((res) => {
      let user = res.data;

      // const expires_at = res.data.expires_at

      tokenConfig.updateUser(user);

      dispatch(updateUserSuccess(res.data));
      dispatch(returnSuccess(res.data.message, "Ok", notificationId));
    })
    .catch((err) => {
      dispatch(updateUserFailed());
      dispatch(
        returnErrors(err.response?.data, err.response?.status, notificationId)
      );
    });
};

const updateBusinessInfoStart = () => ({
  type: types.UPDATE_BUSINESS_INFO_START,
});

const updateBusinessInfoSuccess = (data) => ({
  type: types.UPDATE_BUSINESS_INFO_SUCCESS,
  data,
});

const updateBusinessInfoFailed = () => ({
  type: types.UPDATE_BUSINESS_INFO_FAIL,
});

export const updateBusinessInfo = (formData, id) => (dispatch) => {
  dispatch(updateBusinessInfoStart());
  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  };
  const endpoint = endpoints.AUTH_URL;
  axios
    .post(`${endpoint}/update/${id}`, formData, config)
    .then((res) => {
      let user = {
        ...res.data.data,
        status: res.data.status,
        message: res.data.message,
      };
      let token = res.config.data.get("token");

      //Add saved key to user data
      user.key = JSON.parse(localStorage.getItem("key"));
      localStorage.removeItem("key");

      window.sessionStorage.setItem("msa_jwt", token);

      // const expires_at = res.data.expires_at

      tokenConfig.updateUser(res.data.data);

      const id = "BUSINESS_INFO";

      dispatch(updateBusinessInfoSuccess(res.data));
      dispatch(returnSuccess(res.data.message, "ok", id));
    })
    .catch((err) => {
      dispatch(updateBusinessInfoFailed());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

const getClientInfoStart = () => ({
  type: types.GET_CLIENT_INFO_START,
});

const getClientInfoSuccess = (data) => ({
  type: types.GET_CLIENT_INFO_SUCCESS,
  data,
});

const getClientInfoFailed = () => ({
  type: types.GET_CLIENT_INFO_FAIL,
});

export const getClientInfo = (formData, notificationId) => (dispatch) => {
  dispatch(getClientInfoStart());
  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  };
  const endpoint = endpoints.AUTH_URL;
  axios
    .post(`${endpoint}/info`, formData, config)
    .then((res) => {
      // let token = res.data.token
      // const expires_at = res.data.expires_at

      dispatch(getClientInfoSuccess(res.data));
      dispatch(returnSuccess(res.data.message, "ok", notificationId));
    })
    .catch((err) => {
      dispatch(getClientInfoFailed());
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};
