import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import store from "./redux";
import { Provider } from "react-redux";
import "./index.scss";
import App from "./App";
import "normalize.css";
import "./styles/general.scss";
import "./styles/base.scss";
// import ErrorBoundary from './components/ErrorBoundary';
// import * as serviceWorker from './serviceWorker';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <ErrorBoundary> */}
      <App />

      {/* </ErrorBoundary> */}
    </BrowserRouter>
  </Provider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// reportWebVitals();
