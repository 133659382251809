const numCaseRegex = new RegExp("^(?=.*[0-9])");
const lowCaseRegex = new RegExp("^(?=.*[a-z])");
const upperCaseRegex = new RegExp("^(?=.*[A-Z])");
const specialCaseRegex = new RegExp("^(?=.*[!@#$%^&*])");

// var expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
// var urlRegex = new RegExp(expression);

export const passwordValidation = (values) => {


    if (values.trim() === "") {
        return "Required"
    } else if (!numCaseRegex.test(values)) {
        return "Password should contain atleast one number"
    }
    else if (!upperCaseRegex.test(values)) {
        return "Password should contain an Uppercase letter"
    }
    else if (!lowCaseRegex.test(values)) {
        return "Password should contain an Lowcase letter"
    }
    else if (!specialCaseRegex.test(values)) {
        return "Password should contain a special character"
    }

    return "";


};


export const confirmPasswordValidation = (confirmPassword, password) => {
    // let errors;

    // if (confirmPassword.trim() === "") {
    //     return "Required"
    // }
    if (password.trim() === "") {
        return "Please fill in the password field";
    }
    else if (password !== confirmPassword) {
        return "Password does not match";
    }

    return "";
};
export const country = (values) => {
    let errors;
    // console.log("validate", numCaseRegex.test(values));
    // console.log("errors", errors);
    // console.log("values confirmpassword ", values);

    if (values.trim() === "") {
        return "Required";
    }


    return errors;
};



export const emailValidation = (values) => {
    if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            values,
        )
    ) {
        return null;
    }
    if (values.trim() === '') {
        return 'Required';
    }
    return `Did you mean ${values.replace(/\s/g, '')}@xyz.com`;
};




export const NameValidation = (field, values) => {

    // console.log(field)
    if (values.trim() === '') {
        return `Required`;
    }
    if (/[^a-zA-Z -]/.test(values[field])) {
        return 'Invalid characters';
    }
    if (values.trim().length < 3) {
        return `Name needs to be at least three characters`;
    }
    return null;
};


export const DeveloperValidation = (values) => {


    if (values.trim() === "") {
        return "Please select an option";
    }

    return null;
};


export const phoneNoValidation = (values) => {


    if (values.trim() === "") {
        return "Required";
    }

    return "";
};

export const BusinessSupport = (values) => {


    if (values.trim() === "") {
        return "Required";
    }

    return "";
};

export const BusinessWebsite = (values) => {


    if (values.trim() === "") {
        return "Required";
         } 
    //      else if (urlRegex.test(
    //     values
    // )) {
    //     return "Please enter a valid website address"
    // }

    return "";
};

export const BusinessName = (values) => {


    if (values.trim() === "") {
        return "Required";
    }
    else if (values.trim().length < 3) {
        return `Business Name should be at least three characters`;
    }



    return "";
};
// export const BusinessSector = (values) => {



//     return "";
// };

export const Terms = (values) => {

    if (!values) {
        return "Required";
    }




    return "";
};

