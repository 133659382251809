import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import style from "./index.module.scss";

const Terms = () => {
  const pageTop = React.useRef(null);

  React.useEffect(() => {
    pageTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <div ref={pageTop}></div>
      <Header bgStyle="privacy__page--bg" theme="darkTheme"></Header>
      <div className={style["terms"]}>
        <div className={style["container"]}>
          <h3>Terms of Use</h3>
          <p className={style["title"]}>
            Read our terms of use to understand the rules of using
            MyserviceAgent (MSA) platform.
          </p>
          <p className={style["text"]}>
            These terms of service (Agreement) set out the legally binding terms
            and conditions for the use of the services.
            <br />
            You agree not to use the MSA service in or for any illegal,
            unauthorized or improper manner or purpose and agree that the
            Service will only be used in a manner that complies with all
            applicable laws, rules and regulations.
          </p>
          <p className={style["subtitle"]}>
            Violation of these Terms of Service
          </p>
          <p className={style["text"]}>
            We reserve the right to investigate and prosecute violations of any
            of these Terms of Service to the fullest extent of the law. You
            hereby agree that MSA may, in the exercise of our sole discretion,
            remove or delete any data, accounts or other content that violates
            these Terms of Service or that is otherwise objectionable.
          </p>
          <p className={style["subtitle"]}>Termination</p>
          <p className={style["text"]}>
            {" "}
            MSA, in its reasonable discretion, has the right to suspend your
            account if you materially breach these Terms of Service. Any
            eventual termination of your account will result in the deactivation
            or deletion of your account, denial of access to your account,and
            the removal of all content in your account.
          </p>
          <p className={style["subtitle"]}>
            Modifications to MSA Site and Prices
          </p>
          <ul>
            <li>
              We reserve the right at any time and from time to time to modify
              or discontinue, temporarily or permanently, the MSA service,
              systems, structures or mode of operation such as its features or
              messaging channels. However, we will make our very best efforts to
              promptly notify customers of any upcoming changes, system
              maintenance and /or service disruption.
            </li>
            <li>Prices of all MSA Service plans are subject to change.</li>
            <li>
              We will not be liable to you or to any third party for any
              modification, price change, suspension or discontinuance of MSA
              Service.
            </li>
            <li>
              We reserve the right, in our sole discretion, to change, modify,
              add to, or delete any portion of these Terms of Service at any
              time, effective with or without prior notice; provided, however,
              that we will use reasonable efforts to provide you with
              notification of any material changes (as determined in our sole
              discretion).
            </li>
            <li>
              If any future changes to these Terms of Service are unacceptable
              to you or cause you to no longer be in compliance, you must
              immediately stop using the MSA Service. Your continued use of the
              Service following any revision to this Agreement constitutes your
              complete acceptance of any and all such changes. Any new features
              that augment or enhance the current MSA Service, including the
              release of new tools and resources, will be subject to these Terms
              of Service.
            </li>{" "}
          </ul>
          <p className={style["subtitle"]}>
            Intellectual Property and Content Ownership
          </p>
          <p className={style["text"]}>
            We claim no ownership rights over the content submitted or created
            exclusively by you in your use of the MSA service account. Any
            content that is yours remains yours. These terms of Service do not
            grant us any licenses or rights to your content except for the
            limited rights needed for us to provide the Service to you.
          </p>
          <ul>
            <li>
              {" "}
              Similarly, any reporting data we collect from your use of the MSA
              service remains yours. By using the MSA service you agree that we
              can use this data solely to provide the service.
            </li>
            <li>
              Subject to clause 6.1, MSA shall only be allowed to include your
              trade name, marks or logo (“marks”) on its website or other
              promotional document for the sole purpose of publicity or
              marketing of the service provided under this Agreement.{" "}
            </li>
            <li>
              The contents of the MSA service are copyrighted under the Nigerian
              copyright laws (© MyServiceAgent 2022 ). MSA, and the MSA logos,
              are trademarks of MSA and may not be used without our express
              written permission. You may not duplicate, copy, or re-use any
              portion of the HTML/CSS or visual design elements without express
              written permission. You do not acquire any ownership rights by
              using MSA Service.
            </li>
          </ul>
          <p className={style["subtitle"]}>Account Access</p>
          <p className={style["text"]}>
            When you provide personal information to us, we will not share that
            information with third parties without your permission, other than
            in circumstances referred to above. We may send out important
            notices informing you of changes to our policies, technical service
            issues related to a product or service, or other information that is
            essential to the use of our Site, services, and products.
          </p>
          <p className={style["subtitle"]}>Access to Your Information</p>
          <p className={style["text"]}>
            In some cases, it is necessary for MSA employees to access your
            account and content in order to diagnose a problem. When you contact
            our support team, it is implied that you are allowing MSA employees
            to access your account if necessary, in order to be helpful. If you
            wish to receive assistance without granting permission to your
            account, please specify as much in your communication with our
            support team and those requests will be honored to the extent
            possible.
          </p>
          <p className={style["subtitle"]}>Indemnity</p>
          <p className={style["text"]}>
            By using this website you agree to use it for only legal activities.
            You agree to take full responsibility for all activities or use that
            contravenes the law of any state or country where it is being used
            and no responsibility in part or whole will be held by MSA
            Technologies LTD for your breach. Any noticed breach will warrant an
            outright removal from all MSA Technologies LTD platforms without
            notice.
          </p>
          <p className={style["subtitle"]}>Disclaimer</p>
          <p className={style["text"]}>
            We don't guarantee 100% delivery rate of SMS or voice messages.
          </p>
          <p className={style["subtitle"]}>Limitation of Liability</p>
          <p className={style["text"]}>
            Except for breach of confidentiality and intellectual property
            violation, MSA will not be liable for any indirect, incidental,
            special, exemplary or consequential damages, lost prof-its, lost
            data, or business interruption, in any way whatsoever arising out of
            the use of, or inability to use, the MSA Service, whether or not MSA
            is advised of the possibility of such damages.{" "}
          </p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Terms;
