import React, { useState } from "react";
import style from "./index.module.scss";
import Header from "../../../../components/Header";
import Button from "../../../../components/Button";
import Footer from "../../../../components/Footer";
import axios from "../../../../utils/axios-base";
import Dialog from "../../../../components/Dialog";
import password from "../../../../assets/password.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormRowGroup } from "../../../../components/Form-row";
import * as Yup from "yup";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";

export default function DemoOTPAuthentication() {
  let [messages, setMessages] = useState({
    voiceOtpCall: "",
    voiceOtpValidate: "",
  });

  const [responses, setResponses] = useState(null);
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validateSuccess, setValidateSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [process, setProcess] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer XYRQTlpabh3V6EsZ",
    },
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;
  async function handleVoiceOtpCall(values) {
    setResponses("Sending OTP...");

    try {
      const data = {
        msisdn: values.phoneNumber,
        length: 4,
        expires: 10,
      };
      sessionStorage.setItem("demoPhoneNumber", values.phoneNumber);
      let res = await axios.post("/otp/voice", data, config);
      if (res.status === 200) {
        setSuccess(true);
        setResponses(null);
        setMessages({ ...messages, voiceOtpCall: "OTP has been sent" });
      } else {
        setSuccess(false);
        setResponses(null);
        setMessages({ ...messages, voiceOtpCall: res.data.message });
      }
    } catch (err) {
      setSuccess(false);
      setResponses(null);
      setMessages({
        ...messages,
        voiceOtpCall: err.response.data.error.msisdn
          ? err.response.data.error.msisdn
          : err.response.data.error,
      });
    }
  }

  async function handleVoiceOtpValidate(values) {
    setResponses("Validating OTP...");

    try {
      const data = {
        msisdn: sessionStorage.getItem("demoPhoneNumber"),
        otp: values.otp,
      };
      let res = await axios.post("/otp/verify", data, config);
      if (res.status === 200) {
        setValidateSuccess(true);
        setResponses(null);
        setMessages({ ...messages, voiceOtpValidate: res.data.message });
      } else {
        setValidateSuccess(false);
        setResponses(null);
        setMessages({ ...messages, voiceOtpValidate: "Error validating OTP" });
      }
    } catch (err) {
      setValidateSuccess(false);
      setResponses(null);
      setMessages({
        ...messages,
        voiceOtpValidate: err.response.data.error.otp
          ? err.response.data.error.otp
          : err.response.data.error,
      });
    }
  }

  const handleClose = () => {
    setIsOpen(false);
    setResponses(null);
    setSuccess(false);
    setMessages({
      voiceOtpCall: "",
      voiceOtpValidate: "",
      errMessage: "",
    });
    setValidateSuccess(null);
  };

  const handleNewsletter = async () => {
    if (!email) {
      setProcess("Email address is required");
    } else {
      setProcess("Subscribing to newsletter...");
      setIsLoading(true);
      const payload = {
        u: "44d4a61233b00e03bd3168a90",
        id: "18c8aff5b4",
        MERGE0: email,
      };

      await axios
        .post(
          `https://myserviceagent.us20.list-manage.com/subscribe/post?u=${payload.u}&id=${payload.id}&MERGE0=${email}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setProcess("");
            setIsLoading(false);
            setIsOpen(true);
          } else {
            setIsOpen(true);
          }
        })
        .catch((error) => {
          setProcess("");
          setIsOpen(true);
          setIsLoading(false);
          console.error("Error:", error);
        });
    }
  };

  const mouseEnter = () => {
    let vid = document.getElementById("video");
    vid.onmouseenter = function () {
      vid.setAttribute("controls", "controls");
    };
  };
  return (
    <>
      <Header theme="darkTheme">
        <div className={style["header__details"]}>
          <div className={style["header"]}>
            <div className={style["column"]}>
              <h3>
                Demo our <span>Voice OTP </span>
                solution
              </h3>
              <p>
                You can setup up OTP verification by connecting to MSA OTP
                Authentication API.
              </p>
              <p>
                Please input your email address in the column provided to
                proceed
              </p>
              <div className={style["form-section"]}>
                <label style={{ color: "#1D293F" }} htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Button
                  type="submit"
                  className="small--filled"
                  onClick={handleNewsletter}
                >
                  {!isLoading ? (
                    "Start Demo"
                  ) : (
                    <ScaleLoader
                      css={override}
                      size={80}
                      color={"#fff"}
                      loading={true}
                    />
                  )}
                </Button>
              </div>
            </div>
            <div className={style["column"]}>
              <video
                onMouseEnter={mouseEnter}
                id="video"
                width="100%"
                // className={style["column"]}
                autoPlay={true}
                muted={true}
                controlsList="nodownload"
              >
                <source
                  src="https://res.cloudinary.com/myserviceagent/video/upload/v1643800306/OTP_Authentication_mixfdy.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </Header>

      <Dialog isOpen={isOpen}>
        <div className={style["dialg--container"]}>
          <div className={style["close-dialog"]} onClick={handleClose}>
            {" "}
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.6635 2.13128L22.0056 0.493117C21.557 0.0445192 20.8159 0.0445192 20.3478 0.493117L12.078 8.76273L3.65232 0.337083C3.20353 -0.11171 2.46236 -0.11171 1.99446 0.337083L0.336595 1.99495C-0.112198 2.44354 -0.112198 3.18471 0.336595 3.65281L8.74293 12.0591L0.492629 20.3485C0.0440309 20.7971 0.0440309 21.5382 0.492629 22.0063L2.15049 23.6642C2.59909 24.1128 3.34025 24.1128 3.80835 23.6642L12.078 15.3944L20.3478 23.6642C20.7964 24.1128 21.5375 24.1128 22.0056 23.6642L23.6635 22.0063C24.1121 21.5577 24.1121 20.8166 23.6635 20.3485L15.3742 12.0787L23.644 3.80904C24.1121 3.34055 24.1121 2.59938 23.6635 2.13128Z"
                fill="#323232"
              />
            </svg>
          </div>

          <div style={{ marginTop: "42px" }}>
            <img src={password} alt="faq" height="80px" width="80px" />

            <div className={style["response"]}>
              <h3>Test Voice OTP Authentication</h3>
              <p>
                {success
                  ? "Kindly input the OTP code sent to your phone number to complete your verification."
                  : "  Please input the phone number associated with your account, we will send you an OTP to verify."}
              </p>
            </div>
            {!success && (
              <>
                <Formik
                  initialValues={{ phoneNumber: "" }}
                  validationSchema={Yup.object().shape({
                    phoneNumber: Yup.string().required(
                      "Phone number is required"
                    ),
                  })}
                  onSubmit={handleVoiceOtpCall}
                >
                  <Form>
                    <FormRowGroup>
                      <div className={style["form-head"]}>
                        <label
                          style={{ color: "#1D293F" }}
                          htmlFor="phoneNumber"
                        >
                          Phone Number
                        </label>
                        <span>Step 1/2</span>
                      </div>
                      <Field
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Enter phone number"
                      />
                      <ErrorMessage name="phoneNumber" component="span" />
                    </FormRowGroup>
                    <p>{process}</p>
                    <Button type="submit" className="small--filled">
                      {responses ? responses : "Send OTP"}
                    </Button>
                  </Form>
                </Formik>
              </>
            )}

            {success && (
              <div className={style["response"]}>
                <Formik
                  initialValues={{ otp: "" }}
                  validationSchema={Yup.object().shape({
                    otp: Yup.string().required("OTP is required"),
                  })}
                  onSubmit={handleVoiceOtpValidate}
                >
                  <Form>
                    <FormRowGroup>
                      <div className={style["form-head"]}>
                        <label style={{ color: "#1D293F" }} htmlFor="otp">
                          Input OTP
                        </label>
                        <span>Step 2/2</span>
                      </div>
                      <Field
                        type="text"
                        name="otp"
                        id="otp"
                        placeholder="Enter OTP"
                      />
                      <ErrorMessage name="otp" component="span" />

                      <p
                        className={
                          validateSuccess ? style["success"] : style["error"]
                        }
                      >
                        <span>
                          {validateSuccess === true ? (
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                style={{ fill: "#27AE60" }}
                                d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9.003 14L16.073 6.929L14.659 5.515L9.003 11.172L6.174 8.343L4.76 9.757L9.003 14Z"
                                fill="#27AE60"
                              />
                            </svg>
                          ) : validateSuccess === false ? (
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                style={{ fill: "#EB2931" }}
                                d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 9V15H11V9H9ZM9 5V7H11V5H9Z"
                                fill="#EB2931"
                              />
                            </svg>
                          ) : (
                            ""
                          )}
                        </span>
                        <span>{messages.voiceOtpValidate}</span>
                      </p>
                    </FormRowGroup>
                    <Button type="submit" className="small--filled">
                      {responses ? responses : "Validate"}
                    </Button>
                  </Form>
                </Formik>
              </div>
            )}
          </div>
        </div>
      </Dialog>

      {/* <ContactForm /> */}
      <Footer />
    </>
  );
}
