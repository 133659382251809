/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout/DashboardLayout";
import style from "./index.module.scss";
import { useLocation } from "react-router-dom";
import Button from "../../../components/Button";
import { Pagination } from "../../../components/Pagination";
import Modal from "../../../components/Modal";
import axios from "axios";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Construction from "../../../assets/images/under-construction.svg";
import { ScaleLoader } from "react-spinners";
import Dialog from "../../../components/Dialog";

const Cards = ({ userInfo }) => {
  const cards = [
    {
      description: "Pending Request",
      amt: `${userInfo ? userInfo.pending : "..."}`,
    },
    {
      description: "Active Sender ID",
      amt: `${userInfo ? userInfo.active : "..."}`,
    },
    {
      description: "Total Sender ID",
      amt: `${userInfo ? userInfo.total : "..."}`,
    },
  ];

  return (
    <div>
      <ul className={style["cards--container"]}>
        {cards.map((item) => (
          <li className={style["cards--item"]}>
            <header>
              <h2>{item.amt}</h2>
              <p>{item.description}</p>
            </header>
          </li>
        ))}
      </ul>
    </div>
  );
};

const SenderID = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalopen] = useState(false);
  const [modalOpen, setModalopen] = useState(false);
  const [senderIds, setSenderIds] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [requestData, setRequestData] = useState({
    sender_id: "",
    description: "",
  });
  const { token, authState } = useSelector((state) => state.auth);

  const config = {
    headers: {
      Authorization: "Bearer XYRQTlpabh3V6EsZ",
    },
  };

  const th = [
    {
      name: "S/N",
    },
    {
      name: "Sender ID",
    },
    {
      name: "Descriptions",
    },
    {
      name: "Status",
    },
    {
      name: "Date",
    },
  ];
  const fetchSenderIDs = async () => {
    try {
      setIsLoading(true);

      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/senderid/dev/client/summary`,
        {
          client_id: authState.id,
          token,
        },
        config
      );

      if (res.status === 200) {
        setIsLoading(false);
        setSenderIds(res.data);
      } else {
        setIsLoading(false);
        setSenderIds(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setNotFound("No sender IDs found yet. :(");
    }
  };

  useEffect(() => {
    fetchSenderIDs();
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/senderid/dev/request`,
        {
          sender_id: requestData.sender_id,
          description: requestData.description,
          client_id: authState.id,
          token,
        },
        config
      );
      if (res.status) {
        setIsLoading(false);
        setModalopen(true);
        setIsModalopen(false);
        fetchSenderIDs();
      } else {
        setIsLoading(false);
        console.log(res.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err.message);
    }
  };

  return (
    <Layout
      pageTitle={
        location.pathname === "/user/sender-id" ? "Sender ID" : "Wallet"
      }
    >
      <Dialog isOpen={isLoading}>
        <ScaleLoader />
      </Dialog>
      <Modal isOpen={modalOpen} toggleModal={() => setModalopen(!modalOpen)}>
        <div className={style.top}>
          <h2>Your Request is Under Review</h2>
          <p>We will send you a confrimation mail of your request</p>
        </div>
        <form>
          <Button
            type="button"
            className="large--filled"
            onClick={() => setModalopen(false)}
          >
            Ok
          </Button>
        </form>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        toggleModal={() => setIsModalopen(!isModalOpen)}
      >
        <div className={style.top}>
          <h2>Sender ID details</h2>
          <p>Please enter your customized sender ID</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Sender ID</label>
            <input
              required
              value={requestData.sender_id}
              onChange={(e) =>
                setRequestData((prev) => ({
                  ...prev,
                  sender_id: e.target.value,
                }))
              }
              maxLength={11}
              type="text"
              placeholder="Raah Cosmetics"
            />
          </div>
          <div>
            <label>Description</label>
            <textarea
              required
              rows="5"
              type="text"
              maxLength={255}
              placeholder="Raah Cosmetics"
              value={requestData.description}
              onChange={(e) =>
                setRequestData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </div>
          <Button type="submit" className="large--filled">
            Request
          </Button>
        </form>
      </Modal>
      {notFound ? (
        <main className={style["main"]}>
          <div>
            <img src={Construction} alt="under construction" />
          </div>
          <div className={style["create"]}>
            <h4>No sender IDs found yet.</h4>
            <span>
              Create one <span onClick={() => setIsModalopen(true)}>here</span>
            </span>
          </div>
        </main>
      ) : (
        <div style={{ padding: "2rem" }}>
          <div className={style["actionarea"]}>
            <Button
              type="button"
              className="medium--filled"
              onClick={() => setIsModalopen(true)}
            >
              <span>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    style={{ fill: "white" }}
                    d="M16.5 2.25H1.5C0.675 2.25 0 2.925 0 3.75V14.25C0 15.075 0.675 15.75 1.5 15.75H16.5C17.325 15.75 17.9925 15.075 17.9925 14.25L18 3.75C18 2.925 17.325 2.25 16.5 2.25ZM16.5 14.25H1.5V3.75H16.5V14.25ZM15.75 4.5H10.5V8.25H15.75V4.5ZM15 6L13.125 7.3125L11.25 6V5.25L13.125 6.5625L15 5.25V6ZM6.75 9C7.9875 9 9 7.9875 9 6.75C9 5.5125 7.9875 4.5 6.75 4.5C5.5125 4.5 4.5 5.5125 4.5 6.75C4.5 7.9875 5.5125 9 6.75 9ZM6.75 6C7.1625 6 7.5 6.3375 7.5 6.75C7.5 7.1625 7.1625 7.5 6.75 7.5C6.3375 7.5 6 7.1625 6 6.75C6 6.3375 6.3375 6 6.75 6ZM11.25 12.4425C11.25 10.5675 8.2725 9.7575 6.75 9.7575C5.2275 9.7575 2.25 10.5675 2.25 12.4425V13.5H11.25V12.4425ZM4.11 12C4.665 11.625 5.775 11.25 6.75 11.25C7.725 11.25 8.8275 11.6175 9.39 12H4.11Z"
                    fill="#fff"
                  />
                </svg>
              </span>
              Request Sender ID
            </Button>
          </div>
          <Cards
            userInfo={
              senderIds && {
                pending: senderIds.pending,
                active: senderIds.verified,
                total: senderIds.total,
              }
            }
          />
          <div className={style["table--container"]}>
            <table className={style["HistoryTable--table"]}>
              {" "}
              <thead>
                <tr>
                  {th.map((item) => {
                    return <th>{item.name}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {senderIds &&
                  senderIds.data.data.map((senderId, index) => (
                    <tr key={senderId.id} className={style["table-row"]}>
                      <td>{index + 1}</td>
                      <td>{senderId.sender_id}</td>
                      <td>{senderId.description}</td>
                      <td>
                        {senderId.status.toLowerCase() === "new" ? (
                          <span className={style["new"]}>
                            {senderId.status}
                          </span>
                        ) : senderId.status.toLowerCase() === "rejected" ? (
                          <span className={style["pending"]}>
                            {senderId.status}
                          </span>
                        ) : (
                          <span className={style["confirmed"]}>
                            {senderId.status}
                          </span>
                        )}
                      </td>
                      <td>
                        {dayjs(senderId.created_at).format("DD-MMM-YYYY")}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className={style["paginate"]}>
            {senderIds && (
              <Pagination
                numberOfPages={senderIds.from}
                currentPage={senderIds.current_page}
                nextPage={senderIds.to}
              />
            )}
          </div>
        </div>
      )}
      )
    </Layout>
  );
};

export default SenderID;
