import React from "react";
import style from "./index.module.scss";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Button from "../../../components/Button";
import Footer from "../../../components/Footer";
import SubscribeForm from "../../../components/SubscribeForm";
import open from "../../../assets/images/open.svg";
import close from "../../../assets/images/close.svg";
import CheckBox from "../../../assets/images/check.png";

export default function CallCenter() {
  const pageTop = React.useRef(null);
  const [showAnswer, setAnswer] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  // function scrollDown(){
  //     faqsTop.current.scrollIntoView({behavior: "smooth"});
  // }

  function handleToggle(index) {
    const ansArr = [false, false, false, false, false];
    if (showAnswer[index]) {
      setAnswer(ansArr);
    } else {
      ansArr[index] = true;
      setAnswer(ansArr);
    }
  }

  React.useEffect(() => {
    pageTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const lists = [
    "Fast and convenient responses to your customers",
    "Resolve basic enquires, FAQ and complaints without needing a physical agent ",
    "Save costs on traditional brick and mortar call center",
    "Hear what your customers literally say about you",
    "Only deploy your customer success team to handle absolutely necessary and high contextual issues",
    "Eliminate call queues",
    "Communicate with over 1000 customers round the clock in any of the major Nigerian languages",
    "Interact with thousands of customers in real time in a personalized way, just like a human agent",
    "Easy to integrate",
    "Accurate sentiment analysis",
    "Access to call recordings and playback",
  ];

  return (
    <>
      <div ref={pageTop}></div>
      <Header bgStyle="call__center__page--bg" theme="lightTheme">
        <div className={style["header__details"]}>
          <div>
            <h3>Virtual Call Center</h3>
            <p className={style["third__header__paragraph"]}>
              Welcome to your call center in the cloud. A redefined customer
              support for better customer experience.
            </p>

            <Link to="/sign-up" style={{ display: "flex" }}>
              <Button className="medium--filled">Get Started</Button>
            </Link>
          </div>
        </div>
      </Header>

      <section className={style["section__callcenter--one"]}>
        <div className={style["container"]}>
          <div className={style["container--left"]}>
            <h1 className={style["intro__text--header"]}>
              Why use Virtual Call Center?
            </h1>
          </div>

          <div className={style["container--right"]}>
            <ul>
              {lists.map((item, index) => (
                <li key={index}>
                  <span>
                    <img src={CheckBox} alt={CheckBox} />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className={style["section__callcenter--two"]}>
        <div className={style["accordion"]}>
          <h3 className={style["accordion__title"]}>
            Answers to all your questions
          </h3>
          {faqs.map((faq, index) => (
            <div
              className={style["accordion__list"]}
              key={index}
              onClick={() => handleToggle(index)}
            >
              <div className={style["header"]}>
                {" "}
                <h3>{faq.question}</h3>
                <img src={showAnswer[index] ? close : open} alt="open" />
              </div>
              {showAnswer[index] && <p>{faq.answer}</p>}
            </div>
          ))}
        </div>
        <div className={style["circle"]}></div>
      </section>

      <SubscribeForm />
      <Footer />
    </>
  );
}
const faqs = [
  {
    question: "What happens when the AI doesn't have an answer to an enquiry?",
    answer:
      "Our intelligent AI can handle this in many ways including scheduling a call back to provide a response or forwarding the call to a call rep agent.",
  },
  {
    question: "Are outbound calls possible?",
    answer: "Yes. Both inbound and outbound calls can be made",
  },
  {
    question: "Is it pre-recorded? ",
    answer:
      "Yes. The flow for our virtual call centre is decided by all parties involved. We love to work closely with you, to give you exactly what you need ",
  },
  {
    question: "Can the API be integrated into our system?",
    answer:
      "Yes. We believe you deserve a smooth service so you can integrate using our API.",
  },
  {
    question: "What if the customer speaks a different language?",
    answer:
      "Our Voice AI is programmed in an authentic Nigerian Voice and can also speak any of the Major Nigerian Languages; Pidgin, Yoruba, Igbo and Hausa.",
  },
];
