import React from "react";
import style from "./index.module.scss";
import Layout from "../../../../components/Layout/AdminDashboardLayout";
import PaymentsLayout from "../../../../components/Layout/PaymentsLayout";
import documentIcon from "../../../../assets/icons/document.svg";
import Modal from "../../../../components/Modal";
// import nigerianBanks from "../../../../utils/JSON/nigerian-banks.json";
import Button from "../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import * as paymentActions from "../../../../redux/actions/paymentActions";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";
import { clearErrorMessage } from "../../../../redux/actions/messageActions";
import usePagination from "../../../../Customhooks/usePaginationCore";
import { Pagination } from "../../../../components/Pagination";
// import {Pagination} from "../../../../components/Pagination";
// import usePagination from "../../../../Customhooks/usePaginationCore";

const BankPayments = () => {
  const token = sessionStorage.getItem("msa_jwt");
  const payment = useSelector(({ payments }) => payments.bank_payments);

  return (
    <Layout pageTitle="Bank Payments">
      <PaymentsLayout>
        <div className={style["admin__container"]}>
          <div className={style["admin__info"]}>
            <h3>Bank Transfers</h3>
            <p>Below is a list of the bank transfer operations.</p>
          </div>

          <div className={style["payments__table"]}>
            <BankPaymentsTable paymentData={payment} token={token} />
          </div>
        </div>
      </PaymentsLayout>
    </Layout>
  );
};

const BankPaymentsTable = ({ paymentData, token }) => {
  const dispatch = useDispatch();
  const [isDocumentOpen, setIsDocumentOpen] = React.useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = React.useState(false);
  const [payment, setPayment] = React.useState();
  const notification = useSelector(({ message }) => message.id);
  const client_token = sessionStorage.getItem("msa_jwt");
  const totalPages = Math.ceil(paymentData?.total / 15); // Returns 15 per page

  const { nextPage, currentPage } = usePagination(
    "admin",
    paymentActions.getBankPaymentsAdmin
  );

  React.useEffect(() => {
    dispatch(paymentActions.getBankPaymentsAdmin(client_token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;
  dayjs.extend(localizedFormat);

  // const getBankName = (slug) => {
  //   const name = nigerianBanks.filter((x) => x.slug === slug)[0].name;
  //   return name;
  // };

  // const getBankLogo = (slug) => {
  //   const logo = nigerianBanks.filter((x) => x.slug === slug)[0].logo;
  //   return logo;
  // };

  const handleToggleDocumentModal = () => {
    setIsDocumentOpen(!isDocumentOpen);
  };

  const handleToggleVerifyModal = () => {
    setIsVerifyModalOpen(!isVerifyModalOpen);
    DeclineLoader.handleLoadingState(false);
    ApproveLoader.handleLoadingState(false);

    dispatch(clearErrorMessage());
    dispatch(paymentActions.getBankPaymentsAdmin(client_token));
  };
  const formData = new FormData();
  const ApproveLoader = useLoader();
  const DeclineLoader = useLoader();

  const approveBankPayment = (data, status) => {
    const verificationStatus = status === "invalid" ? "invalid" : "verified";
    formData.append("id", data.id);
    formData.append("status", verificationStatus);
    formData.append("amount_paid", data.amount_paid);
    // formData.append("payment_evidence", data.payment_evidence);
    formData.append("access_token", token);
    ApproveLoader.handleLoadingState(true);

    dispatch(
      paymentActions.verifyBankPayments(
        formData,
        data.id,
        "VERIFICATION_SUCCESS"
      )
    );

    if (notification === "VERIFICATION_DECLINED") {
      dispatch(clearErrorMessage());
      DeclineLoader.handleLoadingState();
    }
  };

  const declineBankPayment = (data, status) => {
    const verificationStatus = status === "invalid" ? "invalid" : "verified";
    formData.append("id", data.id);
    formData.append("status", verificationStatus);
    formData.append("amount_paid", data.amount_paid);
    // formData.append("payment_evidence", data.payment_evidence);
    formData.append("access_token", token);
    DeclineLoader.handleLoadingState(true);

    dispatch(
      paymentActions.verifyBankPayments(
        formData,
        data.id,
        "VERIFICATION_DECLINED"
      )
    );
  };

  const header = [
    "MSA Account Name",
    "Email Address",
    // "Bank Name",
    "Units",
    "Date",
    "Description",
    "Document",
    "Remark",
  ];

  const [documentImageSrc, setDocumentImageSrc] = React.useState(null);

  const renderTableHeader = () => {
    return header.map((item, id) => <th key={id}>{item}</th>);
  };

  const handleVerify = (item) => {
    handleToggleVerifyModal();
    setPayment(item);
  };

  const handleShowDocument = (src) => {
    setDocumentImageSrc(src);
    handleToggleDocumentModal();
  };

  const verifyDetails = (payment) => {
    return [
      { name: "Credit Alert", value: `NGN ${payment.amount_paid}` },
      // { name: "Transaction Id", value: `${payment.transaction_id}` },
      { name: "Description", value: `${payment.description}` },
      // { name: "Name", value: payment.sender_name || "Not Specified" },

      { name: "Time", value: dayjs(payment.updated_at).format("LT") },
      { name: "Date", value: dayjs(payment.updated_at).format("L") },
      // { name: "Transaction Status", value: payment.status },
    ];
  };

  const renderTableData = () => {
    return paymentData?.data?.map((item, id) => (
      <tr key={id}>
        <th>{item.client?.contact_person}</th>
        <th>{item.client_email}</th>
        {/* <th>{getBankName(item.bank_name)}</th> */}
        <th>{item.airtime_units}</th>
        <th>{dayjs(item.created_at).format("L")}</th>
        <th>{item.description}</th>
        <th>
          <img
            src={documentIcon}
            alt="documentIcon"
            onClick={() => {
              handleShowDocument(item.payment_evidence);
            }}
          />
        </th>
        {item.status?.toLowerCase() === "verified" ? (
          <th>
            {" "}
            <span className={style["approved"]}>Approved</span>
          </th>
        ) : item.status?.toLowerCase() === "new" ? (
          <th>
            <span
              className={style["verify"]}
              onClick={() => {
                handleVerify(item);
              }}
            >
              Verify
            </span>
          </th>
        ) : (
          <th>
            <span className={style["declined"]}>Declined</span>
          </th>
        )}
      </tr>
    ));
  };

  return (
    <>
      <table>
        <thead>
          <tr>{renderTableHeader()}</tr>
        </thead>

        <tbody>{renderTableData()}</tbody>
      </table>
      <Pagination
        numberOfPages={totalPages}
        currentPage={currentPage}
        nextPage={nextPage}
      />

      <Modal
        isOpen={isDocumentOpen}
        toggleModal={handleToggleDocumentModal}
        className={style["modal"]}
      >
        {documentImageSrc ? (
          <div className={style["document__container"]}>
            <img src={documentImageSrc} alt="Payment Evidence" loading="lazy" />
          </div>
        ) : (
          ""
        )}
      </Modal>

      <Modal
        isOpen={isVerifyModalOpen && !notification}
        toggleModal={handleToggleVerifyModal}
        className={style["modal"]}
      >
        {payment ? (
          <div className={style["verify__container"]}>
            <h4>Dear MSA,</h4>
            {verifyDetails(payment).map((item, id) => (
              <div className={style["text__row"]}>
                <span>{item.name}</span>
                <span>{item.value}</span>
              </div>
            ))}

            <Button
              className="filled"
              onClick={() => approveBankPayment(payment, "verified")}
              style={{ marginBottom: "1rem" }}
            >
              {!ApproveLoader.isLoading ? (
                "Approve"
              ) : (
                <ScaleLoader
                  css={override}
                  size={80}
                  color={"#fff"}
                  loading={true}
                />
              )}
            </Button>
            <Button
              className="medium--ghost"
              onClick={() => declineBankPayment(payment, "invalid")}
            >
              {!DeclineLoader.isLoading ? (
                "Decline"
              ) : (
                <ScaleLoader
                  css={override}
                  size={80}
                  color={"red"}
                  loading={true}
                />
              )}
            </Button>
          </div>
        ) : (
          ""
        )}
      </Modal>
      <Modal
        isOpen={notification === "VERIFICATION_SUCCESS"}
        toggleModal={handleToggleVerifyModal}
        className={style["modal"]}
      >
        <h4>Transfer Approved</h4>
      </Modal>
      <Modal
        isOpen={notification === "VERIFICATION_DECLINED"}
        toggleModal={handleToggleVerifyModal}
        className={style["modal"]}
      >
        <h4>Transfer Declined</h4>
      </Modal>
    </>
  );
};

export default React.memo(BankPayments);

// ||
// item.status?.toLowerCase() === "invalid" ? (

const useLoader = () => {
  const [isLoading, setLoader] = React.useState(false);
  // const notification = useSelector((state) => state.message);
  // const dispatch = useDispatch();
  const handleLoadingState = (bool) => {
    setLoader(bool);
  };

  return { isLoading, handleLoadingState };
};
