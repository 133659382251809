import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import SubscribeForm from "../../../components/SubscribeForm";
import style from "./index.module.scss";

export default function Demo() {
  const demos = [
    {
      title: "Business Messaging",
      content:
        "Engage your customers better with intelligent voice and SMS messaging",
      video:
        "https://res.cloudinary.com/myserviceagent/video/upload/v1643800293/Business_Messaging_df4k1d.mp4",
    },
    {
      title: "OTP Authentication",
      content:
        "Deliver timely verifications and authentication alerts to your customers using our voice and SMS services",
      video:
        "https://res.cloudinary.com/myserviceagent/video/upload/v1643800306/OTP_Authentication_mixfdy.mp4",
    },
    {
      title: "Voice Survey",
      content: "New level of detail and insight with voice survey",
      video:
        "https://res.cloudinary.com/myserviceagent/video/upload/v1643800265/Survey_xpgkyw.mp4",
    },
    // {
    //   title: "Virtual Call Center",
    //   content:
    //     "Welcome to your call center in the cloud. A redefined customer support for better customer experience.",
    //   video:
    //     "https://res.cloudinary.com/myserviceagent/video/upload/v1643800293/Business_Messaging_df4k1d.mp4",
    // },
    {
      title: "Omnichannel CRM",
      content: "Handle all your communications platforms in one place",
      video:
        "https://res.cloudinary.com/face-aging-api/video/upload/v1685111570/msa/Final_Edit__Service_Agent_Demo.mp4",
    },
  ];
  return (
    <>
      <Header bgStyle="demo__page"></Header>

      <div className={style["demo--container"]}>
        <h2>Watch a Demo</h2>

        <div className={style["demo--container__demos"]}>
          {demos.map((demo, index) => (
            <div key={index} className={style["demo--item"]}>
              <div className={style["demo--item__video"]}>
                <video
                  controls
                  id="video"
                  width="100%"
                  height="100%"
                  // onMouseEnter={mouseEnter}
                  autoPlay={false}
                  muted={true}
                  controlsList="nodownload"
                  preload="auto"
                >
                  <source src={demo.video} />
                </video>
              </div>
              <div className={style["demo--item__text"]}>
                <h3>{demo.title}</h3>
                <p>{demo.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <SubscribeForm />
      <Footer />
    </>
  );
}
