import axios from "../../utils/axios-base";
import * as types from './actionTypes'
import * as endpoints from '../../utils/endpoints';
// import * as tokenConfig from '../../utils/tokenConfig';
import { returnErrors, returnSuccess } from './messageActions';
// import { updateUser } from "./authActions";

const accountUpdateStart = () => ({
    type: types.UPDATE_USER_START
})

const accountUpdateSuccess = (token, user) => ({
    type: types.UPDATE_USER_SUCCESFUL,
    token,
    user
})

const accountUpdateFailed = () => ({
    type: types.UPDATE_USER_FAIL
})


export const updateUserInfo = (formData, id, notificationName) => dispatch => {
    dispatch(accountUpdateStart())
    const config = {
        headers: {
            "Accept": "application/json, text/plain, */*",
        }
    }
    const endpoint = endpoints.AUTH_URL
    axios
        .post(`${endpoint}/update/${id}`, formData, config)
        .then(res => {

            dispatch(accountUpdateSuccess(res.data))
            dispatch(returnSuccess(res.data.message, "Ok", notificationName))

        }).catch(err => {
            dispatch(accountUpdateFailed())
            dispatch(returnErrors(err.response.data, err.response.status))
        });

}

