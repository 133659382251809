import React from "react";
import Layout from "../../../../components/Layout/DashboardLayout";
import WalletLayout from "../../../../components/Layout/WalletLayout";
import PaymentEvidenceComponent from "../../../../components/PaymentEvidence";
// import PaymentEvidenceComponent from "../../../components/PaymentEvidence";
import style from "./index.module.scss";

export default function UploadEvidence() {
  return (
    <Layout pageTitle="Wallet">
      <WalletLayout>
        <div className={style["upload__evidence--container"]}>
          <header>
            <h3>Upload Payment Evidence</h3>
            <p>Upload details and screenshot of transaction done by bank transfer</p>
          </header>
          <hr />
          <PaymentEvidenceComponent />
        </div>
      </WalletLayout>
    </Layout>
  );
}
