import React from "react";
import style from "./index.module.scss";
import Layout from "../../../components/Layout/AdminDashboardLayout";
import PaymentsLayout from "../../../components/Layout/PaymentsLayout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as paymentActions from "../../../redux/actions/paymentActions";



const AdminPayments = () => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("msa_jwt");
  const userInfo = useSelector(({ auth }) => auth.authState);
  const purchaseSummary = useSelector(({ payments }) => payments.purchase_summary);
  console.log(purchaseSummary);

  const PaymentCardsInfo = [
    {
      title: "Bank Transfer",
      percentage: purchaseSummary?.bank_percent || 0,
      link: "/admin/payments/bank",
    },
  
    {
      title: "Card Payment",
      percentage: purchaseSummary?.paystack_percent || 0,
      link: "/admin/payments/card",
    },
  ];

  React.useEffect(() => {
    dispatch(paymentActions.getPurchaseSummary(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pageTitle="Payments">
      <PaymentsLayout>
        <div className={style["admin__container"]}>
          <div className={style["admin__info"]}>
            <h3>Hi, {userInfo.full_name}</h3>
            <p>Welcome to your My Service Agent Admin portal</p>
          </div>

          <div className={style["payments__container"]}>
            {PaymentCardsInfo?.map((item, id) => (
              <PaymentCard info={item} />
            ))}
          </div>
        </div>
      </PaymentsLayout>
    </Layout>
  );
};

const PaymentCard = (props) => {
  const { info } = props;

  return (
    <div className={style["payments__card"]}>
      <h3>{info.title}</h3>
      <div className={style["percentage"]}>
        <div className={style["circle"]}>
          <div className={style["bar"]}></div>
          <div className={style["box"]}>
            <CircleProgress percent={info.percentage} />
          </div>
        </div>
      </div>
      <div className={style["viewmore"]}>
        <Link exact to={info.link} className={style["viewmore--link"]}>
          View More
        </Link>
      </div>
    </div>
  );
};

const CircleProgress = (props) => {
  const { percent } = props;
  const r = 34;
  const c = Math.PI * (r * 2);
  const pct = ((100 - percent) / 100) * c;

  return (
    <div className={style["circle-progress"]} data-pct={percent}>
      <svg className={style["circle-progress-content"]} viewBox="0 0 74 74">
        <circle
          className={style["bg"]}
          cx="37"
          cy="37"
          r="34"
          fill="none"
          strokeWidth="3"
          strokeDasharray="213.63"
          strokeDashoffset={0}
        />
        <circle
          className={style["bar"]}
          cx="37"
          cy="37"
          r="34"
          fill="none"
          strokeWidth="3"
          strokeDasharray="213.63"
          strokeDashoffset={pct}
        />
      </svg>
    </div>
  );
};

export default AdminPayments;
