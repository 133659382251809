import React from "react";
import style from "./index.module.scss";
import Layout from "../../../../components/Layout/AdminDashboardLayout";
import PaymentsLayout from "../../../../components/Layout/PaymentsLayout";
import { useDispatch, useSelector } from "react-redux";
import * as paymentActions from "../../../../redux/actions/paymentActions";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Pagination } from "../../../../components/Pagination";
import usePagination from "../../../../Customhooks/usePaginationCore";

const CardPayments = () => {
  dayjs.extend(localizedFormat);

  const dispatch = useDispatch();
  const payment = useSelector(({ payments }) => payments.purchase_summary);
  const isLoading = useSelector(({ payments }) => payments.isLoading);
  const token = sessionStorage.getItem("msa_jwt");
  const totalPages = Math.ceil(payment?.data.total / 15); // Returns 15 per page

  const { nextPage, currentPage } = usePagination(
    "admin",
    paymentActions.getPurchaseSummaryCard
  );

  React.useEffect(() => {
    dispatch(paymentActions.getPurchaseSummaryCard(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pageTitle="Card Payments">
      <PaymentsLayout>
        <div className={style["admin__container"]}>
          <div className={style["admin__info"]}>
            <h3>Card Transfers</h3>
            <p>Below is a list of various card operations.</p>
          </div>

          <div className={style["payments__table"]}>
            <CardPaymentsTable
              payment={payment}
              isLoading={isLoading}
              token={token}
            >
              <Pagination
                numberOfPages={totalPages}
                currentPage={currentPage}
                nextPage={nextPage}
              />
            </CardPaymentsTable>
          </div>
        </div>
      </PaymentsLayout>
    </Layout>
  );
};

const CardPaymentsTable = ({ payment, children, isLoading }) => {
  const header = [
    "MSA Account ID",
    "Email Address",
    "Amount Paid",
    "Units",
    "Time",
    "Date",
  ];

  const renderTableHeader = () => {
    return header.map((item, id) => <th key={id}>{item}</th>);
  };

  const renderTableData = () => {
    // console.log(payment.data.data[0].client_id)
    return payment?.data?.data.map((item, id) => {
      return (
        item.purchase_source === "paystack" && (
          <tr key={id}>
            <th>{item.client_id}</th>
            <th>{item.client_email}</th>
            <th>{`${item.amount_paid}`}</th>
            <th>{item.airtime_units}</th>
            <th>{dayjs(item.created_at).format("L")}</th>
            <th>{dayjs(item.created_at).format("LT")}</th>
          </tr>
        )
      );
    });
  };

  return (
    <>
      <table>
        <thead>
          <tr>{renderTableHeader()}</tr>
        </thead>

        <tbody>{!isLoading && renderTableData()}</tbody>
      </table>
      {children}
    </>
  );
};

export default CardPayments;
