import React from "react";
import style from "./index.module.scss";
// import logo from "../../../assets/images/msa__logo.png";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "../../../components/Button";
import eyeClose from "../../../assets/icons/eye-close.png";
import eyeOpen from "../../../assets/icons/eye-open.png";
import * as authActions from "../../../redux/actions/authActions";
import * as messageActions from "../../../redux/actions/messageActions";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
// import Modal from "../../../components/Modal";
import { FormRowGroup } from "../../../components/Form-row";
import { ScaleLoader } from "react-spinners";
import AuthLayout from "../../../components/Layout/AuthLayout";
import SuccessIcon from "../../../assets/success.png";

const PasswordReset = () => {
  const notification = useSelector((state) => state.message);
  return (
    <AuthLayout>
      {notification.success ? <PasswordResetSuccess /> : <PasswordResetForm />}
    </AuthLayout>
  );
};

const PasswordResetForm = () => {
  const [showPassword, setVisibility] = React.useState({ id: null });
  const isLoading = useSelector((state) => state.auth.isLoading);
  const notification = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  const lowCaseRegex = new RegExp(/^(?=.*[a-z])/);
  const upperCaseRegex = new RegExp(/^(?=.*[A-Z])/);
  const specialCaseRegex = new RegExp(/^(?=.*[!@#$%^&*])/);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const email = urlParams.get("email");

  const initialValues = {
    email,
    token,
    password: "",
  };

  const passwordResetSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required.")
      .matches(
        lowCaseRegex,
        "Password must contain at least one lowercase character."
      )
      .matches(
        upperCaseRegex,
        "Password must contain at least one uppercase character."
      )
      .matches(
        specialCaseRegex,
        "Password must contain at least one special character."
      )
      .min(8, "Please fill in a minimum of 8 characters."),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .required("Please confirm your password"),
  });

  const handlePasswordVisibility = (e) => {
    let id = e.target.id;
    id === showPassword.id
      ? setVisibility({ ...showPassword, id: "" })
      : setVisibility({ ...showPassword, id });
  };

  React.useEffect(() => {
    return () => {
      dispatch(messageActions.clearErrorMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style["container"]}>
      <div className={style["reset--password"]}>
        <h3>Reset Password</h3>
        <p>Enter a new and strong password to secure your account</p>
        <ul className="signup__errors">
          {notification.error && <li>{notification.msg.error}</li>}
        </ul>
        <Formik
          initialValues={initialValues}
          validationSchema={passwordResetSchema}
          onSubmit={(values) => {
            const formData = new FormData();
            formData.append("email", values.email);
            formData.append("token", values.token);
            formData.append("password", values.password);

            dispatch(authActions.passwordReset(formData));
          }}
        >
          {({ errors, touched, isValid, dirty, handleSubmit }) => {
            return (
              <Form>
                <FormRowGroup>
                  <label htmlFor="password">New Password*</label>
                  <Field
                    type={showPassword.id === "password" ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="min. of 8 characters"
                    className={
                      errors.password && touched.password
                        ? "input--error"
                        : null
                    }
                  />
                  <span
                    id="password"
                    onClick={(e) => handlePasswordVisibility(e)}
                    className={style["toggle__password"]}
                  >
                    {showPassword.id === "password" ? (
                      <img id="password" src={eyeOpen} alt="eyeOpen"></img>
                    ) : (
                      <img id="password" src={eyeClose} alt="eyeClose"></img>
                    )}
                  </span>
                  <ErrorMessage
                    name="password"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>

                {/* <FormRowGroup>
                  <label htmlFor="confirmpassword">Confirm Password*</label>
                  <Field
                    type={
                      showPassword.id === "confirm_password"
                        ? "text"
                        : "password"
                    }
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm password"
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? "input--error"
                        : null
                    }
                  />
                  <span
                    onClick={(e) => handlePasswordVisibility(e)}
                    className={style["toggle__password"]}
                  >
                    {showPassword.id === "confirm_password" ? (
                      <img
                        id="confirm_password"
                        src={eyeOpen}
                        alt="eyeOpen"
                      ></img>
                    ) : (
                      <img
                        id="confirm_password"
                        src={eyeClose}
                        alt="eyeClose"
                      ></img>
                    )}
                  </span>
                  <ErrorMessage
                    name="confirmPassword"
                    component="span"
                    className="error"
                  />
                </FormRowGroup> */}

                <Button
                  type="submit"
                  className={!(dirty && isValid) ? "faint" : "filled"}
                  disabled={!(dirty && isValid)}
                  onClick={() => handleSubmit()}
                >
                  {!isLoading ? (
                    "Reset my Password"
                  ) : (
                    <ScaleLoader
                      css={override}
                      size={80}
                      color="#fff"
                      loading={true}
                    />
                  )}{" "}
                </Button>
                <p className={style["password__hint"]}>
                  *Password should contain uppercase and lowercase letters,
                  numbers and one of ‘#, $, !, *, ...’
                </p>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const PasswordResetSuccess = () => {
  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };
  return (
    <div className={style["reset--success"]}>
      <img src={SuccessIcon} alt="success" />
      <form>
        <h3>Password Reset Successful</h3>
        <p>Your password has been reset and your account is now secure</p>
        <Button type="button" className="filled" onClick={() => login()}>
          Go to Log In
        </Button>
      </form>
    </div>
  );
};

export default PasswordReset;
