/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import Button from "../../../components/Button";
import Dialog from "../../../components/Dialog";
import Layout from "../../../components/Layout/AdminDashboardLayout";

import { Pagination } from "../../../components/Pagination";
import style from "./index.module.scss";

const Cards = ({ userInfo }) => {
  const cards = [
    {
      description: "Pending Request",
      amt: `${userInfo ? userInfo.pending : "..."}`,
    },
    {
      description: "Active Sender ID",
      amt: `${userInfo ? userInfo.active : "..."}`,
    },
    {
      description: "Total Sender ID",
      amt: `${userInfo ? userInfo.total : "..."}`,
    },
  ];

  return (
    <div>
      <ul className={style["cards--container"]}>
        {cards.map((item) => (
          <li className={style["cards--item"]}>
            <header>
              <h2>{item.amt}</h2>
              <p>{item.description}</p>
            </header>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ActionCard = ({ handleDelete, handleApproval, handleRejection }) => {
  return (
    <div className={style["action--dialog"]}>
      <div>
        <p className={style["approved--action"]} onClick={handleApproval}>
          <span style={{ marginRight: "1rem" }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                style={{ fill: "#fff" }}
                d="M7 10L9 12L13 8M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                stroke="#219681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          Approved
        </p>
      </div>
      <hr className={style["divider"]} />
      <div>
        <p className={style["reject--action"]} onClick={handleRejection}>
          <span style={{ marginRight: "1rem" }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                style={{ fill: "#FED555" }}
                d="M10 0.25C4.624 0.25 0.25 4.624 0.25 10C0.25 15.376 4.624 19.75 10 19.75C15.376 19.75 19.75 15.376 19.75 10C19.75 4.624 15.376 0.25 10 0.25ZM10 1.75C14.5653 1.75 18.25 5.43475 18.25 10C18.25 14.5653 14.5653 18.25 10 18.25C5.43475 18.25 1.75 14.5653 1.75 10C1.75 5.43475 5.43475 1.75 10 1.75ZM7.165 6.085L6.085 7.165L8.923 10L6.0865 12.835L7.1665 13.915L10 11.0778L12.835 13.9127L13.915 12.835L11.0778 10L13.9127 7.165L12.835 6.085L10 8.923L7.165 6.0865V6.085Z"
                fill="#FED555"
              />
            </svg>
          </span>
          Reject
        </p>
      </div>
      <hr className={style["second-divider"]} />
      <div>
        <p className={style["delete--action"]} onClick={handleDelete}>
          <span style={{ marginRight: "1rem" }}>
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                style={{ fill: "#fff" }}
                d="M7 9V15M11 9V15M1 5H17M16 5L15.133 17.142C15.0971 17.6466 14.8713 18.1188 14.5011 18.4636C14.1309 18.8083 13.6439 19 13.138 19H4.862C4.35614 19 3.86907 18.8083 3.49889 18.4636C3.1287 18.1188 2.90292 17.6466 2.867 17.142L2 5H16ZM12 5V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1H7C6.73478 1 6.48043 1.10536 6.29289 1.29289C6.10536 1.48043 6 1.73478 6 2V5H12Z"
                stroke="#EB2931"
                stroke-width="2"
                stroke-linecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          Delete
        </p>
      </div>
    </div>
  );
};

const DeleteDialog = ({ handleDelete }) => {
  return (
    <div className={style["delete"]}>
      <p className={style["delete--action"]} onClick={handleDelete}>
        <span style={{ marginRight: "1rem" }}>
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              style={{ fill: "#fff" }}
              d="M7 9V15M11 9V15M1 5H17M16 5L15.133 17.142C15.0971 17.6466 14.8713 18.1188 14.5011 18.4636C14.1309 18.8083 13.6439 19 13.138 19H4.862C4.35614 19 3.86907 18.8083 3.49889 18.4636C3.1287 18.1188 2.90292 17.6466 2.867 17.142L2 5H16ZM12 5V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1H7C6.73478 1 6.48043 1.10536 6.29289 1.29289C6.10536 1.48043 6 1.73478 6 2V5H12Z"
              stroke="#EB2931"
              stroke-width="2"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        Delete
      </p>
    </div>
  );
};
const SenderIDApproval = () => {
  const [senderIds, setSenderIds] = useState(null);
  const [notFound, setNotFound] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedId, setSelectedId] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const toggling = (index) => {
    setIsOpen(!isOpen);
    setSelectedId(index);
  };

  const { token } = useSelector((state) => state.auth);

  const config = {
    headers: {
      Authorization: "Bearer dW5kZWZpbmVkOnVuZGVmaW5lZA==",
      Accept: "application/json, text/plain, */*",
    },
  };
  const th = [
    {
      name: "S/N",
    },
    {
      name: "Sender ID",
    },
    {
      name: "Descriptions",
    },
    {
      name: "Status",
    },
    {
      name: "Date",
    },
    {
      name: "",
    },
  ];

  const fetchSenderIDs = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/senderid?access_token=${token}`,
        config
      );
      if (response.status === 200) {
        setSenderIds(response.data);
      } else {
        setSenderIds(response.data);
      }
    } catch (err) {
      setNotFound("No sender IDs found yet");
    }
  };

  const approveSenderID = async (id) => {
    setIsLoading(true);
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/senderid/dev/change/${id}`,
        {
          status: "verified",
          access_token: token,
        },
        config
      );
      if (res.status) {
        fetchSenderIDs();
        setIsOpen(!isOpen);
      } else {
        console.log(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDeleteDialog = (id) => {
    setDeleteModalOpen(true);
    setSelectedId(id);
  };

  const handleRejectDialog = (id) => {
    setRejectModalOpen(true);
    setSelectedId(id);
  };

  const deleteSenderID = async () => {
    setIsLoading(true);
    setDeleteModalOpen(!deleteModalOpen);
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/senderid/dev/${selectedId}/delete?access_token=${token}`,
        config
      );

      if (res.status) {
        setIsOpen(!isOpen);

        setConfirmModalOpen(true);
      } else {
        console.log(res);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const rejectSenderID = async () => {
    setIsLoading(true);
    setRejectModalOpen(!rejectModalOpen);
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/senderid/dev/change/${selectedId}`,
        {
          status: "rejected",
          access_token: token,
        },
        config
      );

      if (res.status) {
        setIsOpen(!isOpen);
        setConfirmModalOpen(true);
      } else {
        console.log(res);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getSenderIds = async () => {
      setIsLoading(true);

      try {
        let response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/senderid?access_token=${token}`,
          config
        );
        if (response.status === 200) {
          setSenderIds(response.data);
        } else {
          setSenderIds(response.data);
        }
        setIsLoading(false);
      } catch (err) {
        setNotFound("No sender IDs found yet");
      }
    };

    getSenderIds();
  }, []);

  const confirmDialog = () => {
    fetchSenderIDs();
    setConfirmModalOpen(!confirmModalOpen);
  };

  return (
    <Layout pageTitle={"Sender ID Approval"}>
      <Dialog isOpen={isLoading}>
        <ScaleLoader />
      </Dialog>
      <Dialog
        isOpen={rejectModalOpen}
        toggleModal={() => setRejectModalOpen(!rejectModalOpen)}
      >
        <div className={style.deleteModal}>
          <p>Are You Sure you want to Reject This ID?</p>
          <div className={style.buttons}>
            <div>
              <Button
                type="submit"
                className="medium--filled"
                onClick={rejectSenderID}
              >
                Yes
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                className="medium--ghost"
                onClick={() => {
                  setIsOpen(!isOpen);
                  setRejectModalOpen(!rejectModalOpen);
                }}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        isOpen={deleteModalOpen}
        toggleModal={() => setDeleteModalOpen(!deleteModalOpen)}
      >
        <div className={style.deleteModal}>
          <p>Are You Sure you want to Delete This ID?</p>
          <div className={style.buttons}>
            <div>
              <Button
                type="submit"
                className="medium--filled"
                onClick={deleteSenderID}
              >
                Yes
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                className="medium--ghost"
                onClick={() => {
                  setIsOpen(!isOpen);
                  setDeleteModalOpen(!deleteModalOpen);
                }}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        isOpen={confirmModalOpen}
        toggleModal={() => setConfirmModalOpen(!confirmModalOpen)}
      >
        <div className={style.confirm}>
          <p>Sender ID Has Been {deleteModalOpen ? "deleted" : "rejected"}.</p>
          <div>
            <Button className="small--filled" onClick={confirmDialog}>
              Back to Homepage
            </Button>
          </div>
        </div>
      </Dialog>

      <div style={{ padding: "2rem" }}>
        <Cards
          userInfo={
            senderIds && {
              pending: senderIds?.pending ? senderIds?.pending : "...",
              active: senderIds?.active ? senderIds?.active : "...",
              total: senderIds?.total,
            }
          }
        />

        <div className={style["table--top"]}>
          <p style={{ position: "relative", top: "30px" }}>Recent Sender ID</p>
          <div className={style["filter--section"]}>
            <div className={style["search--bar"]}>
              <input type="text" placeholder="Search" />

              <span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    style={{ fill: "white" }}
                    d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    strokeLinejoin="round"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
            <Button type="buttom" className="filter--button">
              <span style={{ paddingRight: ".5rem" }}>
                <svg
                  width="14"
                  height="12"
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    style={{ fill: "#BDBDBD" }}
                    d="M0.128568 2.26839L2.31563 0.125478C2.48647 -0.0418526 2.7636 -0.0417991 2.93434 0.125478L5.12132 2.26839C5.39667 2.53812 5.20059 3.00001 4.81195 3.00001H3.5V11.5714C3.5 11.8081 3.30414 12 3.0625 12H2.1875C1.94586 12 1.75 11.8081 1.75 11.5714V3.00001H0.437935C0.0485329 3.00001 -0.146209 2.53758 0.128568 2.26839ZM6.5625 1.7143H13.5625C13.8041 1.7143 14 1.52243 14 1.28573V0.428584C14 0.191879 13.8041 1.34454e-05 13.5625 1.34454e-05H6.5625C6.32086 1.34454e-05 6.125 0.191879 6.125 0.428584V1.28573C6.125 1.52243 6.32086 1.7143 6.5625 1.7143ZM6.125 4.71429V3.85715C6.125 3.62045 6.32086 3.42858 6.5625 3.42858H11.8125C12.0541 3.42858 12.25 3.62045 12.25 3.85715V4.71429C12.25 4.951 12.0541 5.14287 11.8125 5.14287H6.5625C6.32086 5.14287 6.125 4.951 6.125 4.71429ZM6.125 11.5714V10.7143C6.125 10.4776 6.32086 10.2857 6.5625 10.2857H8.3125C8.55414 10.2857 8.75 10.4776 8.75 10.7143V11.5714C8.75 11.8081 8.55414 12 8.3125 12H6.5625C6.32086 12 6.125 11.8081 6.125 11.5714ZM6.125 8.14286V7.28572C6.125 7.04901 6.32086 6.85715 6.5625 6.85715H10.0625C10.3041 6.85715 10.5 7.04901 10.5 7.28572V8.14286C10.5 8.37957 10.3041 8.57143 10.0625 8.57143H6.5625C6.32086 8.57143 6.125 8.37957 6.125 8.14286Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </span>
              Sort By Date
              <span style={{ paddingLeft: "1rem", cursor: "pointer" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    style={{ fill: "white" }}
                    d="M6 5V1M14 5V1M5 9H15M3 19H17C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Button>
          </div>
        </div>
        <div className={style["table--container"]}>
          <table rules="group">
            {" "}
            <thead>
              <tr className={style["table--head"]}>
                {th.map((item, index) => {
                  return (
                    <>
                      <th key={index}>
                        {item.name}

                        {item.name === "Status" ? (
                          <svg
                            style={{ marginLeft: ".3rem", cursor: "pointer" }}
                            width="14"
                            height="12"
                            viewBox="0 0 14 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              style={{
                                fill: " #FFFFFF",
                                marginLeft: ".3rem",
                              }}
                              d="M0.128568 2.26839L2.31563 0.125478C2.48647 -0.0418526 2.7636 -0.0417991 2.93434 0.125478L5.12132 2.26839C5.39667 2.53812 5.20059 3.00001 4.81195 3.00001H3.5V11.5714C3.5 11.8081 3.30414 12 3.0625 12H2.1875C1.94586 12 1.75 11.8081 1.75 11.5714V3.00001H0.437935C0.0485329 3.00001 -0.146209 2.53758 0.128568 2.26839ZM6.5625 1.7143H13.5625C13.8041 1.7143 14 1.52243 14 1.28573V0.428584C14 0.191879 13.8041 1.34454e-05 13.5625 1.34454e-05H6.5625C6.32086 1.34454e-05 6.125 0.191879 6.125 0.428584V1.28573C6.125 1.52243 6.32086 1.7143 6.5625 1.7143ZM6.125 4.71429V3.85715C6.125 3.62045 6.32086 3.42858 6.5625 3.42858H11.8125C12.0541 3.42858 12.25 3.62045 12.25 3.85715V4.71429C12.25 4.951 12.0541 5.14287 11.8125 5.14287H6.5625C6.32086 5.14287 6.125 4.951 6.125 4.71429ZM6.125 11.5714V10.7143C6.125 10.4776 6.32086 10.2857 6.5625 10.2857H8.3125C8.55414 10.2857 8.75 10.4776 8.75 10.7143V11.5714C8.75 11.8081 8.55414 12 8.3125 12H6.5625C6.32086 12 6.125 11.8081 6.125 11.5714ZM6.125 8.14286V7.28572C6.125 7.04901 6.32086 6.85715 6.5625 6.85715H10.0625C10.3041 6.85715 10.5 7.04901 10.5 7.28572V8.14286C10.5 8.37957 10.3041 8.57143 10.0625 8.57143H6.5625C6.32086 8.57143 6.125 8.37957 6.125 8.14286Z"
                              fill="#FFFFFF"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                      </th>
                    </>
                  );
                })}
              </tr>
            </thead>
            {notFound ? (
              <div>
                <h4>No sender IDs found yet.</h4>
              </div>
            ) : (
              <tbody>
                {senderIds &&
                  senderIds.data.map((data, index) => (
                    <tr className={style["table--body"]} key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.sender_id}</td>
                      <td>{data?.description}</td>
                      <td>
                        <span
                          className={
                            data?.status.toLowerCase() === "rejected"
                              ? style["status--pending"]
                              : data?.status.toLowerCase() === "new"
                              ? style["status--new"]
                              : style["status--confirmed"]
                          }
                        >
                          {data?.status}
                        </span>
                      </td>
                      <td>{dayjs(data?.created_at).format("DD-MMM-YYYY")}</td>
                      <td>
                        <div>
                          {" "}
                          <svg
                            onClick={() => toggling(data?.id)}
                            style={{ cursor: "pointer" }}
                            width="4"
                            height="16"
                            viewBox="0 0 4 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4C1.46957 4 0.96086 3.78929 0.585787 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585787 0.585786C0.96086 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4ZM2 10C1.46957 10 0.96086 9.78929 0.585787 9.41421C0.210714 9.03914 0 8.53043 0 8C0 7.46957 0.210714 6.96086 0.585787 6.58579C0.96086 6.21071 1.46957 6 2 6C2.53043 6 3.03914 6.21071 3.41421 6.58579C3.78929 6.96086 4 7.46957 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10ZM2 16C1.46957 16 0.96086 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14C0 13.4696 0.210714 12.9609 0.585787 12.5858C0.96086 12.2107 1.46957 12 2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14C4 14.5304 3.78929 15.0391 3.41421 15.4142C3.03914 15.7893 2.53043 16 2 16Z"
                              fill="black"
                            />
                          </svg>
                          {isOpen &&
                            data?.id === selectedId &&
                            (data.status.toLowerCase() === "new" ||
                            data.status.toLowerCase() === "pending" ? (
                              <ActionCard
                                handleApproval={() => approveSenderID(data?.id)}
                                handleDelete={() =>
                                  handleDeleteDialog(data?.id)
                                }
                                handleRejection={() =>
                                  handleRejectDialog(data?.id)
                                }
                              />
                            ) : (
                              <DeleteDialog
                                handleDelete={() =>
                                  handleDeleteDialog(data?.id)
                                }
                              />
                            ))}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
        <div className={style["paginate"]}>
          {senderIds && !notFound && (
            <Pagination
              numberOfPages={senderIds.total / 15}
              currentPage={senderIds.current_page}
              nextPage={senderIds.to}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SenderIDApproval;
