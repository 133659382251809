import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import style from "./index.module.scss";

const Privacy = () => {
  const pageTop = React.useRef(null);

  React.useEffect(() => {
    pageTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <div ref={pageTop}></div>
      <Header bgStyle="privacy__page--bg" theme="darkTheme">
        <div></div>
      </Header>
      <div className={style["privacy"]}>
        <div className={style["container"]}>
          <h3>Privacy Policy</h3>
          <h4 className={style["title"]}>
            Get the most powerful and secure online experience
          </h4>
          <p className={style["text"]}>
            At MSA, we are committed to protecting your privacy and providing
            you with the most powerful and secure online experience. You can
            travel through most of this website without giving us any
            information about yourself. But sometimes we do collect information
            to provide services and/or information that you request, and this
            statement of privacy (this “Privacy Policy”) explains data
            collection and use practices of MSA in those situations. Please read
            this Privacy Policy.
          </p>

          <p className={style["subtitle"]}>Acceptance</p>
          <p className={style["text"]}>
            By accessing and using the Site, you accept and agree to this
            Privacy Notice. If you do not agree to this Privacy Notice, please
            do not use the Site
          </p>
          <p className={style["subtitle"]}>Information We Collect about You</p>
          <p className={style["text"]}>
            {" "}
            MSA is committed to protecting your privacy. So you should be aware
            that we may collect information about you through your use of our
            Site. Some of the information we collect may personally identify
            you. For example:
          </p>
          <ul>
            <li>
              You may provide registration information, such as your first and
              last name, email address, phone number, and country, when using
              the Site to register an account, or try out the demo. When
              registering an account, we may store your registration information
              along with any information you have provided during previous
              visits as part of your personal profile.{" "}
            </li>
            <li>
              You may provide transaction information when using the Site (e.g.,
              funding wallet), including your name, and payment information.
            </li>
          </ul>

          <p className={style["subtitle"]}>Use of Your Information</p>
          <p className={style["text"]}>
            By using the Site, you consent to our collection of your
            information. We may use your information for the following purposes:
          </p>
          <ul>
            <li>
              To proceed with your registration and to make the Site easier for
              you to use by not requiring you to enter your information more
              than once;
            </li>
            <li>
              To deliver services or products that you request, purchase, or
              demo
            </li>
            <li>
              To notify you of product upgrades, special offers, updated
              information, and new services via email, phone, chat or other
              means
            </li>
            <li>
              To comply with legal requirements and to prevent activities that
              may violate our policies.
            </li>
          </ul>
          <p className={style["subtitle"]}>
            By using the Site, you consent to our use of your information for
            these purposes. Sharing of Your Information
          </p>
          <p className={style["text"]}>
            We will not disclose your personal information outside of MSA except
            in limited circumstances, such as:{" "}
          </p>
          <ul>
            <li>
              {" "}
              We may disclose your personal information if required to do so by
              law or in the good faith belief that such action is necessary (i)
              to conform to the edicts of the law or comply with legal process
              served on us, (ii) to protect and defend the rights or property of
              MSA, or (iii) to act in exigent circumstances to protect the
              personal safety of our users or the public.
            </li>
            <li>
              In the event that MSA, or any portion thereof, is sold,
              transferred, merged, financed, or dissolved, in whole or in part,
              your personal information that we may possess may be sold,
              transferred, or otherwise disclosed as part of the transaction or
              proceeding, unless you instruct us not to do so.
            </li>
          </ul>
          <p className={style["subtitle"]}>Control of Your Information</p>
          <p className={style["text"]}>
            When you provide personal information to us, we will not share that
            information with third parties without your permission, other than
            in circumstances referred to above. We may send out important
            notices informing you of changes to our policies, technical service
            issues related to a product or service, or other information that is
            essential to the use of our Site, services, and products.
          </p>
          <p className={style["subtitle"]}>Access to Your Information</p>
          <p className={style["text"]}>
            We make good faith efforts to provide you with access so you can
            edit or update your personal information. You may have the ability
            to view or edit some of your personal information online
          </p>
          <p className={style["subtitle"]}>Security of Your Information</p>
          <p className={style["text"]}>
            We are committed to protecting the security of your personal
            information and we honor your choices for its intended use. We have
            implemented the appropriate safeguards to protect your data from
            loss, misuse, unauthorized access or disclosure, alteration, or
            destruction. For example, your personal information is stored in
            password-controlled servers with limited access. We make good faith
            efforts to provide the security measures necessary to keep the
            integrity and confidentiality of your information safe. However, no
            system can be completely secure and we do not guarantee that our
            security measures are impenetrable.
            <br />
            You also have a significant role in protecting your information. As
            an example, you should not share your password with anyone. If you
            believe that your password has been compromised, you should
            immediately change your password.
          </p>
          <p className={style["subtitle"]}>Cookies and Other Mechanisms</p>
          <p className={style["text"]}>
            A cookie is placed on your machine (if you accept cookies) or is
            read if you have visited our Site previously. All of this is done in
            accordance with applicable laws. You can choose to have your
            computer warn you each time a cookie is being sent, or you can
            choose to turn off all cookies. You do this through your browser
            (like Internet Explorer, Google Chrome) settings. Each browser is a
            little different, so look at your browser’s Help menu to learn the
            correct way to modify your cookies. To ensure we are creating and
            publishing relevant content, we may use information collected from
            cookies and other mechanisms. We may use this information in
            aggregated form and use site-visitation statistics to improve our
            sites. If you choose to not have your browser accept cookies from
            our Site, you will be able to view the text on the screens, however,
            you will not experience a personalized visit. Of course, any data
            collected will be used in accordance with our own Privacy Notice and
            Google’s Privacy Notice. Google Analytics uses cookies to help us
            analyze how our websites are used, including the number of visitors,
            the websites from which visitors have navigated to our websites, and
            the pages on our websites to which visitors navigate. This
            information is used by us to improve our websites.
          </p>
          <p className={style["subtitle"]}>
            Modifications to the Privacy Policy
          </p>
          <p className={style["text"]}>
            MSA may update and make changes to the Privacy Notice at any time.
            When we do so, we will also revise the date at the bottom of the
            first page of the Privacy Notice. When we make material changes to
            the Privacy Notice, we will notify you by placing prominent notice
            on the Site.
          </p>
          <p className={style["subtitle"]}>Children’s Information</p>
          <p className={style["text"]}>
            The Site is not intended for use by individuals under the age of 13.
            We do not knowingly collect personal data from nor send requests for
            personal data to individuals under the age of 13.
          </p>
          <p className={style["text"]}>Last Updated: 9th of November, 2021.</p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Privacy;
