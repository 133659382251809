import React from "react";
import WalletHistory from "../Wallets/History";

export default function UsageReports() {
  return (
    // <Layout>
    //   <main className={style["main"]}>
    //     <div>
    //       <img src={Construction} alt="under construction" />
    //     </div>
    //     <h4>Under Construction</h4>
    //     <p>It’s under construction now, but it won’t always be forever.</p>
    //   </main>
    // </Layout>
    <WalletHistory />
  );
}
