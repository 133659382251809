import React from "react";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import style from "./index.module.scss";

const DemoOverview = () => {
  const mouseEnter = () => {
    let vid = document.getElementById("video");
    vid.onmouseenter = function () {
      vid.setAttribute("controls", "controls");
    };
  };
  return (
    <div>
      <Header theme="darkTheme">
        <div className={style["header__details"]}>
          <div className={style["demo-overview"]}>
            <div className={style["text"]}>
              <h3>Peep the Future of Customer Engagement in Africa</h3>
              <p>
                MyServiceAgent helps businesses engage customers better through
                voice-based solutions. See an overview of our services below
              </p>
            </div>
            <div className={style["column"]}>
              <video
                onMouseEnter={mouseEnter}
                id="video"
                height="580"
                width="auto"
                // className={style["column"]}
                autoPlay={true}
                muted={true}
                controlsList="nodownload"
              >
                <source src="https://res.cloudinary.com/myserviceagent/video/upload/v1644352847/Product_Demo_1_ucjh5q.mp4" />
              </video>
            </div>
          </div>
        </div>
      </Header>
      <Footer />
    </div>
  );
};

export default DemoOverview;
