import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import Button from "../../../components/Button";
import AuthLayout from "../../../components/Layout/AuthLayout";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as authActions from "../../../redux/actions/authActions";
import * as messageActions from "../../../redux/actions/messageActions";
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import style from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FormRowGroup } from "../../../components/Form-row";

export default function ForgotPassword() {
  const [isOpen, setIsOpen] = React.useState(false);

  const notification = useSelector((state) => state.message);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsOpen(!isOpen);
    navigate("/login");
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  React.useEffect(() => {
    return () => {
      dispatch(messageActions.clearErrorMessage());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      {notification.success ? (
        <div className={style["sent"]}>
          {" "}
          <h3>Password Sent!</h3>
          <p>
            Please check your email for instructions on how you can retrieve
            your password.
          </p>
          <Button
            type="button"
            className="filled"
            onClick={() => toggleModal()}
          >
            Continue
          </Button>
        </div>
      ) : (
        <FormComponent
          override={override}
          dispatch={dispatch}
          isLoading={isLoading}
          notification={notification}
        />
      )}
    </AuthLayout>
  );
}

const FormComponent = ({ notification, isLoading, override, dispatch }) => {
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .email("Email address is invalid.")
      .required("Email is required."),
  });

  return (
    <div className={style["container"]}>
      <div className={style["forgot--password"]}>
        <h3>Forgot Password</h3>
        <p>
          Enter the email address associated with your account to receive a
          reset link.
        </p>

        <ul className={"signup__errors"}>
          {notification.error &&
            Object.values(notification.msg.error).map((item) => (
              <li>{item}</li>
            ))}
        </ul>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={forgotPasswordSchema}
          onSubmit={({ email }) => {
            const formData = new FormData();
            formData.append("email", email);

            dispatch(authActions.forgotPassword(formData));
          }}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty, handleSubmit } = formik;
            return (
              <Form>
                <FormRowGroup>
                  <label htmlFor="email">Email address*</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter email address"
                    className={
                      errors.email && touched.email ? "input--error" : null
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="error"
                  />
                </FormRowGroup>

                <Button
                  type="submit"
                  className={!(dirty && isValid) ? "faint" : "filled"}
                  disabled={!(dirty && isValid)}
                  onClick={handleSubmit}
                >
                  {!isLoading ? (
                    "Send Reset Link"
                  ) : (
                    <ScaleLoader
                      css={override}
                      size={80}
                      color="#fff"
                      loading={true}
                    />
                  )}{" "}
                </Button>
              </Form>
            );
          }}
        </Formik>

        <Link to="/login">
          <div className={style["bottom--link"]}>
            <p>Back to Log in</p>
          </div>
        </Link>
      </div>
    </div>
  );
};
