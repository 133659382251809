import React from 'react'
import Layout from '../../../../components/Layout/DashboardLayout'
import WalletLayout from '../../../../components/Layout/WalletLayout'

export default function WalletAlerts() {
    return (
        <Layout pageTitle="Wallets">
        <WalletLayout>
  
            hellp
        </WalletLayout>
      </Layout>
    )
}
