import React from 'react'
import { useDispatch } from 'react-redux';
import { getHistoryByDate, getPurchaseHistory } from '../redux/actions/paymentActions';

export default function usePagination(role, item) {

    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = React.useState(1);
    const token = sessionStorage.getItem("msa_jwt");
    const id = JSON.parse(sessionStorage.getItem("msa_user_data")).id
    

    const formData = new FormData();

    function nextPage(pageNumber) {
        setCurrentPage(pageNumber);
        fetchPayments();
    }
   



    const fetchPayments = () => {

        formData.append("client_id", id);
        formData.append("token", token);
        formData.append("page", currentPage);

        if ("filter" === true) {
            dispatch(getHistoryByDate(formData));
        } else {
            getPurchaseHistory();
    
        }

        role === "admin" ? dispatch(item(token, currentPage)) : dispatch(getPurchaseHistory(formData, currentPage));

    };


    React.useEffect(() => {
        fetchPayments();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])


    return { currentPage, nextPage };
}
