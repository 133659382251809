import React from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "../Button";
import style from "./index.module.scss";
import CountryData from "../../utils/JSON/countries.json";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "../../utils/axios-base";
import { css } from "@emotion/react";
import Modal from "../Modal";
import { FormRowCheckbox, FormRowGroup } from "../Form-row/index";
import { useLocation } from "react-router-dom";

const ContactForm = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (fields) => {
    const msgTemplate = `

      NAME :- ${fields.firstname} ${fields.lastname} \n
      COUNTRY :- ${fields.country} \n
      JOBTITLE :- ${fields.jobtitle} \n
      COMPANY :- ${fields.company} \n

      ${fields.message}
    `;
    const data = {
      email: fields.email,
      subject: "MyServiceAgent - Contact Form",
      message: msgTemplate,
      admin_message: msgTemplate,
    };

    //I think an email template should be created for this on the back-end due to the formatting issues,
    // but if you have a better solution feel free to implement.

    const config = {
      headers: {
        Accept: "application/json, text/plain, */*",
      },
    };

    setLoading(true);

    axios
      .post("/users/message/send", data, config)
      .then((res) => {
        setLoading(false);
        setIsOpen(true);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const contactFormSchema = Yup.object().shape({
    firstname: Yup.string().required("Firstname is required."),

    lastname: Yup.string().required("Lastname is required."),

    email: Yup.string().required("Email is required").email("Email is Invalid"),

    company: Yup.string().required("Company Name is required"),

    country: Yup.string().required("Country is required i.e Nigeria"),

    message: Yup.string().required(
      "Please Enter the content of your Message (Required)"
    ),
  });

  const ominichannelFormSchema = Yup.object().shape({
    firstname: Yup.string().required("Firstname is required."),

    lastname: Yup.string().required("Lastname is required."),

    email: Yup.string().required("Email is required").email("Email is Invalid"),

    company: Yup.string().required("Company Name is required"),

    channel: Yup.string().required(
      "Please select the channel of your Message (Required)"
    ),
  });

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    company: "",
    country: "Nigeria",
    message: "",
  };

  const initialValuesOminichannel = {
    firstname: "",
    lastname: "",
    email: "",
    company: "",
    channel: [],
  };

  return (
    <>
      <div className={style["contact__box"]}>
        <div className={style["container"]}>
          <div className={style["container--left"]}>
            <h1>Contact Us</h1>
            {/* <p>
              Interested in learning about how MSA’s identity and engagement
              solutions can prevent fraud while fostering secure and global
              growth for your business? Let's chat.
            </p> */}
            <>
              <Modal isOpen={isOpen} toggleModal={handleToggle}>
                <h3>Message Delivered Successfully</h3>
                <p>We will be in touch with you.</p>

                <Button
                  className="large--filled"
                  onClick={() => handleToggle()}
                >
                  Okay
                </Button>
              </Modal>

              <Formik
                initialValues={
                  location.pathname.includes("ominichannel")
                    ? initialValuesOminichannel
                    : initialValues
                }
                validationSchema={
                  location.pathname.includes("ominichannel")
                    ? ominichannelFormSchema
                    : contactFormSchema
                }
                onSubmit={(values) => {
                  if (location.pathname.includes("ominichannel")) {
                    const payload = {
                      firstname: values.firstname,
                      lastname: values.lastname,
                      email: values.email,
                      company: values.company,
                      message: values.channel.toString(),
                    };

                    handleSubmit(payload);
                    return;
                  }
                  handleSubmit(values);
                }}
              >
                {({ errors, touched, isValid, dirty, values }) => {
                  return (
                    <Form>
                      <div className={style["form__container--row"]}>
                        <div>
                          <label style={{ color: "#000" }} htmlFor="firstname">
                            First Name*
                          </label>
                          <Field
                            type="text"
                            name="firstname"
                            id="firstname"
                            placeholder="e.g Peter"
                            className={
                              errors.firstname && touched.firstname
                                ? "input--error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="firstname"
                            component="span"
                            className="error"
                          />
                        </div>

                        <div>
                          <label style={{ color: "#000" }} htmlFor="lastname">
                            Last Name*
                          </label>
                          <Field
                            type="text"
                            name="lastname"
                            id="lastname"
                            placeholder="e.g Pan"
                            className={
                              errors.lastname && touched.lastname
                                ? "input--error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="lastname"
                            component="span"
                            className="error"
                          />
                        </div>
                      </div>

                      <label style={{ color: "#000" }} htmlFor="email">
                        Email address
                      </label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        placeholder="name@example.com"
                        className={
                          errors.email && touched.email ? "input--error" : null
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="error"
                      />

                      <label style={{ color: "#000" }} htmlFor="company">
                        Company Name
                      </label>
                      <Field
                        type="text"
                        name="company"
                        id="company"
                        placeholder="e.g Acme Corp. Ltd"
                        className={
                          errors.company && touched.company
                            ? "input--error"
                            : null
                        }
                      />
                      <ErrorMessage
                        name="company"
                        component="span"
                        className="error"
                      />

                      {!location.pathname.includes("ominichannel") && (
                        <>
                          {" "}
                          <label style={{ color: "#000" }} htmlFor="country">
                            Country
                          </label>
                          <select
                            name="country"
                            as="select"
                            id=""
                            defaultValue={"Nigeria"}
                            style={{ width: "100%" }}
                          >
                            {CountryData.map((country, id) => (
                              <option value={country.name.common} key={id}>
                                {country.flag} {country.name.common}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage
                            name="country"
                            component="span"
                            className="error"
                          />
                          <FormRowGroup>
                            <label style={{ color: "#000" }} htmlFor="message">
                              How can we help you ?
                            </label>
                            <Field
                              name="message"
                              as="textarea"
                              id="message"
                              rows="4"
                              placeholder="Leave us a message..."
                            ></Field>
                            <ErrorMessage
                              name="message"
                              component="span"
                              className="error"
                            />
                          </FormRowGroup>
                        </>
                      )}

                      {location.pathname.includes("ominichannel") && (
                        <>
                          <label style={{ color: "#000" }} htmlFor="channel">
                            Preferred Communication Channel
                          </label>
                          <FormRowCheckbox>
                            <Field
                              type="checkbox"
                              value="chatbots"
                              name="channel"
                              className={
                                errors.channel && touched.channel
                                  ? "input--error"
                                  : null
                              }
                            />
                            <label
                              className={
                                values.channel.includes("chatbots")
                                  ? "is--checked"
                                  : null
                              }
                            >
                              Chatbots
                            </label>
                          </FormRowCheckbox>
                          <FormRowCheckbox>
                            <Field
                              type="checkbox"
                              value="email"
                              name="channel"
                            />
                            <label
                              className={
                                values.channel.includes("email")
                                  ? "is--checked"
                                  : null
                              }
                            >
                              Emails
                            </label>
                          </FormRowCheckbox>
                          <FormRowCheckbox>
                            <Field type="checkbox" value="ivr" name="channel" />
                            <label
                              className={
                                values.channel.includes("ivr")
                                  ? "is--checked"
                                  : null
                              }
                            >
                              Interactive Voice Response (IVR)
                            </label>
                          </FormRowCheckbox>
                          <FormRowCheckbox>
                            <Field
                              type="checkbox"
                              value="social_media"
                              name="channel"
                            />
                            <label
                              className={
                                values.channel.includes("social_media")
                                  ? "is--checked"
                                  : null
                              }
                            >
                              Social Media
                            </label>
                          </FormRowCheckbox>
                          <ErrorMessage
                            name="channel"
                            component="span"
                            className="error"
                          />
                        </>
                      )}

                      <Button
                        type="submit"
                        className={!dirty || !isValid ? "faint" : "filled"}
                        disabled={!dirty && !isValid}
                      >
                        {!loading ? (
                          "Send Message"
                        ) : (
                          <ScaleLoader
                            css={override}
                            size={80}
                            color={"#fff"}
                            loading={true}
                          />
                        )}{" "}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </>
          </div>
          <div className={style["container--right"]}>
            <div className={style["contact__call"]}>
              {/* <p className={style["contact__call--text"]}>Send us a message</p> */}
              {/* <div>

            <a className={style["contact__number"]}>hello@myserviceagent.net</a>
            </div> */}
              <p className={style["contact__number"]}>
                or send us a mail at
                <a href="mailto:hello@myserviceagent.net">
                  hello@myserviceagent.net
                </a>
                or call us on
                <a href="tel:+2348026299730">0802 629 9730</a> |
                <a href="tel:012701845">012701845</a>
              </p>
            </div>
            {/* <div className={style["contact__img"]}>
              <img src={contactImg} alt="contact" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
