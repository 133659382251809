import React from "react";
import style from "./index.module.scss";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import open from "../../../assets/images/open.svg";
import close from "../../../assets/images/close.svg";
import SubscribeForm from "../../../components/SubscribeForm";
import CheckBox from "../../../assets/images/check.png";

const BusinessMessaging = () => {
  const pageTop = React.useRef(null);

  const [showAnswer, setAnswer] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  // function scrollDown(){
  //     faqsTop.current.scrollIntoView({behavior: "smooth"});
  // }

  function handleToggle(index) {
    const ansArr = [false, false, false, false, false];
    if (showAnswer[index]) {
      setAnswer(ansArr);
    } else {
      ansArr[index] = true;
      setAnswer(ansArr);
    }
  }

  React.useEffect(() => {
    pageTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const lists = [
    "Record messages in your own voice",
    "Send to over 1000 customers simultaneously",
    "Deliver customized and personalized campaign and promotional messages",
    "As a non-developer, you can use MSA business messaging without writing a single line of code",
    "Boost your sales with a more personal and interactive sales service",
    "Boost conversation with a 99.9% secure and efficient business messaging service",
    "Welcome new customers with a personalized call or SMS message",
    "Integrate messaging APIs into your applications with ease",
  ];

  return (
    <>
      <div ref={pageTop}></div>
      <Header bgStyle="business__messaging__page--bg" theme="lightTheme">
        <div className={style["header__details"]}>
          <div>
            <h3>Business Messaging</h3>
            <p className={style["third__header__paragraph"]}>
              Engage your customers better with intelligent voice and SMS
              messaging
            </p>
            <Link to="/sign-up" style={{ display: "flex" }}>
              <Button className="medium--filled">Get Started</Button>
            </Link>
          </div>
        </div>
      </Header>

      <section className={style["section__business--one"]}>
        <div className={style["container"]}>
          <div className={style["container--left"]}>
            <h1 className={style["intro__text--header"]}>
              Why use Voice Business Messaging?
            </h1>
          </div>

          <div className={style["container--right"]}>
            <ul>
              {lists.map((item, index) => (
                <li key={index}>
                  <span>
                    <img src={CheckBox} alt={CheckBox} />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className={style["section__business--two"]}>
        <div className={style["accordion"]}>
          <h3 className={style["accordion__title"]}>
            Answers to all your questions
          </h3>
          {faqs.map((faq, index) => (
            <div
              className={style["accordion__list"]}
              key={index}
              onClick={() => handleToggle(index)}
            >
              <div className={style["header"]}>
                {" "}
                <h3>{faq.question}</h3>
                <img src={showAnswer[index] ? close : open} alt="open" />
              </div>
              {showAnswer[index] && <p>{faq.answer}</p>}
            </div>
          ))}
        </div>
        <div className={style["circle"]}></div>
      </section>

      <SubscribeForm />
      <Footer />
    </>
  );
};

export default BusinessMessaging;

const faqs = [
  {
    question: "  Is it interactive?",
    answer:
      "Yes. Our business messaging can be one way or interactive based on your needs.",
  },
  {
    question: " How can we tell if the message got to the customer or not?",
    answer:
      "The dashboard contains all information regarding who received the message, who didn't and if there was a response.",
  },
  {
    question: "Will all messages that were sent out be delivered?",
    answer:
      "In most cases yes but this is highly dependent on a lot of factors including if the receiver's phone has been switched off",
  },
  {
    question: " Can I schedule the messages ahead of time?",
    answer:
      "Yes. All messages on our platform can be scheduled to your preferred time by the sender and the receiver",
  },
  {
    question:
      "What happens when the message doesn't get delivered? Will there be a call back option?",
    answer:
      "Our AI can automatically reschedule a call back based on sender's request",
  },
  {
    question: "Can I record the message in my voice?",
    answer:
      "Yes, you can easily record a message in your voice and preview it on our platform before sending it out to your audience.",
  },
];
