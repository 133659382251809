import * as types from '../actions/actionTypes';


const initialState = {
  success: false,
  error: false,
  isSending: false,
  data: []
}


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SEND_BUSINESS_MESSAGE_START:
      return {
        isSending: true
      }
    case types.SEND_BUSINESS_MESSAGE_SUCCESS:
      return {
        success: true,
        error: false,
        data: action.data,
        isSending: false
      }

    case types.SEND_BUSINESS_MESSAGE_FAIL:
      return {
        success: false,
        error: true,
        isSending: false
      }
    default:
      return state;
  }
}