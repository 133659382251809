/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import style from "../../styles/RouteStyles/signup.module.scss";
// import { countries } from "../../utils/CountriesJson";
import dialingcodes from "../../utils/JSON/dialing-codes.json";
import * as authActions from "../../redux/actions/authActions";
import * as messageActions from "../../redux/actions/messageActions";
import eyeOpen from "../../assets/icons/eye-open.png";
import eyeClose from "../../assets/icons/eye-close.png";
import * as endpoints from "../../utils/endpoints";
import {
  confirmPasswordValidation as ConfirmPasswordValidation,
  country,
  emailValidation as EmailValidation,
  NameValidation,
  phoneNoValidation,
  DeveloperValidation,
  passwordValidation,
  Terms,
} from "../../utils/validation";
import Button from "../Button";
import Modal from "../Modal";
import { useSelector } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { FormRowCheckbox } from "../Form-row";
import axios from "axios";

// import * as Yup from "yup";

export default function PersonalInfo({ handleNextBtn, ...props }) {
  // form submit handler

  const notification = useSelector((state) => state.message);
  const authState = useSelector((state) => state.auth.data);
  const isLoading = useSelector((state) => state.auth.isLoading);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  // console.log(authState);

  const [values, setValues] = React.useState(props.state);
  const [countryData, setCountryData] = React.useState({
    name: "Nigeria",
    code: "NG",
    callingCode: "234",
  });
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [data, setData] = React.useState(false);

  const toggleModal = () => {
    sessionStorage.setItem("id", authState.user?.id);

    setIsOpen(!isOpen);
  };

  const toggleVerificationModal = () => {
    sessionStorage.setItem("id", authState.user?.id);

    setIsModalOpen(!isModalOpen);
  };

  const handleCountry = (e) => {
    const data = e.target.value;
    setCountryData(JSON.parse(data));

    console.log(JSON.parse(data));
  };

  const state = values.password;

  const validate = {
    firstName: (fname) => NameValidation("firstName", fname),
    lastName: (lname) => NameValidation("lastName", lname),
    email: EmailValidation,
    country: country,
    dialing_code: phoneNoValidation,
    password: passwordValidation,
    confirmPassword: (confirmPassword, state) =>
      ConfirmPasswordValidation(confirmPassword, state),
    software_dev: DeveloperValidation,
    phone_no: phoneNoValidation,
    terms: Terms,
  };

  const handleChange = (evt) => {
    notification.error && props.dispatch(messageActions.clearErrorMessage());

    const { name, value: newValue, type, checked } = evt.target;

    // keep number fields as numbers
    const value =
      type === "number" ? +newValue : type === "checkbox" ? checked : newValue;

    const { [name]: removedError, ...rest } = errors;

    const error = validate[name](value, state);

    // save field values
    setValues({
      ...values,
      [name]: value,
    });

    setErrors({ ...rest, ...(error && { [name]: touched[name] && error }) });

    // was the field modified
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    // validate the form

    const formValidation = Object.keys(values).reduce(
      (acc, key) => {
        const newError = validate[key](values[key], values.password);
        const newTouched = { [key]: true };
        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          },
        };
      },
      {
        errors: { ...errors },
        touched: { ...touched },
      }
    );

    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (
      !Object.values(formValidation.errors).length && // errors object is empty
      Object.values(formValidation.touched).length ===
        Object.values(values).length && // all fields were touched
      Object.values(formValidation.touched).every((t) => t === true) // every touched field is true
    ) {
      const number = `${countryData.callingCode} ${values.phone_no}`;
      const formData = new FormData();
      formData.append("phone_number", number);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("country", countryData.name);
      formData.append("software_developer", values.software_dev);
      formData.append(
        "contact_person",
        `${values.firstName} ${values.lastName}`
      );
      formData.append("accepted_terms", values.terms && "yes");

      props.dispatch(authActions.addUser(formData));
    }

    console.log(values.dialing_code);
  };

  const handleBlur = (evt) => {
    const { name, value } = evt.target;

    // remove whatever error was there previously
    const { [name]: removedError, ...rest } = errors;

    // check for a new error
    const error = validate[name](value, state);

    // // validate the field if the value has been touched
    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error }),
    });
  };

  const handleResend = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const endpoint = endpoints.RESEND_VERIFICATION;

    const { data } = await axios.post(
      `${endpoint}`,
      { email: values.email },
      config
    );

    setData(data);
  };

  React.useEffect(() => {
    if (data && data.status) {
      setIsModalOpen(true);
    }
  }, [data]);

  React.useEffect(() => {
    return () => {
      props.dispatch(messageActions.clearErrorMessage());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultCountry = { name: "Nigeria", code: "NG", callingCode: "234" };

  return (
    <div className={style["container"]}>
      <div className={style["signup"]}>
        <h3>Let’s get you started</h3>
        <div className={style["subheading"]}>
          <div>
            <svg
              width="22"
              height="23"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                style={{ fill: "#fff" }}
                d="M5.85735 14.0373L7.92272 6.96899H8.6057L6.54032 14.0373H5.85735Z"
                fill="white"
              />
              <path
                style={{ fill: "#fff" }}
                d="M9.52567 13.9413V13.316C9.8987 13.0197 10.2553 12.7208 10.5954 12.4191C10.9355 12.1173 11.2372 11.8211 11.5005 11.5304C11.7693 11.2341 11.9805 10.9461 12.1341 10.6664C12.2877 10.3811 12.3645 10.1068 12.3645 9.84352C12.3645 9.66797 12.3344 9.5034 12.274 9.3498C12.2137 9.19072 12.1149 9.06454 11.9778 8.97129C11.8406 8.87254 11.6569 8.82317 11.4265 8.82317C11.0809 8.82317 10.8203 8.93289 10.6448 9.15232C10.4747 9.37174 10.3924 9.63506 10.3979 9.94226H9.55035C9.55584 9.53632 9.64087 9.1962 9.80544 8.92192C9.9755 8.64763 10.2004 8.43917 10.4802 8.29654C10.7654 8.15392 11.0864 8.0826 11.4429 8.0826C11.986 8.0826 12.4194 8.2362 12.743 8.5434C13.0722 8.84512 13.2368 9.26752 13.2368 9.8106C13.2368 10.1343 13.1572 10.4552 12.9981 10.7733C12.8445 11.0915 12.6416 11.3987 12.3892 11.6949C12.1424 11.9857 11.8763 12.26 11.591 12.5178C11.3058 12.7756 11.0342 13.0088 10.7764 13.2172H13.4096V13.9413H9.52567Z"
                fill="white"
              />
              <path
                style={{ fill: "#fff" }}
                d="M2.44552 14.4556V6.50134L0.895804 6.85791V5.7882L3.02152 4.85563H3.95409V14.4556H2.44552Z"
                fill="white"
              />
            </svg>
          </div>{" "}
          <p> Personal Information</p>
        </div>
        <ul className="signup__errors">
          {notification.error
            ? Object.values(notification.msg.error).map((item, index) => {
                return <li key={index}>{item}</li>;
              })
            : ""}
        </ul>
        <form>
          <div className={style["form__container--row"]}>
            <div>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                placeholder="e.g Peter"
                onFocus={(e) => props.handleFocused(e)}
                onBlur={handleBlur}
              />

              {touched.firstName && errors.firstName && (
                <div className="error">{errors.firstName}</div>
              )}
            </div>

            <div>
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="e.g Pan"
                onChange={handleChange}
                onFocus={(e) => props.handleFocused(e)}
                onBlur={handleBlur}
              />
              {touched.lastName && errors.lastName && (
                <div className="error">{errors.lastName}</div>
              )}
            </div>
          </div>

          <div className={style["form__container--col"]}>
            <div>
              <label htmlFor="Phone Number">Phone Number</label>
              <div className={style["form__container--custom"]}>
                <select
                  id=""
                  onChange={handleCountry}
                  defaultValue={JSON.stringify(defaultCountry)}
                >
                  {dialingcodes.map((code, index) => {
                    return (
                      <option key={index} value={JSON.stringify(code)}>
                        +{code.callingCode}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="tel"
                  name="phone_no"
                  onChange={handleChange}
                  onFocus={(e) => props.handleFocused(e)}
                  onBlur={handleBlur}
                />
              </div>
              {touched.phone_no && errors.phone_no && (
                <div className="error">{errors.phone_no}</div>
              )}{" "}
            </div>
          </div>
          <div className={style["form__container--col"]}>
            <div>
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                placeholder="name@example.com"
                onFocus={(e) => props.handleFocused(e)}
                onBlur={handleBlur}
              />

              {touched.email && errors.email && (
                <div className="error">{errors.email}</div>
              )}
            </div>
            <div>
              <label htmlFor="Create Password">Password</label>
              <div className={style["form__container--custom"]}>
                <input
                  type={
                    props.showPassword.id === "password" ? "text" : "password"
                  }
                  name="password"
                  onChange={handleChange}
                  // onFocus={(e) => props.handleFocused(e)}
                  placeholder="min. of 8 characters"
                  onBlur={handleBlur}
                  style={{ height: "50px" }}
                />

                <span id="password" onClick={(e) => props.handlePassword(e)}>
                  {props.showPassword.id === "password" ? (
                    <img id="password" src={eyeOpen} alt="eyeOpen" />
                  ) : (
                    <img id="password" src={eyeClose} alt="eyeClose" />
                  )}
                </span>
              </div>
              {touched.password && errors.password && (
                <div className="error">{errors.password}</div>
              )}{" "}
            </div>

            {/* <div>
              <label htmlFor="Confirm Password">Confirm Password</label>
              <div className={style["form__container--custom"]}>
                <input
                  type={
                    props.showPassword.id === "confirmPassword"
                      ? "text"
                      : "password"
                  }
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={values.password.trim() === ""}
                />

                <span
                  id="confirmPassword"
                  onClick={(e) => props.handlePassword(e)}
                >
                  {props.showPassword.id === "confirmPassword" ? (
                    <img id="confirmPassword" src={eyeOpen} alt="eyeOpen" />
                  ) : (
                    <img id="confirmPassword" src={eyeClose} alt="eyeClose" />
                  )}
                </span>
              </div>

              {touched.confirmPassword && errors.confirmPassword && (
                <div className="error">{errors.confirmPassword}</div>
              )}
            </div> */}
          </div>
          <div className={style["form__container--col"]}>
            <div>
              <label htmlFor="Question">Are you a software Developer?*</label>
              <div className={style["radio--buttons"]}>
                <div className={style["radio"]}>
                  <input
                    type="radio"
                    name="software_dev"
                    value="no"
                    id=""
                    onChange={handleChange}
                    onFocus={(e) => props.handleFocused(e)}
                    onBlur={handleBlur}
                  />

                  <label htmlFor="Question" style={{ width: "420px" }}>
                    No, I’m not
                  </label>
                </div>
                <div className={style["radio"]}>
                  <input
                    type="radio"
                    name="software_dev"
                    value="yes"
                    id=""
                    onChange={handleChange}
                    onFocus={(e) => props.handleFocused(e)}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="Question" style={{ width: "420px" }}>
                    Yes, I am
                  </label>
                </div>
              </div>
              {errors.software_dev && (
                <div className="error">{errors.software_dev}</div>
              )}
            </div>

            {/* <div>
              <label htmlFor="country">Country*</label>
              <select
                name="country"
                id=""
                defaultValue={"Nigeria"}
                onChange={handleChange}
              >
                {Countrydata.map((country) => {
                  return (
                    <option value={country.name.common}>
                      {country.flag} {country.name.common}
                    </option>
                  );
                })}
              </select>
              {errors.country && <div className="error">{errors.country}</div>}{" "}
            </div> */}
          </div>

          <hr />
          <FormRowCheckbox>
            <input type="checkbox" onChange={handleChange} name="terms" id="" />
            <div className={style["terms"]}>
              I have read and agreed to the{" "}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>{" "}
              and
              <a href="/terms-of-service" target="_blank">
                {" "}
                Terms and Conditions
              </a>
            </div>
          </FormRowCheckbox>
          {touched.terms && errors.terms && (
            <div className="error">{errors.terms}</div>
          )}

          <Button
            onClick={(e) => handleSubmit(e)}
            className={
              !touched.terms ||
              !touched.firstName ||
              !touched.lastName ||
              !touched.email ||
              !touched.phone_no ||
              !touched.software_dev ||
              !touched.password
                ? "faint"
                : "filled"
            }
            type="submit"
            disabled={
              isLoading ||
              !touched.terms ||
              !touched.firstName ||
              !touched.lastName ||
              !touched.email ||
              !touched.phone_no ||
              !touched.software_dev ||
              !touched.password
            }
          >
            {!isLoading ? (
              "Continue"
            ) : (
              <ScaleLoader
                css={override}
                size={80}
                color={"#fff"}
                loading={true}
              />
            )}{" "}
          </Button>
          <Modal isOpen={notification.success} toggleModal={toggleModal}>
            <div className={style["forgotpassword__modal--content"]}>
              <header>
                <h2>Check your email</h2>
              </header>
              <p>
                You account has been created and a verification link has been
                sent to the email provided, Kindly verify your email to proceed{" "}
              </p>

              <Button
                type="button"
                className="filled"
                onClick={() => handleNextBtn()}
              >
                Continue
              </Button>
              <p className={style.resend}>
                If you didn't get the verification link,{" "}
                <span onClick={handleResend}>click here</span> to resend
              </p>
            </div>
          </Modal>
          <Modal isOpen={isModalOpen} toggleModal={toggleVerificationModal}>
            <div className={style["forgotpassword__modal--content"]}>
              {/* <header>
              <h2>Check your email</h2>
            </header> */}
              <p>{data.message} </p>

              <Button
                type="button"
                className="filled"
                onClick={() => handleNextBtn()}
              >
                Ok
              </Button>
            </div>
          </Modal>
        </form>
        <div className={style["signup__container--links"]}>
          <p>
            Already have an account,
            <Link to="/login">
              <span> Log in</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
