/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import style from "./index.module.scss";
import Button from "../Button";
import logoDark from "../../assets/images/msa__new__logo.svg";
import logoLight from "../../assets/images/new__logo.svg";
import burgerIconOpenLight from "../../assets/images/header/burger__menu__open__light.svg";
import burgerIconOpenDark from "../../assets/images/header/burger__menu__open__dark.svg";
import burgerIconCloseLight from "../../assets/images/header/burger__menu__close__light.svg";
import burgerIconCloseDark from "../../assets/images/header/burger__menu__close__dark.svg";
import { useClickOutside } from "../../hooks/useClickOutside";

const Header = (props) => {
  const justify = props.justify || "flex-start";
  const bgStyle = props.bgStyle || "landing__page--bg";
  const theme = props.theme || "darkTheme";

  const navBarTheme = {
    darkTheme: {
      logo: logoLight,
      color: "#000000",
      burger: { open: burgerIconOpenDark, close: burgerIconCloseDark },
      background: "#ffffff",
    },
    lightTheme: {
      logo: logoDark,
      color: "#ffffff",
      burger: { open: burgerIconOpenLight, close: burgerIconCloseLight },
      background: "transparent",
    },
  };

  const [dropdown, setDropdown] = React.useState(false);
  const [demoDropdown, setDemoDropdown] = React.useState(false);
  const [burgerIcon, setBurgerIcon] = React.useState(
    navBarTheme[theme].burger.open
  );
  const navBarMenu = React.useRef(null);
  const ref = React.useRef(null);

  const menuItems = [
    {
      name: "Products ",
      link: "/products",
      id: "products",
    },
    // {
    //   name: "Pricing",
    //   link: "/pricing",
    //   id: "pricing",
    // },
    {
      name: "About us",
      link: "/about",
      id: "aboutus",
    },
    {
      name: "FAQ",
      link: "/faq",
      id: "faq",
    },
    {
      name: "Blog",
      link: "/blog",
      id: "blog",
    },
    {
      name: "Demo",
      link: "/demo",
      id: "demo",
    },

    {
      name: "Developers",
      link: "https://documentation.myserviceagent.net/",
      id: "docs",
    },

    {
      name: "Log in",
      link: "/login",
      id: "login",
    },

    {
      name: "Get Started",
      link: "/sign-up",
      id: "signup",
    },
  ];

  const dropdownItems = [
    {
      title: "Voice Survey ",
      route: "/voice-survey",
    },
    {
      title: "Business messaging",
      route: "/business-messaging",
    },
    {
      title: "OTP Authentication",
      route: "/otpauthentication",
    },

    {
      title: "Virtual Call Center",
      route: "/call-center",
    },
    {
      title: "Omnichannel CRM",
      route: "/omnichannel-crm",
    },
  ];

  const demoDropdownItems = [
    {
      title: "Voice Survey",
      route: "/demo/voice-survey",
    },
    {
      title: "OTP Authentication",
      route: "/demo/otp-authentication",
    },
    {
      title: "Business Messaging",
      route: "/demo/business-messaging",
    },
    {
      title: "Overview",
      route: "/demo/overview",
    },
  ];
  // This is good.

  const Dropdown = (props) => {
    if (props.id !== 0 || !props.display) {
      return "";
    }

    return (
      <div className={style["dropdown"]} id="dropdown">
        <ul>
          {props.list.map((item, id) => (
            <li key={id}>
              <Link to={item.route}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const DemoDropdown = (props) => {
    if (props.id !== 3 || !props.display) {
      return "";
    }

    return (
      <div className={style["demo-dropdown"]} id="demo-dropdown">
        <ul>
          {props.list.map((item, id) => (
            <li key={id}>
              <Link to={item.route}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const toggleDropDown = (item) => {
    if (item === "products") {
      setDropdown(!dropdown);
      setDemoDropdown(false);
    } else if (item === "demo") {
      setDemoDropdown(!demoDropdown);
      setDropdown(false);
    }
  };

  function toggleBurgerMenu() {
    if (
      !navBarMenu.current.style.display ||
      navBarMenu.current.style.display === "none"
    ) {
      navBarMenu.current.style.display = "block";
      setBurgerIcon(navBarTheme[theme].burger.close);
    } else {
      navBarMenu.current.style.display = "none";
      setBurgerIcon(navBarTheme[theme].burger.open);
    }
  }

  useClickOutside(ref, setDropdown);

  return (
    <section
      className={`${style["header"]} ${style[bgStyle]}`}
      style={{ color: navBarTheme[theme].color }}
    >
      <nav ref={ref} className={style["navbar"]}>
        <div className={style["navbar__menu__imgs"]}>
          <div className={style["navbar__brand"]}>
            <Link to="/">
              <img src={navBarTheme[theme].logo} alt="MSA Logo"></img>
            </Link>
          </div>

          <img
            className={style["burger__menu__icon"]}
            color={navBarTheme[theme].color}
            src={burgerIcon}
            alt="burger menu"
            onClick={toggleBurgerMenu}
          />
        </div>

        <ul
          className={`${style["navbar__menu"]}  ${
            bgStyle !== "landing__page--bg" && bgStyle !== "privacy__page--bg"
              ? style["transparent-bg"]
              : style["white-bg"]
          }`}
          ref={navBarMenu}
        >
          {menuItems.map((item, id) => (
            <li key={id} id={item.id} className={style["navbar__menu--item"]}>
              {id === 0 || id === 10 ? (
                <p id={item.id} onClick={(e) => toggleDropDown(item.id)}>
                  {item.name}
                  <span id={item.id} className={style["arrow-down"]}></span>
                </p>
              ) : item.id === "signup" ? (
                <Link to={item.link}>
                  <Button className="small--filled">{item.name}</Button>
                </Link>
              ) : item.id === "docs" ? (
                <Link
                  to={item.link}
                  target="_blank"
                  style={{ color: navBarTheme[theme].color }}
                >
                  {item.name}
                </Link>
              ) : (
                <Link
                  to={item.link}
                  style={{ color: navBarTheme[theme].color }}
                >
                  {item.name}
                </Link>
              )}
              <Dropdown list={dropdownItems} id={id} display={dropdown} />
              <DemoDropdown
                list={demoDropdownItems}
                id={id}
                display={demoDropdown}
              />
              {/* {id + 1 !== menuItems.length && (
                // <hr className={style["menu__rule"]} />
              )} */}
            </li>
          ))}
        </ul>
      </nav>

      {props.children && (
        <div className={style["hero"]}>
          <div
            className={style["hero__details"]}
            style={{ justifyContent: justify }}
          >
            {props.children}
          </div>

          {props.showArrow && (
            <div className={style["hero__arrow"]}>
              <svg
                width="15"
                height="38"
                viewBox="0 0 15 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.5 36L2 28H13L7.5 36Z"
                  stroke="white"
                  strokeWidth="2"
                />
                <path
                  d="M7.5 1V28"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Header;
