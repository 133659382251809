import React from 'react';
import style from './index.module.scss';
import Layout from '../../../components/Layout/AdminDashboardLayout';

const AdminHome = () => {

    return (
        <Layout pageTitle="Dashboard">
        <div className={style["admin__container"]}>
          
        </div>
      </Layout>
    )
}

export default AdminHome;