import React from "react";
import style from "./index.module.scss";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Button from "../../../components/Button";
import Footer from "../../../components/Footer";
import SubscribeForm from "../../../components/SubscribeForm";
import open from "../../../assets/images/open.svg";
import close from "../../../assets/images/close.svg";
import CheckBox from "../../../assets/images/check.png";

export default function VoiceSurvey() {
  const pageTop = React.useRef(null);
  const [showAnswer, setAnswer] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  // function scrollDown(){
  //     faqsTop.current.scrollIntoView({behavior: "smooth"});
  // }

  function handleToggle(index) {
    const ansArr = [false, false, false, false, false];
    if (showAnswer[index]) {
      setAnswer(ansArr);
    } else {
      ansArr[index] = true;
      setAnswer(ansArr);
    }
  }

  React.useEffect(() => {
    pageTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const lists = [
    "Collect insights to improve product services and overall customer satisfaction",
    "Get accurate sentiment analysis",
    "Accurate analytics of NPS and CSAT score",
    "Hear what your customers literally say about you",
    "Make intelligent decisions in the aspect of customer experience",
    "Access to voice survey recording",
    "Get authentic feedback",
    "A faster way of conducting customer research",
  ];

  return (
    <>
      <div ref={pageTop}></div>
      <Header bgStyle="voice__survey__page--bg" theme="lightTheme">
        <div className={style["header__details"]}>
          <div>
            <h3>Voice Survey</h3>
            <p className={style["third__header__paragraph"]}>
              New level of detail and insight with voice survey.{" "}
            </p>
            <Link to="/sign-up" style={{ display: "flex" }}>
              <Button className="medium--filled">Get Started</Button>
            </Link>
          </div>
        </div>
      </Header>

      <section className={style["section__survey--one"]}>
        <div className={style["container"]}>
          <div className={style["container--left"]}>
            <h1 className={style["intro__text--header"]}>
              Why use Voice Survey?
            </h1>
          </div>

          <div className={style["container--right"]}>
            <ul>
              {lists.map((item, index) => (
                <li key={index}>
                  <span>
                    <img src={CheckBox} alt={CheckBox} />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className={style["section__survey--two"]}>
        <div className={style["accordion"]}>
          <h3 className={style["accordion__title"]}>
            Answers to all your questions
          </h3>
          {faqs.map((faq, index) => (
            <div
              className={style["accordion__list"]}
              key={index}
              onClick={() => handleToggle(index)}
            >
              <div className={style["header"]}>
                {" "}
                <h3>{faq.question}</h3>
                <img src={showAnswer[index] ? close : open} alt="open" />
              </div>
              {showAnswer[index] && <p>{faq.answer}</p>}
            </div>
          ))}
        </div>
        <div className={style["circle"]}></div>
      </section>

      <SubscribeForm />
      <Footer />
    </>
  );
}
const faqs = [
  {
    question: "Can we get an analysis of the customer's response?",
    answer:
      "Yes. Analysis is a big part of what we do. You can get a breakdown of the conversation, and an NPS and CSAT analysis of your customer's response",
  },
  {
    question: "What happens when the customer doesn't answer the call?",
    answer:
      "Our AI can automatically reschedule a call back or initiate an sms based on sender's request",
  },
  {
    question: "Can I schedule a call with the customer? ",
    answer:
      "All calls on our platform can be scheduled to your preferred time by the sender and the receiver",
  },
  {
    question: "Will the survey be sent out in a human voice? ",
    answer:
      "Our Voice AI is programmed in an authentic Nigerian Voice and can also speak any of the Major Nigerian Languages; Pidgin, Yoruba, Igbo and Hausa.",
  },
  {
    question: "Will I get a sentiment analysis? ",
    answer:
      "Yes. Our Survey analysis comes with sentiment analysis based on request",
  },
];
