export const apiErrorHandler = (errorResponse) => {
  if (typeof errorResponse === "string") {
    // If the error response is a simple error message string
    return [errorResponse]; // Return an array containing the error message
  } else if (typeof errorResponse === "object" && errorResponse !== null) {
    // If the error response is an object with error messages as arrays
    const errorMessages = [];
    for (const key in errorResponse) {
      if (
        errorResponse.hasOwnProperty(key) &&
        Array.isArray(errorResponse[key])
      ) {
        errorMessages.push(...errorResponse[key]);
      }
    }
    return errorMessages;
  } else {
    // If the error response is not recognized, return a generic error message
    return ["Server Error"];
  }
};
