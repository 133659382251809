import React from "react";
import style from "./index.module.scss";
import imageUploadIcon from "../../assets/icons/image-upload.svg";

export default function FileUpload({
  setFieldValue,
  values,
  name,
  accept,
  children,
}) {
  // const initialState = {
  //   inDraggableArea: false,
  //   files: [],
  // };

  // Reducer function
  // const reducer = (state = initialState, action) => {
  //   switch (action.type) {
  //     case "AddToDraggableArea":
  //       return { ...state, inDraggableArea: action.inDraggableArea };

  //     case "AddToFileList":
  //       return { ...state, files: state.files.concat(action.files) };

  //     default:
  //       return state;
  //   }
  // };

  // const [data, dispatch] = React.useReducer(reducer, initialState);

  //Event for placing the file in the draggable area
  const handleDrop = (e) => {
    e.preventDefault();

    let files = [...e.dataTransfer.files];
    setFieldValue("payment_evidence", files[0]);

    // if (files) {
    //   dispatch({ type: "AddToFileList", files });
    //   dispatch({ type: "AddToDraggableArea", inDraggableArea: false });
    // }
  };

  // To copy file from PC to draggable area
  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";

    // dispatch({ type: "AddToDraggableArea", inDraggableArea: true });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    // dispatch({ type: "AddToDraggableArea", inDraggableArea: true });
  };

  return (
    <div
      className={style["file__upload--container"]}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
    >
      {!values[name]?.name && (
        <img src={imageUploadIcon} alt="ImageUploadIcon" />
      )}
      <div className={style["file__upload--input"]}>
        {!values[name]?.name && (
          <>
            <center>
              <label htmlFor="file-upload">Add File</label>
            </center>
            <input
              type="file"
              name="file-upload"
              onChange={(event) =>
                setFieldValue(`${name}`, event.target.files[0])
              }
              id="file-upload"
              accept={accept}
            />
          </>
        )}

        <div className={style["upload__evidence--img-container"]}>
          {values[name] || values[name] ? (
            // values.payment_evidence.map((file) => (
            <>
              {children}
              <label htmlFor="file-upload">Change File</label>
              <input
                type="file"
                name="file-upload"
                onChange={(event) =>
                  setFieldValue(`${name}`, event.target.files[0])
                }
                accept={accept}
                id="file-upload"
              />
            </>
          ) : (
            // ))
            <>
              <p style={{ textAlign: "center" }}>or Drag files to Upload</p>
              <p>Kindly upload as WAV/MP3 file (WAV format preferable)</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
