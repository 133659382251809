import { combineReducers } from "redux";
import * as actionTypes from "../actions/actionTypes";

import authReducer from "./authReducer";
import messageReducer from "./messageReducer";
import paymentReducer from "./paymentReducer";
import otpReducer from "./otpReducer";
import accountReducer from "./accountReducer";
import sendMessagesReducer from "./sendMessagesReducer";
import createPhonebookReducer from "./createPhonebookReducer";
import blogReducer from "./blogReducer";

const appReducer = combineReducers({
  auth: authReducer,
  otp: otpReducer,
  payments: paymentReducer,
  message: messageReducer,
  account: accountReducer,
  businessmessaging: sendMessagesReducer,
  creatingPhonebook: createPhonebookReducer,
  blog: blogReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
