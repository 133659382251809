import React from "react";
import style from "./index.module.scss";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import SubscribeForm from "../../../components/SubscribeForm";
import expandIcon from "../../../assets/images/open.svg";
import closeIcon from "../../../assets/images/close.svg";
import {
  faqsAll,
  faqsBusinessMessaging,
  faqsOTP,
  faqsVirtualCall,
  faqsVoiceSurvey,
} from "./faqs";

export default function FAQ(props) {
  const faqsTop = React.useRef(null);
  const headerTop = React.useRef(null);
  const [showAnswer, setAnswer] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  // function scrollDown(){
  //     faqsTop.current.scrollIntoView({behavior: "smooth"});
  // }

  function handleAnswers(index) {
    const ansArr = [false, false, false, false, false];
    if (showAnswer[index]) {
      setAnswer(ansArr);
    } else {
      ansArr[index] = true;
      setAnswer(ansArr);
    }
  }

  React.useEffect(() => {
    headerTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const [activeTab, setActiveTab] = React.useState("Business Messaging");

  const faqTabs = [
    "Business Messaging",
    "OTP Authentication",
    "Voice Survey",
    "Virtual Call Center",
  ];

  return (
    <>
      <div ref={headerTop}></div>
      <Header theme="lightTheme" bgStyle="faqs__page--bg">
        <div className={style["header__details"]}>
          <div>
            <h3>Frequently Asked Questions</h3>
            <p>
              MyServiceAgent is a technology solution that solves communication
              problems for businesses in Africa. Learn more about our products
              and services by going through our most asked questions from
              businesses and individuals.
            </p>
          </div>
          {/* <img src={headerFaq} alt="faq" /> */}
        </div>
        {/* <div className={style["header__details"]}>
                <p className={style["header__title"]}>FAQ</p>
                <p className={style["header__subtext"]}>Learn more about MyServiceAgent and it’s products.</p>
                <img src={scrollDownIcon} alt="scroll down" onClick={scrollDown}/>
            </div> */}
      </Header>

      <div className={style["faq--tabs"]}>
        {faqTabs.map((tab, index) => (
          <div
            key={index}
            className={
              activeTab === tab
                ? style["faq--tabs__active"]
                : style["faq--tabs__title"]
            }
            onClick={() => setActiveTab(tab)}
          >
            <h3> {tab}</h3>
          </div>
        ))}
      </div>

      {activeTab === "All" && (
        <div className={style["container"]} ref={faqsTop}>
          {faqsAll.map((item, index) => (
            <div
              key={index}
              className={`${style["faq__box"]} ${
                showAnswer[index]
                  ? style["border__red"]
                  : style["border__light"]
              }`}
            >
              <div
                className={style["faq__question__bar"]}
                onClick={() => handleAnswers(index)}
              >
                <p className={style["faq__title"]}>{item.question}</p>
                <img
                  src={showAnswer[index] ? closeIcon : expandIcon}
                  alt="toggle answer"
                />
              </div>

              {showAnswer[index] && (
                <p className={style["faq__answer"]}>{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      )}

      {(activeTab === "Business Messaging" ||
        activeTab === "OTP Authentication" ||
        activeTab === "Voice Survey" ||
        activeTab === "Virtual Call Center") && (
        <div className={style["business--faq--container"]} ref={faqsTop}>
          {activeTab === "Business Messaging" &&
            faqsBusinessMessaging.map((item, index) => (
              <div
                key={index}
                className={`${style["faq__box"]} ${
                  showAnswer[index]
                    ? style["border__red"]
                    : style["border__light"]
                }`}
              >
                <div
                  className={style["faq__question__bar"]}
                  onClick={() => handleAnswers(index)}
                >
                  <p className={style["faq__title"]}>{item.question}</p>
                  <img
                    src={showAnswer[index] ? closeIcon : expandIcon}
                    alt="toggle answer"
                  />
                </div>

                {showAnswer[index] && (
                  <p className={style["faq__answer"]}>{item.answer}</p>
                )}
              </div>
            ))}

          {activeTab === "OTP Authentication" &&
            faqsOTP.map((item, index) => (
              <div
                key={index}
                className={`${style["faq__box"]} ${
                  showAnswer[index]
                    ? style["border__red"]
                    : style["border__light"]
                }`}
              >
                <div
                  className={style["faq__question__bar"]}
                  onClick={() => handleAnswers(index)}
                >
                  <p className={style["faq__title"]}>{item.question}</p>
                  <img
                    src={showAnswer[index] ? closeIcon : expandIcon}
                    alt="toggle answer"
                  />
                </div>

                {showAnswer[index] && (
                  <p className={style["faq__answer"]}>{item.answer}</p>
                )}
              </div>
            ))}

          {activeTab === "Voice Survey" &&
            faqsVoiceSurvey.map((item, index) => (
              <div
                key={index}
                className={`${style["faq__box"]} ${
                  showAnswer[index]
                    ? style["border__red"]
                    : style["border__light"]
                }`}
              >
                <div
                  className={style["faq__question__bar"]}
                  onClick={() => handleAnswers(index)}
                >
                  <p className={style["faq__title"]}>{item.question}</p>
                  <img
                    src={showAnswer[index] ? closeIcon : expandIcon}
                    alt="toggle answer"
                  />
                </div>

                {showAnswer[index] && (
                  <p className={style["faq__answer"]}>{item.answer}</p>
                )}
              </div>
            ))}

          {activeTab === "Virtual Call Center" &&
            faqsVirtualCall.map((item, index) => (
              <div
                key={index}
                className={`${style["faq__box"]} ${
                  showAnswer[index]
                    ? style["border__red"]
                    : style["border__light"]
                }`}
              >
                <div
                  className={style["faq__question__bar"]}
                  onClick={() => handleAnswers(index)}
                >
                  <p className={style["faq__title"]}>{item.question}</p>
                  <img
                    src={showAnswer[index] ? closeIcon : expandIcon}
                    alt="toggle answer"
                  />
                </div>

                {showAnswer[index] && (
                  <p className={style["faq__answer"]}>{item.answer}</p>
                )}
              </div>
            ))}
        </div>
      )}

      <SubscribeForm />
      <Footer />
    </>
  );
}
