import React from "react";
import style from "../../styles/RouteStyles/signup.module.scss";
// import Modal from "../Modal";
// import * as authActions from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import * as messageActions from "../../redux/actions/messageActions";
import { useDispatch, useSelector } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import BusinessSec from "../../utils/JSON/business-sector.json";
import * as authActions from "../../redux/actions/authActions";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../Button";
import Modal from "../Modal";
import AuthLayout from "../Layout/AuthLayout";

export default function BusinessInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notification = useSelector((state) => state.message);
  const authState = useSelector((state) => state.auth.authState);
  const isLoading = useSelector((state) => state.auth.isLoading);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  const toggleModal = () => {
    dispatch(messageActions.clearErrorMessage());
  };

  const headerValues = {
    heading: "Let’s get you started",
    subheading: "Business Information",
    paragraph: "Business Info",
  };

  const initialValues = {
    client_name: "",
    business_sector: "",
    website: "",
    organization_type: "",
    supports_international: "",
    terms: "",
  };

  notification.id === "BUSINESS_INFO" && navigate("/dashboard");

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get("email");
    const code = urlParams.get("code");

    const formData = new FormData();

    formData.append("token", code);
    formData.append("email", email);

    dispatch(authActions.verifyUser(code, email));

    return () => {
      dispatch(messageActions.clearErrorMessage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businessInfoSchema = Yup.object().shape({
    client_name: Yup.string().required("Required"),

    supports_international: Yup.string().required("Required"),
    organization_type: Yup.string().when("business_sector", {
      is: "Others",
      then: () => Yup.string().required("Required field"),
    }),
  });

  return (
    <AuthLayout>
      <div className={style["container"]}>
        <div className={style["signup"]}>
          <h3>{headerValues.heading}</h3>
          <div className={style["subheading"]}>
            <div>
              <svg
                width="23"
                height="24"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style={{ fill: "#fff" }}
                  d="M6.70421 11.6266L8.35972 5.96091H8.90716L7.25165 11.6266H6.70421Z"
                  fill="white"
                />
                <path
                  style={{ fill: "#fff" }}
                  d="M9.40771 11V10.6109C9.71991 10.3646 10.0145 10.1162 10.2915 9.86555C10.5729 9.61051 10.8214 9.35988 11.0368 9.11364C11.2567 8.8674 11.4282 8.62556 11.5513 8.38812C11.6788 8.15067 11.7426 7.92202 11.7426 7.70217C11.7426 7.53947 11.714 7.38777 11.6568 7.24706C11.6041 7.10636 11.5161 6.99423 11.393 6.91069C11.2699 6.82274 11.1028 6.77877 10.8917 6.77877C10.6895 6.77877 10.5202 6.82494 10.3839 6.91728C10.2476 7.00522 10.1442 7.12395 10.0739 7.27345C10.0079 7.42295 9.97494 7.58344 9.97494 7.75493H9.44069C9.44069 7.45153 9.50445 7.1921 9.63197 6.97664C9.75948 6.75679 9.93317 6.5897 10.153 6.47537C10.3729 6.36105 10.6213 6.30388 10.8983 6.30388C11.3073 6.30388 11.6414 6.42041 11.9009 6.65345C12.1647 6.8821 12.2966 7.22508 12.2966 7.68238C12.2966 7.955 12.2285 8.22542 12.0922 8.49365C11.9558 8.75747 11.7822 9.0147 11.5711 9.26534C11.36 9.51158 11.1336 9.74243 10.8917 9.95788C10.6543 10.1733 10.4322 10.3668 10.2256 10.5383H12.4549V11H9.40771Z"
                  fill="white"
                />
                <path
                  style={{ fill: "#fff" }}
                  d="M0.825807 11V10.1645C1.32415 9.76881 1.8005 9.3694 2.25487 8.96634C2.70924 8.56327 3.11231 8.16753 3.46407 7.77912C3.82317 7.38338 4.10532 6.99863 4.31052 6.62487C4.51572 6.24379 4.61832 5.87736 4.61832 5.52559C4.61832 5.29108 4.57801 5.07123 4.4974 4.86603C4.41678 4.6535 4.28487 4.48495 4.10166 4.36036C3.91844 4.22845 3.67294 4.16249 3.36514 4.16249C2.90344 4.16249 2.55534 4.30906 2.32083 4.6022C2.09364 4.89534 1.98371 5.24711 1.99104 5.65751H0.858786C0.866114 5.1152 0.979706 4.66083 1.19956 4.2944C1.42675 3.92798 1.72722 3.64949 2.10097 3.45895C2.48205 3.26841 2.91077 3.17314 3.38713 3.17314C4.11265 3.17314 4.6916 3.37834 5.12398 3.78874C5.5637 4.1918 5.78355 4.7561 5.78355 5.48162C5.78355 5.91401 5.67729 6.34273 5.46476 6.76778C5.25956 7.19283 4.98841 7.60323 4.6513 7.99897C4.32151 8.38738 3.96608 8.75381 3.585 9.09825C3.20391 9.44269 2.84115 9.75415 2.49671 10.0326H6.0144V11H0.825807Z"
                  fill="white"
                />
              </svg>
            </div>{" "}
            <p>{headerValues.subheading}</p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={businessInfoSchema}
            onSubmit={(
              {
                client_name,
                organization_type,
                business_sector,
                supports_international,
                website,
              },
              actions
            ) => {
              const formData = new FormData();

              const token = authState.token;

              const sectorDropdown = business_sector;
              const sectorInput = organization_type;
              const websiteUrl = website;

              formData.append("client_name", client_name);
              formData.append(
                "website",
                website.trim().length < 1 ? "Not Specified" : websiteUrl
              );
              formData.append("supports_international", supports_international);

              formData.append(
                "organization_type",
                sectorDropdown.trim().length < 1 || sectorDropdown === "Others"
                  ? sectorInput
                  : sectorDropdown
              );
              formData.append("token", token);
              // formData.append("accepted_terms", terms && "yes");

              dispatch(
                authActions.updateBusinessInfo(formData, authState.data.id)
              );
              actions.setSubmitting(false);
            }}
          >
            {({ values }) => (
              <Form>
                <div
                  className={`${style["form__container--row"]} ${style["form__container--row-secondary"]}`}
                >
                  <div>
                    <label htmlFor="Business Name">Name of Business</label>
                    <Field
                      name="client_name"
                      type="text"
                      placeholder="e.g Acme Corp. Ltd"
                    />
                    <ErrorMessage
                      component="span"
                      name="client_name"
                      className="error"
                    />
                  </div>
                </div>
                <div
                  className={`${style["form__container--row"]} ${style["form__container--row-secondary"]}`}
                >
                  <div>
                    <label htmlFor="Business Name">Business Sector</label>
                    <Field name="business_sector" as="select">
                      <option value="">Select a sector below</option>
                      {BusinessSec.map((item) => {
                        return <option value={item.name}>{item.name}</option>;
                      })}
                    </Field>
                    <ErrorMessage
                      name="business_sector"
                      component="span"
                      className="error"
                    />
                  </div>
                </div>
                {values?.business_sector &&
                  values.business_sector.toLowerCase() === "others" && (
                    <div
                      className={`${style["form__container--row"]} ${style["form__container--row-secondary"]}`}
                    >
                      <div>
                        <label htmlFor="Business Name">
                          Please fill in the sector*
                        </label>
                        <Field name="organization_type" type="text" />
                        <ErrorMessage
                          name="organization_type"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                    // </div>
                  )}
                <div className={style["form__container--col"]}>
                  <div>
                    <label htmlFor="Business Support">
                      Does this business Support International Customers?
                    </label>
                    <div className={style["radio--buttons"]}>
                      <div className={style["radio"]}>
                        <Field
                          type="radio"
                          name="supports_international"
                          value="no"
                          id=""
                        />

                        <label htmlFor="Question" style={{ width: "420px" }}>
                          No, it doesn't
                        </label>
                      </div>

                      <div className={style["radio"]}>
                        <Field
                          type="radio"
                          name="supports_international"
                          value="yes"
                          id=""
                        />
                        <label htmlFor="Question" style={{ width: "420px" }}>
                          Yes, it does
                        </label>
                      </div>
                    </div>
                    {/* <Field name="supports_international" as="select">
                    <option value="">Please Select an option</option>
                    <option value="yes">Yes</option>

                    <option value="no">No</option>
                  </Field>
                  <ErrorMessage
                    name="supports_international"
                    component="span"
                    className="error"
                  /> */}
                  </div>

                  <div>
                    <label htmlFor="Business website">Business Website</label>
                    <div className={style["form__container--custom"]}>
                      <div className={style["placeholder"]}>
                        <p>http://</p>
                      </div>

                      <Field
                        name="website"
                        type="text"
                        placeholder="e.g example.com"
                      />
                    </div>
                  </div>
                </div>

                <Button
                  className={
                    !values.business_sector ||
                    !values.client_name ||
                    !values.supports_international ||
                    !values.website
                      ? "faint"
                      : "filled"
                  }
                  type="submit"
                  disabled={
                    isLoading ||
                    !values.business_sector ||
                    !values.client_name ||
                    !values.supports_international ||
                    !values.website
                  }
                  // onClick={(e) => handleSubmit(e)}
                >
                  {!isLoading ? (
                    "Continue to Account →"
                  ) : (
                    <ScaleLoader
                      css={override}
                      size={80}
                      color={"#fff"}
                      loading={true}
                    />
                  )}{" "}
                </Button>
              </Form>
            )}
          </Formik>
          {/* 
      <div className={style["signup__container--links"]}>
        <span onClick={handleBackBtn}>← Back</span>
      </div> */}

          <Modal
            isOpen={notification.success && notification.id !== "BUSINESS_INFO"}
            toggleModal={toggleModal}
          >
            <div className={style["forgotpassword__modal--content"]}>
              <header>
                <h2>Registration Complete</h2>
              </header>
              <p>
                Thanks for verification. Kindly fill out this information to
                help us better optimize your account for you
              </p>

              <Button
                type="button"
                className="filled"
                onClick={() => toggleModal()}
              >
                Continue
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </AuthLayout>
  );
}
