import React, { useState, useEffect } from "react";
import style from "./index.module.scss";
import Header from "../../../../components/Header";
import Button from "../../../../components/Button";
import Footer from "../../../../components/Footer";
import axios from "../../../../utils/axios-base";
import Dialog from "../../../../components/Dialog";
import chatBox from "../../../../assets/chatBox.svg";
import { css } from "@emotion/react";
import { ScaleLoader } from "react-spinners";
// import axios from "axios";
import { useSelector } from "react-redux";
import { formatMinutes, formatSeconds } from "../../../../utils/format-time";
import MicRecorder from "mic-recorder-to-mp3";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const ProgressBar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    width: "300%",
    backgroundColor: "whitesmoke",
    borderRadius: 40,
    margin: 50,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 10,
    color: "black",
    fontWeight: 900,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}></span>
      </div>
    </div>
  );
};

export default function DemoBusinessMessaging() {
  let [messages, setMessages] = useState({
    voiceOtpCall: "",
    voiceOtpValidate: "",
    businessMessaging: "",
    voiceSurvey: "",
  });

  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [process, setProcess] = useState("");
  const [messageType, setMessageType] = useState("");

  const userInfo = useSelector((state) => state.auth.authState);

  const [isRecording, setIsRecording] = React.useState(false);
  const [blobURL, setBlobURL] = React.useState("");
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [audioFile, setAudioFile] = React.useState(null);
  const [recordingTime, setRecordingTime] = React.useState({
    recordingMinutes: 0,
    recordingSeconds: 0,
  });

  const businessMessages = [
    { label: "Enjoy brekete discount", message: "enjoy-berekete-discount" },
    { label: "Please be informed", message: "please-be-reminded" },
    { label: "Happy holidays", message: "happy-holidays" },
    { label: "We have three new", message: "we-have-three-new " },
    { label: "Life no get duplicate", message: "life-no-get-duplicate" },
  ];

  const config = {
    headers: {
      Authorization: "Bearer XYRQTlpabh3V6EsZ",
    },
  };

  async function handleBusinessMessaging() {
    setIsSending(true);
    setMessages({
      ...messages,
      businessMessaging: "Calling phone number with message...",
    });

    try {
      const data = {
        clieet_id: userInfo?.key?.client_id,
        msisdn: phoneNumber,
        message: message,
      };
      let res = await axios.post("/telemarketing/preset", data, config);
      if (res.status === 201) {
        setIsSending(false);
        setMessages({
          ...messages,
          businessMessaging: "Message has been sent",
        });
      } else {
        setIsSending(false);
        setMessages({
          ...messages,
          businessMessaging: "Error sending message",
        });
      }
    } catch (err) {
      setIsSending(false);
      setMessages({ ...messages, businessMessaging: "Error sending message" });
    }
  }

  const handleClose = () => {
    setIsOpen(false);
    setRecordingTime({
      recordingMinutes: 0,
      recordingSeconds: 0,
    });
    setBlobURL(null);
    setAudioFile(null);
    setStep(1);
    setMessage(null);
    setPhoneNumber("");
    setMessageType("");
    setOpenMessage(false);
    setMessages({
      voiceOtpCall: "",
      voiceOtpValidate: "",
      businessMessaging: "",
      voiceSurvey: "",
    });
  };

  const handMessage = (message) => {
    setMessage(message.message);
    setOpenMessage(false);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    baground-color: white;
  `;

  const handleNewsletter = async () => {
    if (!email) {
      setProcess("Email address is required");
    } else {
      setProcess("Subscribing to newsletter...");
      setIsLoading(true);
      const payload = {
        u: "44d4a61233b00e03bd3168a90",
        id: "18c8aff5b4",
        MERGE0: email,
      };

      await axios
        .post(
          `https://myserviceagent.us20.list-manage.com/subscribe/post?u=${payload.u}&id=${payload.id}&MERGE0=${email}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setProcess("");
            setIsLoading(false);
            setIsOpen(true);
          } else {
            setIsOpen(true);
          }
        })
        .catch((error) => {
          setProcess("");
          setIsOpen(true);
          setIsLoading(false);
          console.error("Error:", error);
        });
    }
  };

  const handleSendVoiceMessage = async () => {
    setIsSending(true);
    setMessages({
      ...messages,
      businessMessaging: "Calling phone number with message...",
    });
    const formData = new FormData();
    formData.append("msisdns", phoneNumber);
    formData.append("name", "Voice message demo");
    formData.append("message_audio", audioFile);
    try {
      await axios.post("/telemarketing/voice", formData, config).then((res) => {
        if (res.status === 200) {
          setIsSending(false);
          setMessages({
            ...messages,
            businessMessaging: "Message has been sent",
          });
        } else {
          setMessages({
            ...messages,
            businessMessaging: "Message has been sent",
          });
        }
      });
    } catch (error) {
      setIsSending(false);
      setMessages({ ...messages, businessMessaging: "Error sending message" });
    }
  };
  // Audio recorder starts here
  useEffect(() => {
    navigator.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setIsBlocked(false);
      },
      () => {
        console.log("Permission Denied");
        setIsBlocked(true);
      }
    );
  });

  useEffect(() => {
    const MAX_RECORDER_TIME = 5;
    let recordingInterval = null;

    if (isRecording)
      recordingInterval = setInterval(() => {
        setRecordingTime((prevState) => {
          if (
            prevState.recordingMinutes === MAX_RECORDER_TIME &&
            prevState.recordingSeconds === 0
          ) {
            clearInterval(recordingInterval);
            return prevState;
          }

          if (
            prevState.recordingSeconds >= 0 &&
            prevState.recordingSeconds < 59
          )
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };

          if (prevState.recordingSeconds === 59)
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };
        });
      }, 1000);
    else clearInterval(recordingInterval);

    return () => clearInterval(recordingInterval);
  });

  const startRecording = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const saveRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });

        setAudioFile(file);
        const blobURL = URL.createObjectURL(file);
        setBlobURL(blobURL);
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  const mouseEnter = () => {
    let vid = document.getElementById("video");
    vid.onmouseenter = function () {
      vid.setAttribute("controls", "controls");
    };
  };
  // Audio recorder ends here

  return (
    <>
      <Header theme="darkTheme">
        <div className={style["header__details"]}>
          <div className={style["header"]}>
            <div className={style["column"]}>
              <h3>
                Demo our{" "}
                <span>
                  Business <br />
                  Messaging{" "}
                </span>{" "}
                solution
              </h3>
              <p>
                You can send voice-based messages to your customers with
                marketing messages, updates, notifications, etc.
              </p>
              <p>
                Please input your email address in the column provided to
                proceed
              </p>
              <div className={style["form-section"]}>
                <label style={{ color: "#1D293F" }} htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Button
                  type="submit"
                  className="small--filled"
                  onClick={handleNewsletter}
                >
                  {!isLoading ? (
                    "Start Demo"
                  ) : (
                    <ScaleLoader
                      css={override}
                      size={80}
                      color={"#fff"}
                      loading={true}
                    />
                  )}
                </Button>
              </div>
            </div>
            <div className={style["column"]}>
              <video
                id="video"
                width="100%"
                height="100%"
                onMouseEnter={mouseEnter}
                // className={style["column"]}
                autoPlay={true}
                muted={true}
                controlsList="nodownload"
                preload="auto"
              >
                <source src="https://res.cloudinary.com/myserviceagent/video/upload/v1643800293/Business_Messaging_df4k1d.mp4" />
              </video>
            </div>
          </div>
        </div>
      </Header>

      <Dialog isOpen={isOpen}>
        <div className={style["dialg--container"]}>
          <div className={style["close-dialog"]} onClick={handleClose}>
            {" "}
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.6635 2.13128L22.0056 0.493117C21.557 0.0445192 20.8159 0.0445192 20.3478 0.493117L12.078 8.76273L3.65232 0.337083C3.20353 -0.11171 2.46236 -0.11171 1.99446 0.337083L0.336595 1.99495C-0.112198 2.44354 -0.112198 3.18471 0.336595 3.65281L8.74293 12.0591L0.492629 20.3485C0.0440309 20.7971 0.0440309 21.5382 0.492629 22.0063L2.15049 23.6642C2.59909 24.1128 3.34025 24.1128 3.80835 23.6642L12.078 15.3944L20.3478 23.6642C20.7964 24.1128 21.5375 24.1128 22.0056 23.6642L23.6635 22.0063C24.1121 21.5577 24.1121 20.8166 23.6635 20.3485L15.3742 12.0787L23.644 3.80904C24.1121 3.34055 24.1121 2.59938 23.6635 2.13128Z"
                fill="#323232"
              />
            </svg>
          </div>

          <div style={{ marginTop: "82px" }}>
            <img src={chatBox} alt="faq" height="60px" width="60px" />
            <div className={style["response"]}>
              <h3>Test Business Messaging</h3>
              <p>
                {messageType === "text"
                  ? "  Please select a message from the dropdown, then add your phone number in the second column to receive the message via voice."
                  : messageType === "voice"
                  ? "Please record your message as audio, then add your phone number in the second column to receive the message via voice."
                  : "Please choose how you’d like to test the business messaging solution from the options below."}
              </p>
            </div>

            {!messageType && (
              <div className={style["options"]}>
                <div
                  className={style["option"]}
                  onClick={() => setMessageType("text")}
                >
                  <p>As a Text Message</p>
                  <span>
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        style={{ fill: "#EB2931" }}
                        d="M5.17266 6.99999L0.222656 2.04999L1.63666 0.635986L8.00066 6.99999L1.63666 13.364L0.222656 11.95L5.17266 6.99999Z"
                        fill="#EB2931"
                      />
                    </svg>
                  </span>
                </div>
                <div
                  className={style["option"]}
                  onClick={() => setMessageType("voice")}
                >
                  <p>As a Live Voice Recording</p>
                  <span>
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        style={{ fill: "#EB2931" }}
                        d="M5.17266 6.99999L0.222656 2.04999L1.63666 0.635986L8.00066 6.99999L1.63666 13.364L0.222656 11.95L5.17266 6.99999Z"
                        fill="#EB2931"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            )}
            {messageType === "text" && (
              <div className={style["section__form__three"]}>
                <form>
                  <div className={style["form-head"]}>
                    <label style={{ color: "#1D293F" }} htmlFor="phoneNumber">
                      {step === 1 ? "      Business Message" : "Phone Number"}
                    </label>

                    <span>Step {step} /2</span>
                  </div>

                  {step === 2 && (
                    <input
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      type="text"
                      id="phoneNumber"
                      placeholder="Enter phone number"
                    />
                  )}
                  {step === 1 && (
                    <>
                      <div
                        className={style["message"]}
                        onClick={() => setOpenMessage(!openMessage)}
                      >
                        <p> {message ? message : "Select a message"}</p>
                        <span>
                          {!openMessage ? (
                            <svg
                              width="14"
                              height="8"
                              viewBox="0 0 14 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.00072 5.17205L11.9507 0.222046L13.3647 1.63605L7.00072 8.00005L0.636719 1.63605L2.05072 0.222046L7.00072 5.17205Z"
                                fill="black"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="14"
                              height="8"
                              viewBox="0 0 14 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.99928 2.828L2.04928 7.778L0.635281 6.364L6.99928 -5.56359e-07L13.3633 6.364L11.9493 7.778L6.99928 2.828Z"
                                fill="black"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      {openMessage && (
                        <div className={style["message-box"]}>
                          {businessMessages.map((message, index) => (
                            <p key={index} onClick={() => handMessage(message)}>
                              {message.label}
                            </p>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                  <p>{messages.businessMessaging}</p>
                  <p>{process}</p>
                </form>
                <div className={style["form__btn__group"]}>
                  {step === 1 ? (
                    <Button
                      type="submit"
                      className="small--filled"
                      onClick={() => setStep(step + 1)}
                      disabled={!message}
                    >
                      Next
                    </Button>
                  ) : (
                    <div
                      className={style["component__right"]}
                      onClick={handleBusinessMessaging}
                    >
                      <Button className="small--filled">
                        {!isSending ? (
                          "Send Voice Message"
                        ) : (
                          <ScaleLoader
                            css={override}
                            size={80}
                            color={"#fff"}
                            loading={true}
                          />
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}

            {messageType === "voice" && (
              <>
                <div className={style["voice-message"]}>
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        style={{ fill: "#263238" }}
                        d="M20.4853 3.51469C18.2188 1.24823 15.2053 0 12 0C8.79469 0 5.78123 1.24823 3.51469 3.51469C1.24823 5.78123 0 8.79469 0 12C0 15.2053 1.24823 18.2188 3.51469 20.4853C5.78123 22.7518 8.79469 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51469ZM5.625 13.125C5.625 13.7463 5.12133 14.25 4.5 14.25C3.87867 14.25 3.375 13.7463 3.375 13.125V10.875C3.375 10.2537 3.87867 9.75 4.5 9.75C5.12133 9.75 5.625 10.2537 5.625 10.875V13.125ZM9.375 15C9.375 15.6213 8.87133 16.125 8.25 16.125C7.62867 16.125 7.125 15.6213 7.125 15V9C7.125 8.37867 7.62867 7.875 8.25 7.875C8.87133 7.875 9.375 8.37867 9.375 9V15ZM13.125 17.25C13.125 17.8713 12.6213 18.375 12 18.375C11.3787 18.375 10.875 17.8713 10.875 17.25V6.75C10.875 6.12867 11.3787 5.625 12 5.625C12.6213 5.625 13.125 6.12867 13.125 6.75V17.25ZM16.875 15C16.875 15.6213 16.3713 16.125 15.75 16.125C15.1287 16.125 14.625 15.6213 14.625 15V9C14.625 8.37867 15.1287 7.875 15.75 7.875C16.3713 7.875 16.875 8.37867 16.875 9V15ZM19.5 14.25C18.8787 14.25 18.375 13.7463 18.375 13.125V10.875C18.375 10.2537 18.8787 9.75 19.5 9.75C20.1213 9.75 20.625 10.2537 20.625 10.875V13.125C20.625 13.7463 20.1213 14.25 19.5 14.25Z"
                        fill="#2948EB"
                      />
                    </svg>
                  </div>
                  <ProgressBar
                    bgcolor="#2948EB"
                    progress={formatSeconds(recordingTime.recordingSeconds)}
                    height={4}
                  />

                  <div>
                    <span>{formatMinutes(recordingTime.recordingMinutes)}</span>
                    <span>:</span>
                    <span>{formatSeconds(recordingTime.recordingSeconds)}</span>
                  </div>
                </div>
                <span style={{ display: "flex", justifyContent: "end" }}>
                  5 minutes maximum
                </span>
                <div className={style["start-button-container"]}>
                  {isRecording ? (
                    <div
                      className={style["stop-button"]}
                      title="Save recording"
                      disabled={isRecording === 0}
                      onClick={saveRecording}
                    >
                      <span>Stop Recording</span>
                    </div>
                  ) : blobURL ? (
                    ""
                  ) : (
                    <div
                      className={style["start-button"]}
                      title="Start recording"
                      onClick={startRecording}
                    >
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1050_1543)">
                          <path
                            style={{ fill: "#fff" }}
                            d="M18.2669 11.8669C18.2669 11.4214 17.9262 11.0807 17.4807 11.0807C17.0352 11.0807 16.6945 11.4214 16.6945 11.8669C16.6945 14.9332 14.2047 17.423 11.1383 17.423C8.07201 17.423 5.58225 14.9332 5.58225 11.8669C5.58225 11.4214 5.24154 11.0807 4.79601 11.0807C4.35047 11.0807 4.00977 11.4214 4.00977 11.8669C4.00977 15.5098 6.7354 18.5761 10.3521 18.9693V21.0659H7.49543C7.0499 21.0659 6.70919 21.4066 6.70919 21.8522C6.70919 22.2977 7.0499 22.6384 7.49543 22.6384H14.7813C15.2268 22.6384 15.5675 22.2977 15.5675 21.8522C15.5675 21.4066 15.2268 21.0659 14.7813 21.0659H11.9246V18.9693C15.5413 18.5761 18.2669 15.5098 18.2669 11.8669Z"
                            fill="white"
                          />
                          <path
                            style={{ fill: "#fff" }}
                            d="M11.1394 0.361588C8.7283 0.361588 6.7627 2.32719 6.7627 4.73833V11.8407C6.7627 14.278 8.7283 16.2174 11.1394 16.2437C13.5506 16.2437 15.5162 14.278 15.5162 11.8669V4.73833C15.5162 2.32719 13.5506 0.361588 11.1394 0.361588Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1050_1543">
                            <rect
                              width="22.2768"
                              height="22.2768"
                              fill="white"
                              transform="translate(0 0.361588)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <span>Start Recording</span>
                    </div>
                  )}
                </div>

                {blobURL && (
                  <>
                    <div className={style["form-head"]}>
                      <label style={{ color: "#1D293F" }} htmlFor="phoneNumber">
                        Phone Number
                      </label>
                    </div>
                    <input
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      type="text"
                      id="phoneNumber"
                      placeholder="Enter phone number"
                      style={{ width: "100%" }}
                    />
                    <p>{messages.businessMessaging}</p>
                    <div
                      className={style["start-button-container"]}
                      onClick={handleSendVoiceMessage}
                    >
                      <div className={style["start-button"]}>
                        {!isSending ? (
                          "Send Business Message"
                        ) : (
                          <ScaleLoader
                            css={override}
                            size={80}
                            color={"#fff"}
                            loading={true}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Dialog>
      {/* <ContactForm /> */}
      <Footer />
    </>
  );
}
