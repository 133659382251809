import React from 'react'
import Layout from '../../../components/Layout/DashboardLayout'

export default function OtpAndalerts() {
    return (
        <Layout>
            
        </Layout>
    )
}
