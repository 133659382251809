import React from "react";
// import style from "./index.module.scss";
import { passwordValidation } from "../../../utils/validation";
import PersonalInfo from "../../../components/MultiStep/PersonalInfo";
import BusinessInfo from "../../../components/MultiStep/BusinessInfo";
import { useDispatch } from "react-redux";
import * as messageActions from "../../../redux/actions/messageActions";

import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../components/Layout/AuthLayout";

export default function Signup() {
  const dispatch = useDispatch();
  // const lowCaseRegex = new RegExp(/^(?=.*[a-z])/);
  // const upperCaseRegex = new RegExp(/^(?=.*[A-Z])/);
  // const specialCaseRegex = new RegExp(/^(?=.*[!@#$%^&*])/);

  const [count, setCount] = React.useState({ index: 1 });
  const [formState, setFormState] = React.useState({
    password: "",

    touched: {
      password: false,
    },
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    country: "Nigeria",
    software_dev: "",
    dialing_code: "234",
    phone_no: "",
    password: "",
    terms: "",
  };

  const initialValuesBusiness = {
    business_name: "",
    business_website: "",
    business_support: "",
    // business_sector: "",
    terms: false,
  };

  const [focusedState, setFocused] = React.useState({
    phone_no: false,
    email: false,
    password: false,
  });

  const [showPassword, setVisibility] = React.useState({ id: null });

  // const headerValues = [
  //   {
  //     heading: "Let’s get you started",
  //     subheading: "Please fill the signup form.",
  //     paragraph: "Personal Info.",
  //     count: count.index,
  //   },
  //   {
  //     heading: "Business Information",
  //     subheading: "Please provide business details",
  //     paragraph: "Business Info",
  //     count: count.index,
  //   },
  // ];

  const navigate = useNavigate();

  const handleNextBtn = () => {
    // setCount({ ...count, index: count.index + 1 });
    dispatch(messageActions.clearErrorMessage());
    navigate("/login");
  };
  const handleBackBtn = () => {
    return setCount({ ...count, index: count.index - 1 });
  };

  const handlePasswordVisibility = (e) => {
    let id = e.target.id;
    id === showPassword.id
      ? setVisibility({ ...showPassword, id: "" })
      : setVisibility({ ...showPassword, id });
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleFocused = (e) => {
    // console.log(e.target.name);

    setFocused({ [e.target.name]: true });
  };

  const handleBlur = (e) => {
    setFormState({
      ...formState,
      touched: { ...formState.touched, [e.target.name]: true },
    });
  };

  React.useEffect(() => {
    window.addEventListener(
      "beforeunload",
      function (e) {
        // Do something
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "beforeunload",
        function (e) {
          // Do something
          e.preventDefault();
        },
        false
      );
    };
  }, []);

  return (
    <AuthLayout>
      <Multiform
        formNum={count}
        showPassword={showPassword}
        state={initialValues}
        BusinessInfo={initialValuesBusiness}
        // errors={errors}
        // nErrors={nErrors}
        formState={formState}
        handleNextBtn={handleNextBtn}
        handleBackBtn={handleBackBtn}
        handlePassword={handlePasswordVisibility}
        handleChange={handleChange}
        handleFocused={handleFocused}
        handleBlur={handleBlur}
        validate={passwordValidation}
        // handleSubmit={handleSubmit}
        dispatch={dispatch}
        focusedState={focusedState}
      />
    </AuthLayout>
  );
}

const Multiform = ({ formNum, handleNextBtn, handleBackBtn, ...props }) => {
  switch (formNum.index) {
    case 1:
      return (
        <PersonalInfo
          count={formNum}
          handleNextBtn={handleNextBtn}
          {...props}
        />
      );
    case 2:
      return (
        <BusinessInfo
          count={formNum}
          handleBackBtn={handleBackBtn}
          {...props}
        />
      );

    default:
      return (
        <PersonalInfo
          count={formNum}
          handleNextBtn={handleNextBtn}
          {...props}
        />
      );
  }
};
