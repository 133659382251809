import React from "react";
import style from "./index.module.scss";
// import logo from "../../../assets/images/new__logo.svg";
import logo from "../../../assets/msa__transparent.svg";
import { Link } from "react-router-dom";

export default function AuthLayout({ children }) {
  return (
    <section className={style["signup--container"]}>
      <div className={style["signup--container__left"]}>
        <Link to="/">
          <div className={style["login__container--img"]}>
            <img src={logo} alt="MSA Logo"></img>
          </div>
        </Link>
      </div>

      <div className={style["signup--container__aside"]}>{children}</div>
    </section>
  );
}
